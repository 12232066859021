import React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { Tag } from '@jeeves/new-components'
import Switch from '@mui/material/Switch'

export const DataLabelHeader = ({ dataLabel, handleUpdateEnabled }) => {
  return (
    <Box sx={{ pl: 4, pr: 2.2, py: 2, border: 1, borderColor: 'cyralColors.grey.200' }}>
      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
        <Stack direction="row" spacing={2} sx={{ mb: 1 }}>
          <Stack direction="row">
            <Typography variant="h3">{dataLabel.name}</Typography>
          </Stack>
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            {dataLabel.tags.map(tag => (
              <Tag size="md">{tag}</Tag>
            ))}
          </Stack>
        </Stack>
        <Switch
          checked={dataLabel.classificationRule.enabled}
          onChange={() =>
            handleUpdateEnabled(dataLabel.name, !dataLabel.classificationRule.enabled)
          }
        ></Switch>
      </Stack>

      <Stack sx={{ mt: 2 }} direction="row" sx={{ justifyContent: 'space-between' }}>
        <Typography>{dataLabel.description}</Typography>
      </Stack>
    </Box>
  )
}
