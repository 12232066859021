import { SidecarInstance } from '@jeeves/graphql/graphql'

interface InstanceCanUpgradeProps {
  instance: Pick<SidecarInstance, 'recyclable' | 'versionIsDynamic' | 'version' | 'isRecycling'>
  sidecarVersion: string
}

export const instanceCanUpgrade = ({ instance, sidecarVersion }: InstanceCanUpgradeProps) => {
  return (
    instance.recyclable &&
    instance.versionIsDynamic &&
    instance.version !== sidecarVersion &&
    !instance.isRecycling
  )
}
