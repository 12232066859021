import ExpressClient from '../../../clients/express'
import { useAuth } from '../../../components/Auth'
import usePopup from '../../../components/PopupMessage/hooks/usePopup'
import { useAppConfig } from '@jeeves/hooks'
import { get as lodashGet } from 'lodash'
import { useMutation, gql } from '@apollo/client'

const CLONE_SIDECAR_MUTATION = gql`
  mutation CloneSidecar($sidecarId: ID!, $input: CloneSidecarInput!) {
    cloneSidecar(sidecarId: $sidecarId, input: $input) {
      sidecar {
        id
      }
      sidecarAccount {
        clientId
        clientSecret
      }
    }
  }
`

const useInstructions = () => {
  const { getTokenSilently } = useAuth()
  const { config } = useAppConfig()
  const ec = new ExpressClient(getTokenSilently)
  const [cloneSidecarMutation] = useMutation(CLONE_SIDECAR_MUTATION)

  const { setPopup, popupTypes } = usePopup()
  const handleSetPopup = (popupType, popupMessage) => {
    setPopup(popupType, popupMessage)
  }

  const AWSRegions = [
    { label: 'Ohio [us-east-2]', value: 'us-east-2' },
    { label: 'N. Virginia [us-east-1]', value: 'us-east-1' },
    { label: 'N. California [us-west-1]', value: 'us-west-1' },
    { label: 'Oregon [us-west-2]', value: 'us-west-2' },
    { label: 'Hong Kong [ap-east-1]', value: 'ap-east-1' },
    { label: 'Mumbai [ap-south-1]', value: 'ap-south-1' },
    { label: 'Osaka-Local [ap-northeast-3]', value: 'ap-northeast-3' },
    { label: 'Seoul [us-northeast-2]', value: 'us-northeast-2' },
    { label: 'Singapore [ap-southeast-1]', value: 'ap-southeast-1' },
    { label: 'Sydney [ap-southeast-2]', value: 'ap-southeast-2' },
    { label: 'Tokyo [ap-northeast-1]', value: 'ap-northeast-1' },
    { label: 'Central [ca-central-1]', value: 'ca-central-1' },
    { label: 'Beijing [cn-north-1]', value: 'cn-north-1' },
    { label: 'Ningxia [cn-northwest-1]', value: 'cn-northwest-1' },
    { label: 'Frankfurt [eu-central-1]', value: 'eu-central-1' },
    { label: 'Ireland [eu-west-1]', value: 'eu-west-1' },
    { label: 'London [eu-west-2]', value: 'eu-west-2' },
    { label: 'Paris [eu-west-3]', value: 'eu-west-3' },
    { label: 'Stockholm [eu-north-1]', value: 'eu-north-1' },
    { label: 'Bahrain [me-south-1]', value: 'me-south-1' },
    { label: 'Sao Paolo [sa-east-1]', value: 'sa-east-1' },
  ]

  const generateId = async (name, properties) => {
    try {
      const id = await ec
        .request({
          method: 'POST',
          url: `/sidecars`,
          data: { name, properties },
        })
        .then(res => res.data)
        .then(res => res.ID)
      return id
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
      throw e
      // console.log('handleOnToggle error: ', e.response.data.error.message)
    }
  }

  const createSidecar = async (name, properties) => {
    try {
      const res = await ec
        .request({
          method: 'POST',
          url: `/sidecars`,
          data: { name, properties },
        })
        .then(res => res.data)
      await ec.put(`sidecars/${res.ID}`, {
        bypass: 'failover',
      })
      return res
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
      throw e
      // console.log('handleOnToggle error: ', e.response.data.error.message)
    }
  }

  const getGCRKey = async () => {
    try {
      const resp = await ec.get('/deploy/gcr-key').then(res => res.data)
      return resp
    } catch (e) {
      throw e
    }
  }

  const cloneSidecar = async variables => {
    const deploymentMethod = variables.input.deploymentMethod
    if (deploymentMethod) {
      variables.input.deploymentMethod = deploymentMethod.replace('-', '_')
    }
    return (await cloneSidecarMutation({ variables })).data.cloneSidecar
  }

  return {
    getGCRKey,
    AWSRegions,
    ec,
    handleSetPopup,
    popupTypes,
    config,
    generateId,
    createSidecar,
    cloneSidecar,
  }
}

export default useInstructions
