/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React from 'react'
import styled from '@emotion/styled'

import SearchField from '@jeeves/components/SearchField'

const FiltersWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 10px;
`

const Filters = ({ filterState: { nameFilter, setNameFilter } }) => (
  <FiltersWrapper>
    <SearchField
      label="Search by name"
      name="sidecarFilter"
      value={nameFilter}
      onChange={e => setNameFilter(e.target.value)}
    />
  </FiltersWrapper>
)

export default Filters
