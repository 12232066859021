import React from 'react'
import Typography from '@mui/material/Typography'
import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'

import { IconCopyButton, Input } from '@jeeves/new-components'

const handleFocus = event => event.target.select()

const AwsCli = () => {
  return (
    <Stack spacing={2}>
      <StepOne />
      <StepTwo />
      <StepThree />
    </Stack>
  )
}

const StepOne = () => {
  const pip3InstallCommand = 'pip3 install cyral'

  return (
    <Stack spacing={1}>
      <Typography variant="h6" sx={{ color: 'primary.main' }}>
        1. Install the Cyral CLI tool
      </Typography>
      <Stack direction="row" spacing={1} sx={{ alignItems: 'flex-end' }}>
        <Stack spacing={0.5} sx={{ flex: 1 }}>
          <Typography variant="h6" sx={{ color: 'text.secondary' }}>
            Please use Python 3.9 or higher.
          </Typography>
          <Input
            defaultValue={pip3InstallCommand}
            onFocus={handleFocus}
            endAdornment={
              <InputAdornment position="end" sx={{ marginLeft: 0 }}>
                <IconCopyButton text={pip3InstallCommand} />
              </InputAdornment>
            }
            sx={{
              typography: 'code',
            }}
            readOnly
            fullWidth
          />
        </Stack>
      </Stack>
    </Stack>
  )
}

const StepTwo = () => {
  let control_plane_address

  if (window._env_.control_plane_address && window._env_.keycloak_frontend_port) {
    control_plane_address = `${window._env_.control_plane_address}:${window._env_.keycloak_frontend_port}`
  } else if (window._env_.control_plane_address) {
    control_plane_address = window._env_.control_plane_address
  } else {
    control_plane_address = '<CONTROL_PLANE_ADDRESS>'
  }

  const s3ConfigurationCommand = `cyral --cp-address ${control_plane_address} access s3`

  return (
    <Stack spacing={1}>
      <Typography variant="h6" sx={{ color: 'primary.main' }}>
        2. Set up your S3 Access Configuration
      </Typography>
      <Input
        defaultValue={s3ConfigurationCommand}
        onFocus={handleFocus}
        endAdornment={
          <InputAdornment position="end" sx={{ marginLeft: 0 }}>
            <IconCopyButton text={s3ConfigurationCommand} />
          </InputAdornment>
        }
        sx={{
          typography: 'code',
        }}
        readOnly
        fullWidth
      />
    </Stack>
  )
}

const StepThree = () => {
  const command = 'aws s3 ls --profile cyral'

  return (
    <Stack spacing={1}>
      <Typography variant="h6" sx={{ color: 'primary.main' }}>
        3. View which S3 Buckets you have access to
      </Typography>
      <Input
        defaultValue={command}
        onFocus={handleFocus}
        endAdornment={
          <InputAdornment position="end" sx={{ marginLeft: 0 }}>
            <IconCopyButton text={command} />
          </InputAdornment>
        }
        sx={{
          typography: 'code',
        }}
        readOnly
        fullWidth
      />
    </Stack>
  )
}

export default AwsCli
