import { useAuth } from '../../../components/Auth'
import ExpressClient from '@jeeves/clients/express'
import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'
import { get as lodashGet } from 'lodash'

const useProfile = () => {
  const { getTokenSilently, getTokenWithPopup } = useAuth()
  const { setPopup, popupTypes } = usePopup()
  const ec = new ExpressClient(getTokenSilently)

  const getOpaqueToken = async () => {
    let token
    try {
      token = await getTokenSilently({ audience: [] })
    } catch (err) {
      if (err.error !== 'consent_required') {
        console.log(' useProfiles.getOpaqueToken error: ', err)
        return
      }
      try {
        token = await getTokenWithPopup({ audience: [] })
      } catch (err) {
        console.log(' useProfiles.getOpaqueToken consent required error: ', err)
        throw err
      }
    }
    return token
  }

  const fetchUserConnections = async () => {
    try {
      const userConnections = await ec.get('/users/connections/names').then(res => res.data)
      return userConnections
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
      throw e
    }
  }

  return {
    fetchUserConnections,
    getOpaqueToken,
  }
}

export default useProfile
