import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { ServiceIcon } from '@jeeves/new-components'
import { styled } from '@mui/material/styles'
import { OverflowTagsWithPopper } from '@jeeves/pages/AccessPortal/components/RepoCards/components/RepoHeaderContent'

const StyledRepositoryRow = styled('tr', {
  name: 'RepositoryRow',
  slot: 'Root',
})(({ theme }) => ({
  cursor: 'pointer',
  ':hover': {
    backgroundColor: theme.palette.grey[100]
  },
  td: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  }
}))

const RepositoryRow = ({ repo }) => {
  const history = useHistory()

  return (
    <StyledRepositoryRow onClick={() => history.push(`/repositories/${repo.id}`)}>
      <Box component="td" sx={{ textAlign: 'center !important', whiteSpace: 'nowrap', width: '200px' }}><ServiceIcon type={repo.type} sx={{ fontSize: '2.5rem' }}/></Box>
      <Box component="td"><Typography variant='h4'>{repo.name}</Typography></Box>
      <Box component="td"><OverflowTagsWithPopper tags={repo.tags}/></Box>
    </StyledRepositoryRow>
  )
}

export default RepositoryRow
