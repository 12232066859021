import { Stack, Typography } from '@mui/material'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'

import { SidecarHeaderIcon, SidecarHeaderInfo, SidecarHeaderTags } from './components'
import SidecarHeaderButton from '../SidecarHeaderButton'

export const SidecarHeader_queryFragment = graphql(`
  fragment SidecarHeader_query on Query {
    sidecar(id: $sidecarId) {
      id
      name
    }
    ...SidecarHeaderIcon_query
    ...SidecarHeaderTags_query
    ...SidecarHeaderInfo_query
  }
`)

interface SidecarHeaderProps {
  query: FragmentType<typeof SidecarHeader_queryFragment>
}

const SidecarHeader = ({ query: queryProp }: SidecarHeaderProps) => {
  const query = useFragment(SidecarHeader_queryFragment, queryProp)

  const sidecarName = query.sidecar.name

  return (
    <Stack spacing={1} sx={{ p: 4, alignItems: 'flex-start' }}>
      <SidecarHeaderButton />

      <Stack direction="row" spacing={3} sx={{ alignItems: 'center' }}>
        <SidecarHeaderIcon query={query} />

        <Stack spacing={1}>
          <Typography variant="h3" sx={{ color: 'text.primary' }}>
            {sidecarName}
          </Typography>

          <Stack spacing={2}>
            <SidecarHeaderInfo query={query} />
            <SidecarHeaderTags query={query} />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default SidecarHeader
