import { useEffect } from 'react'
import useInstructions from '@jeeves/pages/Wrappers/hooks/useInstructions'

import usePopup from '@jeeves/hooks/usePopup'
import { get as lodashGet } from 'lodash'

const TerraformGen = ({ currentDialog, setCurrentDialog }) => {
  const { ec } = useInstructions()
  const { showError } = usePopup()

  useEffect(() => {
    const downloadTemplate = async () => {
      if (currentDialog === 'terraformGKE') {
        try {
          const tftemplate = await ec
            .request({
              method: 'GET',
              timeout: 60000,
              url: `/deploy/terraform/`,
              params: {
                AWSRegion: 'test',
                KeyName: 'test',
                VPC: 'test',
                SidecarName: 'test',
                ControlPlane: 'test',
                PublicSubnets: ['test'],
                ELKAddress: 'test',
              },
            })
            .then(res => res.data)
          await downloadFile(tftemplate)
          setCurrentDialog('')
        } catch (e) {
          const popupMessage = lodashGet(e, 'response.data.error.message', null)
          showError(popupMessage)
          setCurrentDialog('')
        }
      }
    }
    downloadTemplate()
  }, [currentDialog])

  const downloadFile = content => {
    const element = document.createElement('a')
    // eslint-disable-next-line
    const file = new Blob([content], { type: 'application/x-yaml' })
    element.href = URL.createObjectURL(file)
    element.download = 'sidecar.tf'
    document.body.appendChild(element)
    element.click()
  }

  return null
}

export default TerraformGen
