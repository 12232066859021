import React from 'react'

import AlertsTable from './components/AlertsTable'
import Graph from './components/Graph'
import { AlertsProvider } from './contexts/AlertsContext'

const Alerts = () => {
  return (
    <AlertsProvider>
      <Graph />
      <AlertsTable />
    </AlertsProvider>
  )
}

export default Alerts
