/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useState, useEffect } from 'react'
import {
  withStyles,
  MenuItem,
  Grid,
  TextField,
  Switch,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from '@material-ui/core'
import { SidecarDialog } from '../index'
import { ButtonLoading } from '@jeeves/components/Loading'
import { Button, FlexGrow } from '@jeeves/components/Primitives'
import useInstructions from '../../../hooks/useInstructions'
import { LogsIntegration, MetricsIntegration } from '../IntegrationInputs'
import useWrappers from '@jeeves/pages/Wrappers/hooks/useWrappers'
import { useAuth } from '@jeeves/hooks'
import ExpressClient from '@jeeves/clients/express'
import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'
import { useAccounts } from '@jeeves/pages/WrapperDetail/components/Accounts/useAccounts'

import { get as lodashGet } from 'lodash'

const styles = theme => ({
  title: {
    padding: theme.spacing.unit * 3,
  },
  content: {
    marginBottom: theme.spacing.unit * 4,
  },
  deleteIcon: {
    fontSize: 28,
  },
  DialogTitle: {
    padding: theme.spacing.unit * 3,
  },
  DialogContent: {
    padding: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 5,
    paddingTop: 0,
  },
  DialogActions: {
    paddingBottom: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingTop: 0,
    backgroundColor: theme.palette.background.primary,
  },
  mt3: {
    marginTop: theme.spacing.unit * 3,
  },
  typography: {
    marginBottom: theme.spacing.unit,
  },
  vpcSwitch: {
    marginTop: theme.spacing.unit * 2,
  },
})

export const TerraformGenContents = withStyles(styles)(
  ({
    newTemplate,
    alreadyCreated,
    saveProperties,
    onSave,
    clone,
    setCloned,
    setFinished,
    open,
    onClose,
    onGenerated,
    classes,
    logsOptions,
    metricsOptions,
    sidecarName: argsSidecarName,
    keyName: argsKeyName,
    vpc: argsVpc,
    logIntegrationID: argsLogIntegrationID,
    metricsIntegrationID: argsMetricsIntegrationID,
    publiclyAccessible: argsPubliclyAccessible,
    awsRegion: argsAwsRegion,
    publicSubnets: argsPublicSubnets,
    sidecarId: argsSidecarId,
    repos,
    suggestedSidecarName,
  }) => {
    const { showError } = usePopup()
    const { AWSRegions, generateId, createSidecar, cloneSidecar } = useInstructions()
    const [awsRegion, setAwsRegion] = useState(argsAwsRegion || '')
    const [keyName, setKeyName] = useState(argsKeyName || '')
    const [vpc, setVpc] = useState(argsVpc || '')
    const [sidecarName, setSidecarName] = useState(
      suggestedSidecarName || (alreadyCreated ? argsSidecarName : '')
    )
    const [publicSubnets, setPublicSubnets] = useState(argsPublicSubnets || '')
    const [elkAddress, setElkAddress] = useState('') // eslint-disable-line
    const [createVPC, setCreateVPC] = useState(false) // eslint-disable-line
    const [logIntegration, setLogIntegration] = useState(
      argsLogIntegrationID || (alreadyCreated && !newTemplate ? 'cloudwatch' : '')
    )
    const [metricsIntegration, setMetricsIntegration] = useState(
      argsMetricsIntegrationID || (alreadyCreated && !newTemplate ? 'default' : '')
    )
    const [publiclyAccessible, setPubliclyAccessible] = useState(argsPubliclyAccessible === 'true')
    const [generating, setGenerating] = useState(false)
    const { wrapperHandlers, repoHandlers } = useWrappers()
    const [sidecarNames, setSidecarNames] = useState([])
    const { user, getTokenSilently } = useAuth()
    const ec = new ExpressClient(getTokenSilently)
    const { createSidecarAccount } = useAccounts()

    const handleOnClose = () => {
      onClose()
      clearFields()
    }

    const clearFields = () => {
      setAwsRegion('')
      setKeyName('')
      setVpc('')
      setSidecarName('')
      setPublicSubnets('')
      setElkAddress('')
      setLogIntegration('')
      setMetricsIntegration('')
    }

    const downloadFile = content => {
      const element = document.createElement('a')
      // eslint-disable-next-line
      const file = new Blob([content], { type: 'application/x-yaml' })
      element.href = URL.createObjectURL(file)
      element.download = 'sidecar.tf'
      document.body.appendChild(element)
      element.click()
    }

    const sidecarProperties = {
      deploymentMethod: 'tf-aws-ec2',
      awsRegion,
      keyName,
      vpc,
      publicSubnets,
      logIntegrationID: logIntegration,
      metricsIntegrationID: metricsIntegration,
      publiclyAccessible: publiclyAccessible ? 'true' : undefined,
    }

    const handleOnSubmit = async () => {
      const publicSubnetsArr = publicSubnets ? publicSubnets.replace(/\s/g, '').split(',') : null
      const replaceTerm = window.location.origin.includes('https') ? 'https://' : 'http://'
      const controlPlane = window.location.origin.replace(replaceTerm, '')
      const vpcValue = createVPC ? 'cyral-default' : vpc
      const publicSubnetsValue = createVPC ? 'cyral-default' : publicSubnetsArr
      let sidecar = {}

      setGenerating(true)

      try {
        if (alreadyCreated) {
          if (clone) {
            const {
              deploymentMethod,
              logIntegration,
              metricsIntegrationID,
              ...restSidecarProperties
            } = sidecarProperties
            const newSidecarResponse = await cloneSidecar({
              sidecarId: argsSidecarId,
              input: {
                name: sidecarName,
                deploymentMethod,
                logIntegration,
                metricsIntegrationID,
                additionalProperties: restSidecarProperties,
              },
            })
            sidecar.ID = newSidecarResponse.sidecar.id
            sidecar.clientID = newSidecarResponse.sidecarAccount.clientId
            sidecar.clientSecret = newSidecarResponse.sidecarAccount.clientSecret
          } else {
            sidecar.ID = argsSidecarId
            const sidecarAccount = await createSidecarAccount(sidecar.ID)
            sidecar.clientID = sidecarAccount.clientId
            sidecar.clientSecret = sidecarAccount.clientSecret
          }
          if (saveProperties) {
            await ec.put(`/sidecars/${argsSidecarId}`, {
              properties: sidecarProperties,
            })
          }
        } else {
          sidecar = await createSidecar(sidecarName, sidecarProperties)
        }
        // TO DO: correct name of state variable can make this variable name better
        const logIntegrationObject = logsOptions.find(
          logsOption => logsOption.id === logIntegration
        )
        const metricsIntegrationObject = metricsOptions.find(
          metricsOption => metricsOption.id === metricsIntegration
        )
        const tftemplate = await ec
          .request({
            method: 'GET',
            timeout: 60000,
            url: `/deploy/terraform/`,
            params: {
              SidecarId: sidecar.ID,
              AWSRegion: awsRegion,
              KeyName: keyName,
              VPC: vpcValue,
              SidecarName: sidecarName,
              ControlPlane: controlPlane,
              PublicSubnets: publicSubnetsValue,
              publiclyAccessible: publiclyAccessible,
              logIntegrationType: logIntegrationObject.type,
              logIntegrationValue: logIntegrationObject.value,
              metricsIntegrationType: metricsIntegrationObject.type,
              metricsIntegrationValue: metricsIntegrationObject.value,
              useTLS: logIntegrationObject.useTLS,
              useMutualAuthentication: logIntegrationObject.useMutualAuthentication,
              usePrivateCertificateChain: logIntegrationObject.usePrivateCertificateChain,
              SumoLogicHost: logIntegrationObject.host,
              SumoLogicUri: logIntegrationObject.uri,
              clientId: sidecar.clientID,
              clientSecret: sidecar.clientSecret,
              ELKUsername: logIntegrationObject.elkUsername,
              ELKPassword: logIntegrationObject.elkPassword,
            },
          })
          .then(res => res.data)
        await downloadFile(tftemplate)
        handleOnClose()
        if (clone && setCloned) setCloned(true)
        if (setFinished) setFinished(true)
        // Save everytime because client id, secret is being generated
        if (onSave) onSave()
      } catch (e) {
        console.log(`TerraformGen.handleOnSubmit.error:`, e)
        const popupMessage = lodashGet(e, 'response.data.error.message', null)
        showError(popupMessage)
      } finally {
        setGenerating(false)
      }
    }

    useEffect(() => {
      if (!open) handleOnClose()
    }, [open])

    return (
      <div>
        <Grid container spacing={24} className={classes.content}>
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              name="AwsRegion"
              value={awsRegion}
              label="AWS region"
              onChange={e => setAwsRegion(e.target.value)}
            >
              {AWSRegions.map(region => (
                <MenuItem key={region.value} value={region.value}>
                  {region.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="KeyName"
              value={keyName}
              label="AWS key pair name"
              onChange={e => setKeyName(e.target.value)}
            ></TextField>
          </Grid>
          {!createVPC && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="Vpc"
                value={vpc}
                label="AWS VPC ID"
                onChange={e => setVpc(e.target.value)}
              ></TextField>
            </Grid>
          )}

          {(!alreadyCreated || (alreadyCreated && clone)) && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="SidecarName"
                value={sidecarName}
                label="Sidecar name"
                onChange={e => setSidecarName(e.target.value)}
              ></TextField>
            </Grid>
          )}

          {!createVPC && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="PublicSubnets"
                value={publicSubnets}
                label="Subnet IDs"
                onChange={e => setPublicSubnets(e.target.value)}
              ></TextField>
            </Grid>
          )}

          <Grid item xs={12}>
            <LogsIntegration
              logIntegration={logIntegration}
              setLogIntegration={setLogIntegration}
              logsOptions={logsOptions}
            />
          </Grid>
          <Grid item xs={12}>
            <MetricsIntegration
              metricsIntegration={metricsIntegration}
              setMetricsIntegration={setMetricsIntegration}
              metricsOptions={metricsOptions}
            />
          </Grid>
        </Grid>

        <Grid
          container
          css={{ justifyContent: 'space-between', alignItems: 'center', marginTop: '32px' }}
        >
          <FormControlLabel
            label="Publicly accessible"
            control={
              <Switch
                id="publicly_accessible"
                checked={publiclyAccessible}
                value={publiclyAccessible}
                onChange={e => setPubliclyAccessible(!publiclyAccessible)}
                color="primary"
              />
            }
          />

          <FlexGrow />

          <Button
            variant="outlined"
            color="primary"
            onClick={handleOnClose}
            css={{ marginRight: '16px' }}
            disabled={generating}
          >
            Cancel
          </Button>

          <div css={{ position: 'relative' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOnSubmit}
              disabled={generating}
            >
              Generate
            </Button>
            {generating && <ButtonLoading />}
          </div>
        </Grid>
      </div>
    )
  }
)

const TerraformGen = ({ currentDialog, setCurrentDialog, ...props }) => {
  const [open, setOpen] = useState(currentDialog === 'tf-aws-ec2')

  useEffect(() => {
    setOpen(currentDialog === 'tf-aws-ec2')
  }, [currentDialog])

  const handleClose = () => {
    setCurrentDialog('')
  }

  return (
    <SidecarDialog open={open} onClose={handleClose} title="Generate a file">
      <TerraformGenContents open={open} onClose={handleClose} {...props}></TerraformGenContents>
    </SidecarDialog>
  )
}

export default TerraformGen
