import * as React from 'react'
import { useMutation, gql } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { useToast } from '@jeeves/new-components'

import { validationSchema } from './schema'

const ADD_REPOSITORY = gql`
  mutation Mutation($input: CreateRepoInput!) {
    createRepo(input: $input) {
      repo {
        id
        name
        type
        tags
      }
    }
  }
`

const useAddRepositoryModal = () => {
  const [open, setOpen] = React.useState(false)
  const { toast } = useToast()
  const [addRepo] = useMutation(ADD_REPOSITORY, {
    onError: () => {
      toast({
        variant: 'error',
        description: 'An error occurred while creating the repository. Please try again.',
      })
    },
    refetchQueries: ['ReposWithUtilities'],
  })

  const methods = useForm({
    defaultValues: {
      repositoryType: '',
      repositoryName: '',
    },
    resolver: zodResolver(validationSchema),
    shouldUnregister: true,
  })

  const onClose = React.useCallback(() => {
    setOpen(false)
    methods.reset()
  }, [methods])

  React.useEffect(() => {
    if (methods.formState.isSubmitSuccessful) {
      onClose()
    }
  }, [onClose, methods.formState.isSubmitSuccessful])

  const getMongoDBReplicaSetRepoInput = data => {
    const { connectionFormat, repositoryName, numNodes, replicaSetName, nodes, SRVRecord } = data
    const isStaticNodes = connectionFormat === 'StaticNodeSpecification'
    const clusterSpecification = isStaticNodes ? { staticNodes: nodes } : { SRVRecord: SRVRecord }

    return {
      replicaSet: {
        name: repositoryName,
        numNodes,
        replicaSetName,
        clusterSpecification,
      },
    }
  }

  const getMongoDBShardedClusterRepoInput = data => {
    const { connectionFormat, repositoryName, numNodes, nodes, SRVRecord } = data
    const isStaticNodes = connectionFormat === 'StaticNodeSpecification'
    const clusterSpecification = isStaticNodes ? { staticNodes: nodes } : { SRVRecord }

    return {
      shardedCluster: {
        name: repositoryName,
        numNodes,
        clusterSpecification,
      },
    }
  }

  const getMongoDBClusterInput = data => {
    let mongoDBCluster = {}

    if (data.clusterType === 'replicaSet') {
      mongoDBCluster = getMongoDBReplicaSetRepoInput(data)
    }

    if (data.clusterType === 'shardedCluster') {
      mongoDBCluster = getMongoDBShardedClusterRepoInput(data)
    }

    return {
      mongoDBCluster,
    }
  }

  const getInput = data => {
    if (data.repositoryType === 'mongodb' && data.clusterType !== 'standalone') {
      return getMongoDBClusterInput(data)
    }

    return {
      standaloneRepo: {
        name: data.repositoryName,
        type: data.repositoryType,
        endpoint: {
          host: data.host,
          port: data.port,
        },
      },
    }
  }

  const onSubmit = async data => {
    const input = getInput(data)

    await addRepo({ variables: { input } })
  }

  return {
    methods,
    onSubmit: methods.handleSubmit(onSubmit),
    open,
    closeModal: onClose,
    openModal: () => setOpen(true),
  }
}

export default useAddRepositoryModal
