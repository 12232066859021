import { useHistory } from 'react-router'
import { Stack, Typography, TableRow } from '@mui/material'
import {
  CheckCircleOutlineRounded,
  InfoOutlined,
  RemoveCircleOutline,
  WarningAmberRounded,
} from '@mui/icons-material'

import { SidecarHealth } from '@jeeves/graphql/graphql'
import { Badge, ServiceIcon } from '@jeeves/new-components'
import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { isSidecarOutdated } from '@jeeves/utils/comparation'
import { Tag } from '../index'

const SidecarsRow_SidecarFragment = graphql(`
  fragment SidecarsRow_SidecarFragment on Sidecar {
    id
    cloud
    instances {
      id
    }
    health
    labels
    name
    version
    bindings(first: $firstBindings) {
      totalCount
    }
  }
`)

const SidecarsRow_QueryFragment = graphql(`
  fragment SidecarsRow_QueryFragment on Query {
    systemInfo {
      latestSidecarVersion
    }
  }
`)

const variantIcons = {
  [SidecarHealth.Healthy]: <CheckCircleOutlineRounded sx={{ color: 'cyralColors.green.300' }} />,
  [SidecarHealth.Unhealthy]: <InfoOutlined sx={{ color: 'error.main' }} />,
  [SidecarHealth.Degraded]: <WarningAmberRounded sx={{ color: 'cyralColors.yellow.300' }} />,
  [SidecarHealth.Unknown]: <RemoveCircleOutline sx={{ color: 'cyralColors.grey.300' }} />,
} as const

interface Props {
  sidecar: FragmentType<typeof SidecarsRow_SidecarFragment>
  query: FragmentType<typeof SidecarsRow_QueryFragment>
}

const SidecarsRow = ({ sidecar: sidecarProp, query: queryProp }: Props) => {
  const sidecar = useFragment(SidecarsRow_SidecarFragment, sidecarProp)
  const query = useFragment(SidecarsRow_QueryFragment, queryProp)

  const history = useHistory()

  const { id, name, cloud, labels, health, instances, version, bindings } = sidecar

  const isSidecarVersionUpgradeAvailable = isSidecarOutdated({
    sidecarVersion: version,
    latestSidecarVersion: query.systemInfo?.latestSidecarVersion ?? '',
  })

  const renderTags = (tags: string[]) => {
    const TAGS_TO_DISPLAY = 1
    const displayedTags = tags.slice(0, TAGS_TO_DISPLAY)
    const overflowTags = tags.slice(TAGS_TO_DISPLAY)
    const overflowTagsCount = overflowTags?.length

    return (
      <>
        {displayedTags.map((tag, index) => {
          return <Tag key={index} text={tag} />
        })}

        {Boolean(overflowTagsCount) && <Tag text={`+${overflowTagsCount}`} color="grey" />}
      </>
    )
  }

  const handleSidecarClick = () => {
    history.push(`/sidecars/${id}`)
  }

  return (
    <TableRow onClick={handleSidecarClick} hover sx={{ cursor: 'pointer' }}>
      <td>{variantIcons[health]}</td>
      <td>
        <Stack spacing={1} sx={{ alignItems: 'flex-start', py: 4 }}>
          <Typography variant="h4" sx={{ color: 'text.primary' }}>
            {name}
          </Typography>
          {isSidecarVersionUpgradeAvailable && <Badge variant="primary">Upgrade available</Badge>}
        </Stack>
      </td>
      <td>
        <Stack direction="row" spacing={1}>
          {renderTags(labels)}
        </Stack>
      </td>
      <td>{cloud && <ServiceIcon type={cloud.toLowerCase()} sx={{ fontSize: 28 }} />}</td>
      <td>
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          {instances.length}
        </Typography>
      </td>
      <td>
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          {bindings.totalCount}
        </Typography>
      </td>
    </TableRow>
  )
}

export default SidecarsRow
