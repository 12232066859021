import * as React from 'react'
import Button from '@mui/material/Button'

import Dialog from '@mui/material/Dialog'

import Stack from '@mui/material/Stack'

import { useForm, Controller } from 'react-hook-form'

import { InputLabel, Input, DialogTitle, DialogActions, DialogContent } from './Dialogs.styles'
import { gql, useMutation } from '@apollo/client'
import { DATALABELS_QUERY } from '../../DataLabels'

import { Tag } from '@jeeves/new-components'
import Box from '@mui/material/Box'

const UPDATE_LABEL = gql`
  mutation UpdateCustomDatalabel($datalabelName: String!, $datalabel: CustomDatalabelUpdateInput!) {
    updateCustomDatalabel(datalabelName: $datalabelName, datalabel: $datalabel) {
      description
      type
      tags
      classificationRule {
        ruleType
        ruleCode
      }
    }
  }
`

export const EditLabel = ({ editLabelOpen, setEditLabelOpen, dataLabel }) => {
  const [tags, setTags] = React.useState(dataLabel.tags)
  const {
    register,
    control,
    trigger,
    getValues,
    reset,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues: { name: dataLabel.name, description: dataLabel.description } })
  const [updateLabel, { data, loading, error }] = useMutation(UPDATE_LABEL, {
    refetchQueries: [DATALABELS_QUERY],
  })

  const handleOnClose = () => {
    setEditLabelOpen(false)
    setTags(dataLabel.tags)
    reset()
  }

  const onSubmit = async () => {
    const { name, description } = getValues()

    const datalabel = {
      description: description,
      tags: tags,

      classificationRule: {
        ruleType: 'UNKNOWN',
        ruleCode: 'string',
        enabled: true,
      },
    }

    try {
      await updateLabel({
        variables: { datalabelName: name, datalabel: datalabel },
      })
      handleOnClose()
    } catch (e) {
      console.error(e)
    }
  }

  const handleTagDelete = tag => {
    const newTags = tags.filter(t => t !== tag)
    setTags(newTags)
  }

  const handleAddTag = () => {
    const currentTag = getValues('currentTag')
    const newTags = [...tags, currentTag]

    setTags(newTags)
    setValue('currentTag', '')
  }

  return (
    <div>
      <Dialog
        open={editLabelOpen}
        onClose={() => setEditLabelOpen(false)}
        fullWidth
        sx={{ radius: '12px' }}
      >
        <DialogTitle>Edit Data Label</DialogTitle>
        <DialogContent>
          <Stack sx={{ mt: 0.5 }}>
            <form onSubmit={handleSubmit(data => {})}>
              <InputLabel>Name</InputLabel>

              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Name your label..."
                    fullWidth
                    disabled
                    input={<Input />}
                  ></Input>
                )}
              />

              <InputLabel>Tags</InputLabel>
              <Stack direction="row" justifyContent="space-between">
                <Controller
                  name="currentTag"
                  control={control}
                  render={({ field }) => (
                    <Input
                      sx={{ width: '85%' }}
                      placeholder="Add a tag..."
                      {...field}
                      fullWidth
                    ></Input>
                  )}
                />
                <Button
                  sx={{ alignSelf: 'start', marginTop: '3px' }}
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={handleAddTag}
                >
                  Add
                </Button>
              </Stack>

              <Box
                sx={{
                  border: `1px solid`,
                  borderColor: 'cyralColors.grey.200',
                  width: '100%',
                  height: '77px',
                  mb: 2,
                  padding: 1,
                }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ flexWrap: 'wrap', alignItems: 'flex-end' }}
                >
                  {tags.map((tag, idx) => (
                    <Tag key={idx} onRemove={() => handleTagDelete(tag)}>
                      {tag}
                    </Tag>
                  ))}
                </Stack>
              </Box>

              <InputLabel>Description</InputLabel>

              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Name your label..."
                    rows={6}
                    cols={60}
                    multiline
                    fullWidth
                    input={<Input />}
                  ></Input>
                )}
              />
            </form>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnClose} color="primary" disabled={isSubmitting}>
            Cancel
          </Button>

          <Button variant="contained" color="primary" disabled={isSubmitting} onClick={onSubmit}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
