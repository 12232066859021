import { gql } from '@apollo/client'

export const ACCESS_RULE_FIELDS = gql`
  fragment accessRuleFields on AccessRule {
    id
    validFrom
    validUntil
    identity {
      type
      name
    }
    accessRestrictions {
      ... on PagerdutyIntegration {
        id
      }
      ... on DuoIntegration {
        id
      }
    }
  }
`

export const USER_ACCOUNT_FIELDS = gql`
  fragment userAccountFields on UserAccount {
    id
    name
    credentials {
      ... on AWSIAMUserAccountCredentials {
        roleARN
      }
      ... on AWSSecretsManagerUserAccountCredentials {
        secretARN
      }
      ... on EnvironmentVariableUserAccountCredentials {
        variableName
      }
      ... on HashicorpVaultUserAccountCredentials {
        path
        isDynamicUserAccount
      }
      ... on KubernetesSecretUserAccountCredentials {
        secretName
        secretKey
      }
      ... on GCPSecretManagerUserAccountCredentials {
        secretName
      }
      ... on CyralStorageUserAccountCredentials {
        password
      }
    }
    accessRules {
      ...accessRuleFields
    }
    autoApproval {
      enabled
      duration
    }
    numUsersAccessed
    ... on MongoDBUserAccount {
      authenticationDatabase
    }
  }
  ${ACCESS_RULE_FIELDS}
`

export const REPO_USER_ACCOUNTS = gql`
  fragment repoUserAccounts on RepoWithSSOFields {
    userAccounts {
      ...userAccountFields
    }
  }
  ${USER_ACCOUNT_FIELDS}
`
