/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useEffect, useState, useContext } from 'react'

import { Table, TableBody, TableFooter, TableRow } from '@material-ui/core'

import { stableSort, getSorting } from '@jeeves/utils'
import { TableHead, TablePagination } from '@jeeves/components/Table'
import { Paper } from '@jeeves/components/Primitives'
import Toolbar from '@jeeves/components/Toolbar'
import Loading from '@jeeves/components/Loading'
import RowSpan from '@jeeves/components/RowSpan'

import Row from './Row'
import { AlertsContext } from '../../contexts/AlertsContext'
import TimeSpanSelector from '../TimeSpanSelector'
import useAlerts from '../../hooks/useAlerts'
import { useAppConfig } from '@jeeves/hooks'
import { getDocsURL } from '@jeeves/utils/docsURL'

const AlertsTable = () => {
  const [state, setState] = useContext(AlertsContext)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openAlert, setOpenAlert] = useState(null)
  const {
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    ec,
    lodashGet,
    getTimeSpan,
    timeSpan,
    setTimeSpan,
    setState: setAlertsContextState,
  } = useAlerts()
  const {
    config: { isDemo },
  } = useAppConfig()

  const { rowsPerPage, page, order, orderBy, alerts } = state

  const withinTimeSpan = d => new Date().getTime() - d.time * 1000 < getTimeSpan(timeSpan).value

  const fetchAlerts = async () => {
    setLoading(true)
    try {
      const response = await ec.get('/alerts/all')
      const data = response.data.filter(withinTimeSpan)
      setState(state => ({
        ...state,
        alerts: data,
      }))
      setAlertsContextState(prevState => ({ ...prevState, alerts: data }))
    } catch (e) {
      setError(true)
      console.log('Get alerts error', e)
      setState(state => ({
        ...state,
        alerts: undefined,
      }))
      setAlertsContextState(prevState => ({ ...prevState, alerts: undefined }))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchAlerts()
  }, [timeSpan])

  const columns = [
    { id: 'details', label: null, css: { padding: 0 } },
    { id: 'time', label: 'Date' },
    { id: 'service', label: 'Service', css: { padding: '4px 24px' }, demoOnly: true },
    { id: 'category', label: 'Event', css: { padding: '4px 24px' } },
    { id: 'severity', label: 'Severity', disablePadding: true },
    { id: 'identity.user', label: 'Identity' },
    { id: 'reponame', label: 'Repository' },
    { id: 'location', label: 'Location', demoOnly: true },
  ].filter(column => (isDemo ? true : !column.demoOnly))

  const getTableBody = () =>
    stableSort(alerts, getSorting(order, orderBy))
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((alert, index) => (
        <Row
          key={alert.id}
          alert={alert}
          key={index}
          expanded={openAlert === alert.id}
          greyedOut={openAlert && openAlert !== alert.id}
          handleExpandRow={() => setOpenAlert(openAlert === alert.id ? null : alert.id)}
          closeRow={() => setOpenAlert(null)}
        />
      ))

  return (
    <Paper css={{ minWidth: '750px' }}>
      <Toolbar title="Details" />

      <Table aria-labelledby="Wrappers">
        <TableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          columns={columns}
          rowCount={lodashGet(alerts, 'length', 0)}
        />

        <TableBody>
          {loading && (
            <RowSpan colSpan={columns.length}>
              <Loading />
            </RowSpan>
          )}
          {!loading && error && (
            <RowSpan colSpan={columns.length}>There was a problem loading alerts</RowSpan>
          )}
          {!loading && !error && alerts && alerts.length === 0 && (
            <RowSpan colSpan={columns.length}>No alerts found</RowSpan>
          )}
          {!loading && !error && alerts && alerts.length > 0 && getTableBody()}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={lodashGet(alerts, 'length', 0)}
              colSpan={8}
              page={page}
              rowsPerPage={rowsPerPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  )
}

export default AlertsTable
