import React from 'react'

import AwsCli from './components/AwsCli'
import FileBrowser from './components/FileBrowser'

import { ConnectionTypesTabs } from '../../../../components'

const S3AccordionContent = ({ accessPortalSidecar, accessPortalSidecarBinding }) => {
  const connectionTypes = [
    {
      label: 'Browser',
      value: 'browser',
      content: <FileBrowser />,
      copyValue: null,
    },
    {
      label: 'AWS CLI',
      value: 'aws-cli',
      content: <AwsCli />,
      copyValue: null,
    },
  ]

  return <ConnectionTypesTabs connectionTypes={connectionTypes} />
}

export default S3AccordionContent
