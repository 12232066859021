import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Divider, Grid, Tab, Tabs, Toolbar, Typography, withStyles } from '@material-ui/core'
import { blue } from '@material-ui/core/colors'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

import { Button, Paper } from '@jeeves/components/Primitives'
import useInstructions from '../hooks/useInstructions'
import { useFetch } from '../../../support/hooks'
import AutomatedGen from './components/AutomatedGen'
import CloudFormGen from './components/CloudFormGen'
import DockerGen from './components/DockerGen'
import CustomGen from './components/CustomGen'
import TerraformGen from './components/TerraformGen'
import TerraformGKE from './components/TerraformGKE'
import Helm3Gen from './components/Helm3Gen'
import BinariesGen from './components/BinariesGen'
import SingleContainerGen from './components/SingleContainerGen'

import DOMPurify from 'dompurify'
import { get as lodashGet } from 'lodash'
import { useAppConfig } from '@jeeves/hooks'
import ExpressInstallerGen from './components/ExpressInstallerGen'

let dockerModel,
  dockerLabels,
  cloudFormationModel,
  cloudFormationLabels,
  terraformModel,
  terraformLabels

const DEPLOYMENT_MAP = new Map([
  [
    44001304194,
    {
      deploymentMethod: 'docker-compose',
      splitKey: `You can generate the template by logging into your account.`,
      model: dockerModel,
      labels: dockerLabels,
      buttonLabel: 'Generate Link',
    },
  ],
  [
    44001761071,
    {
      deploymentMethod: 'cft-ec2',
      splitKey: '<p>You can generate the template by logging into your account.</p>',
      model: cloudFormationModel,
      labels: cloudFormationLabels,
      buttonLabel: 'Generate Template',
    },
  ],
  [
    44001858432,
    {
      deploymentMethod: 'tf-aws-ec2',
      splitKey: '<p>You can generate the template by logging into your account</p>',
      model: terraformModel,
      labels: terraformLabels,
      buttonLabel: 'Generate Template',
    },
  ],
  [
    'fake_0001',
    {
      deploymentMethod: 'automated',
      splitKey: '<p>Log into your account to deploy the template</p>',
      model: terraformModel,
      labels: terraformLabels,
      buttonLabel: 'Deploy',
    },
  ],
  [
    'extra_0001',
    {
      deploymentMethod: 'custom',
      splitKey: '<p>Log into your account to deploy the template</p>',
      model: terraformModel,
      labels: terraformLabels,
      buttonLabel: 'Generate',
    },
  ],
  [
    'extra_0002',
    {
      deploymentMethod: 'linux',
      splitKey: '<p>Log into your account to deploy the template</p>',
      model: terraformModel,
      labels: terraformLabels,
      buttonLabel: 'Generate',
    },
  ],
  [
    'extra_0003',
    {
      deploymentMethod: 'single-container',
      splitKey: '<p>Log into your account to deploy the template</p>',
      model: terraformModel,
      labels: terraformLabels,
      buttonLabel: 'Create',
    },
  ],
  [
    'express-installer',
    {
      deploymentMethod: 'express-installer',
      splitKey: '<p>Log into your account to deploy the template</p>',
      model: terraformModel,
      labels: terraformLabels,
      buttonLabel: 'Create',
    },
  ],
  [
    44001918400,
    {
      deploymentMethod: 'terraformGKE',
      splitKey: 'You can generate the template by logging into your account',
      model: terraformModel,
      labels: terraformLabels,
      buttonLabel: 'Download template',
    },
  ],
  [
    44001945719,
    {
      deploymentMethod: 'helm',
      splitKey: 'You can generate the template by logging into your account.',
      model: terraformModel,
      labels: terraformLabels,
      buttonLabel: 'Generate values file',
    },
  ],
])

const VerticalTabs = withStyles(theme => ({
  flexContainer: {
    flexDirection: 'column',
    background: theme.palette.background.default,
    paddingBottom: 0,
  },
  indicator: {
    display: 'none',
  },
}))(Tabs)

const MyTab = withStyles(theme => ({
  selected: {
    color: theme.palette.primary.main,
    borderBottom: 0,
    fontWeight: 600,
    background: theme.palette.text.white,
  },
}))(Tab)

const styles = theme => ({
  backIcon: {
    fontSize: '28px',
  },
  tab: {
    opacity: 1,
    textAlign: 'left',
    textTransform: 'none',
    maxWidth: '100%',
  },
  hrtab: {
    opacity: 1,
    textAlign: 'left',
    textTransform: 'none',
    maxWidth: '100%',
    borderBottomStyle: 'solid',
    borderBottomWidth: 2,
    borderBottomColor: theme.palette.primary.main,
  },
  tabs: {
    height: '100%',
    background: theme.palette.background.default,
    paddingTop: theme.spacing.unit,
  },
  tabTitle: {
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.background.secondary,
  },
  platform: {
    paddingLeft: theme.spacing.unit * 3,
  },
  floatRight: {
    float: 'right',
  },
  markdown: {
    fontFamily: theme.fontFamily,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    '& *': {
      fontFamily: `${theme.fontFamily} !important`,
    },
    '& h2': {
      fontSize: theme.spacing.unit * 2,
      fontWeight: 400,
    },
    '& h3': {
      fontSize: theme.spacing.unit * 1.75,
      fontWeight: 400,
    },
    '& ol': {
      counterReset: 'section',
      listStyleType: 'none',
      marginLeft: 0,
      paddingLeft: 0,
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 3,
      color: blue,
    },
    '& li': {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
      fontSize: theme.spacing.unit * 1.75,
      lineHeight: 1.6,
    },
    '& pre': {
      fontFamily: ["'Roboto Mono', sans-serif"],
      fontSize: '0.75rem',
      background: theme.palette.background.default,
      padding: theme.spacing.unit,
      overflowX: 'auto',
    },
    '& a span': {
      fontFamily: `${theme.fontFamily} !important`,
    },
    '& a': {
      color: theme.palette.primary.main,
    },
    '& p': {
      marginTop: 0,
      marginBottom: theme.spacing.unit,
    },
  },
  bottom: {
    padding: theme.spacing.unit * 3,
    paddingLeft: 0,
  },
  button: {
    padding: theme.spacing.unit * 2,
  },
  grow: {
    flexGrow: 1,
  },
})

const WrapperInstructions = ({ classes }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [{ data: fetchedInstructions }] = useFetch('/freshdesk/articles')
  const [instructions, setInstructions] = useState(fetchedInstructions)
  const { ec, popupTypes, handleSetPopup } = useInstructions()
  const [currentDialog, setCurrentDialog] = useState('')
  const [logsOptions, setLogsOptions] = useState([])
  const [metricsOptions, setMetricsOptions] = useState([])
  const { license } = useAppConfig()
  const [haveExpressInstaller, setHaveExpressInstaller] = useState(false)

  useEffect(() => {
    const fetchIntegrationOptions = () => {
      try {
        ec.get('/integrations/logging').then(res => setLogsOptions(res.data))
        ec.get('/integrations/metrics').then(res => setMetricsOptions(res.data))
      } catch (e) {
        const popupMessage = lodashGet(e, 'response.data.error.message', '')
        handleSetPopup(popupTypes.ERROR, popupMessage)
      }
    }
    fetchIntegrationOptions()
  }, [])

  const splitInstructionsHTML = instructions => {
    const activeID = instructions[activeIndex].id
    const { splitKey } = DEPLOYMENT_MAP.get(activeID)
    // console.log('splitInstructionsHTML(): ', instructions)
    const splitArray = instructions[activeIndex].description.split(splitKey)
    return splitArray
  }

  const handleChange = (_, activeIndex) => {
    setActiveIndex(activeIndex)
  }

  const replaceInstructions = instructions => {
    const replacedInstructions = instructions
      .map(instruction => {
        if (!(typeof instruction === 'string')) return instruction
        return instruction.replace(/wrapper/gi, 'sidecar')
      })
      .map(instruction => {
        if (!instruction.description) return instruction
        instruction.description = instruction.description
          .replace(/<td[^\>]*style="width/gi, '<td style="border: thin solid; padding: 3px; width') // eslint-disable-line
          .replace(/<th>/gi, '<th style="border: thin solid;">')
          .replace(/<table style="width/gi, '<table style="border-collapse: collapse; width')
        return instruction
      })

    return replacedInstructions
  }

  const renderTabs = () => {
    if (!instructions || Object.keys(license).length === 0) {
      // console.log('renderTabs no instructionsHTML')
      return null
    }

    let replacedInstructions = replaceInstructions(instructions)

    return replacedInstructions.map((article, index) => {
      if (haveExpressInstaller && index == 0) {
        return <MyTab className={classes.hrtab} label={article.title} key={article.id} />
      }else{
        return <MyTab className={classes.tab} label={article.title} key={article.id} />
      }
    })
  }

  const renderContent = () => {
    if (!instructions || instructions.length === 0) {
      return null
    }

    const activeID = instructions[activeIndex].id
    if (activeID === 'additional')
      return (
        <div>
          <Typography>We also support the following deployment methods:</Typography>
          <ul>
            <li>Deploy a sidecar on EC2 running CentOS</li>
            <li>Deploy a sidecar using Docker Compose</li>
            <li>Deploy a sidecar on AWS ECS using ECS CLI</li>
            <li>Deploy a sidecar to Kubernetes cluster using Helm2</li>
            <li>Deploy a sidecar to Ubuntu</li>
            <li>Deploy a sidecar to GKE using Terraform</li>
          </ul>
          <a
            href="https://support.cyral.com/support/solutions/folders/44000911526"
            css={{ textDecoration: 'none' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography css={{ fontSize: '14px', fontWeight: 'bold' }} color="primary">
              {'Learn more \u2192'}
            </Typography>
          </a>
        </div>
      )

    const replacedInstructions = replaceInstructions(instructions)
    const replacedIds = [
      44001304194,
      44001761071,
      44001858432,
      44001918400,
      44001877924,
      44001945719,
      'fake_0001',
      'extra_0001',
      'extra_0002',
      'extra_0003',
      'express-installer',
    ]
    const isReplaced = id => !replacedIds.includes(id)

    if (isReplaced(activeID)) {
      return (
        <Typography component="div">
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(replacedInstructions[activeIndex].description),
            }}
          />
        </Typography>
      )
    }

    const { deploymentMethod, buttonLabel } = DEPLOYMENT_MAP.get(activeID)

    const [preBreakHTML, postBreakHTML] = splitInstructionsHTML(replacedInstructions)

    return (
      <Typography component="div">
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(preBreakHTML) }} />
        <div className={classes.bottom}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setCurrentDialog(deploymentMethod)}
          >
            {buttonLabel}
          </Button>
          <DockerGen
            currentDialog={currentDialog}
            setCurrentDialog={setCurrentDialog}
            logsOptions={[
              ...logsOptions,
              {
                id: 'default',
                label: 'None',
                type: 'docker_default',
                value: 'docker_default',
              },
            ]}
            metricsOptions={metricsOptions}
          />
          <CustomGen
            currentDialog={currentDialog}
            setCurrentDialog={setCurrentDialog}
            logsOptions={[
              ...logsOptions,
              { label: 'None', type: 'docker_default', value: 'docker_default' },
            ]}
            metricsOptions={metricsOptions}
          />
          <CloudFormGen
            currentDialog={currentDialog}
            setCurrentDialog={setCurrentDialog}
            logsOptions={[
              ...logsOptions,
              { id: 'cloudwatch', label: 'CloudWatch', value: 'cloudwatch', type: 'cloudwatch' },
            ]}
            metricsOptions={metricsOptions}
          />
          <TerraformGen
            currentDialog={currentDialog}
            setCurrentDialog={setCurrentDialog}
            logsOptions={[
              ...logsOptions,
              { id: 'cloudwatch', label: 'CloudWatch', value: 'cloudwatch', type: 'cloudwatch' },
            ]}
            metricsOptions={metricsOptions}
          />
          <TerraformGKE currentDialog={currentDialog} setCurrentDialog={setCurrentDialog} />
          <Helm3Gen
            currentDialog={currentDialog}
            setCurrentDialog={setCurrentDialog}
            logsOptions={[...logsOptions, { id: 'default', label: 'None', type: '', value: '' }]}
            metricsOptions={metricsOptions}
          />
          <BinariesGen
            currentDialog={currentDialog}
            setCurrentDialog={setCurrentDialog}
            logsOptions={[
              ...logsOptions,
              { label: 'None', type: 'docker_default', value: 'docker_default' },
            ]}
            metricsOptions={metricsOptions}
          />
          <SingleContainerGen
            currentDialog={currentDialog}
            setCurrentDialog={setCurrentDialog}
            logsOptions={[
              ...logsOptions,
              { label: 'None', type: 'docker_default', value: 'docker_default' },
            ]}
            metricsOptions={metricsOptions}
          />
          <AutomatedGen currentDialog={currentDialog} setCurrentDialog={setCurrentDialog} />
          <ExpressInstallerGen
            currentDialog={currentDialog}
            setCurrentDialog={setCurrentDialog}
            logsOptions={[
              ...logsOptions,
              { label: 'None', type: 'docker_default', value: 'docker_default' },
            ]}
            metricsOptions={metricsOptions}
          />
        </div>
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(postBreakHTML) }} />
      </Typography>
    )
  }

  useEffect(() => {
    if (fetchedInstructions) {
      if (!license || Object.keys(license).length === 0 || !license.isFreeTrial) {
        setInstructions(fetchedInstructions)
      } else {
        setInstructions([
          ...fetchedInstructions.filter(article =>
            [44001761071, 44001858432, 44001945719].includes(article.id)
          ),
          { title: 'Additional deployment methods', id: 'additional' },
        ])
      }
      setHaveExpressInstaller(fetchedInstructions[0]?.id === "express-installer")
    }
  }, [fetchedInstructions, license])

  return (
    <Paper>
      <Toolbar>
        <Typography variant="h6">Sidecar Deployment Instructions</Typography>
        <div className={classes.grow} />
        <Button variant="outlined" color="primary" component={Link} to="/sidecars">
          <ChevronLeftIcon />
          Back
        </Button>
      </Toolbar>

      <Grid container spacing={24}>
        <Grid item xs={3}>
          <Typography
            variant="caption"
            color="textSecondary"
            gutterBottom
            className={classes.platform}
          >
            Platform
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="caption" color="textSecondary" gutterBottom>
            Deployment steps
          </Typography>
        </Grid>
      </Grid>

      <Divider />

      <Grid container spacing={24}>
        <Grid item xs={3}>
          <VerticalTabs value={activeIndex} onChange={handleChange} className={classes.tabs}>
            {renderTabs()}
          </VerticalTabs>
        </Grid>
        <Grid item xs={9}>
          <div className={classes.markdown}>{renderContent()}</div>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default withStyles(styles)(WrapperInstructions)
