/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useState, useEffect, Fragment } from 'react'
import { Formik } from 'formik'

import useInstructions from '@jeeves/pages/Wrappers/hooks/useInstructions'
import {
  DownloadLink,
  SidecarDialog,
  SidecarGenerateActions,
  SidecarNameField,
} from '../index'

import {
  validateSidecarName,
} from '../Validation'

import { Typography, Link } from '@material-ui/core'
import { IconCopyButton } from '@jeeves/components/CopyButton'

import useWrappers from '@jeeves/pages/Wrappers/hooks/useWrappers'
import { useAuth } from '@jeeves/hooks'
import ExpressClient from '@jeeves/clients/express'
import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'
import { useAccounts } from '@jeeves/pages/WrapperDetail/components/Accounts/useAccounts'
import Loading from '@jeeves/components/Loading'
import { getDocsURL } from '@jeeves/utils/docsURL'

export const CopyValue = ({ label, value, flex }) => (
  <Fragment>
    {label ? (
      <Typography
        css={t => css`
          min-width: 80px;
          font-family: ${t.fontFamily};
          font-weight: bold;
          align-self: center;
          justify-self: stretch;
        `}
      >
        {label}
      </Typography>
    ) : (
      <div></div>
    )}

    <pre
      css={t => css`
        flex-grow: ${flex ? '1' : '0'};
        font-size: ${t.fontSize[3]};
        font-family: ${t.typography.monospaced};
        background-color: #efefef;
        padding: 8px;
        border-radius: 4px;
        margin: 0;
        overflow-x: auto;
        align-self: center;
        justify-self: stretch;
      `}
    >
      {value}
    </pre>
    <IconCopyButton css={{ marginLeft: '12px', alignSelf: 'center' }} copyText={value} />
  </Fragment>
)

const validateForm = values => {
  const errors = {
    sidecarName: validateSidecarName(values.sidecarName),
  }

  return Object.fromEntries(Object.entries(errors).filter(([_, value]) => !!value))
}

export const ExpressInstallerGenContents = ({
  newTemplate,
  alreadyCreated = false,
  saveProperties,
  onSave,
  clone,
  setCloned,
  setFinished,
  open,
  onClose,
  onGenerated,
  sidecarName: argsSidecarName,
  sidecarId: argsSidecarId,
  repos,
  suggestedSidecarName,
  setIsGenerated: argsSetIsGenerated = () => {},
}) => {
  const [generateEnabled, setGenerateEnabled] = useState(true)
  const [isGenerated, setIsGenerated] = useState(alreadyCreated && !clone)
  const { wrapperHandlers, repoHandlers } = useWrappers()
  const [sidecarNames, setSidecarNames] = useState([])
  const { user, getTokenSilently } = useAuth()
  const ec = new ExpressClient(getTokenSilently)
  const { showError } = usePopup()
  const { createSidecarAccount } = useAccounts()
  const [variables, setVariables] = useState('')

  const { handleSetPopup, popupTypes, generateId, createSidecar, getGCRKey, cloneSidecar } = useInstructions()
  const [deploymentCommand, setDeploymentCommand] = useState('')

  const handleOnClose = () => {
    onClose()
    if (isGenerated && clone && setCloned) setCloned(true)
    // Save everytime because client id, secret is being generated
    if (isGenerated && onSave) onSave()
    setIsGenerated(false)
  }

  const submit = async ({ sidecarName }) => {
    let sidecar = {}
    setGenerateEnabled(false)

    const sidecarProperties = {
      deploymentMethod: 'express-installer',
    }

    try {
      if (alreadyCreated) {
        if (clone) {
          const newSidecarResponse = await cloneSidecar({
            sidecarId: argsSidecarId,
            input: {
              name: sidecarName,
              ...sidecarProperties
            }
          })
          sidecar.ID = newSidecarResponse.sidecar.id
          sidecar.clientID = newSidecarResponse.sidecarAccount.clientId
          sidecar.clientSecret = newSidecarResponse.sidecarAccount.clientSecret
        } else {
          sidecar.ID = argsSidecarId
          const sidecarAccount = await createSidecarAccount(sidecar.ID)
          sidecar.clientID = sidecarAccount.clientId
          sidecar.clientSecret = sidecarAccount.clientSecret
        }
        if (saveProperties) {
          await ec.put(`/sidecars/${argsSidecarId}`, {
            properties: sidecarProperties,
          })
        }
      } else {
        sidecar = await createSidecar(sidecarName, sidecarProperties)
      }

      const gcrKey = await getGCRKey()
      const command = `controlPlaneUrl=${window._env_.domain} \
sidecarVersion=${window._env_.template_git_reference} \
sidecarId=${sidecar.ID} \
clientId="${sidecar.clientID}" \
clientSecret="${sidecar.clientSecret}" \
registryKey="${gcrKey}" \
bash -c "$(curl -fsSL \
https://raw.githubusercontent.com/cyral-quickstart/quickstart-sidecar-express/main/install-sidecar.sh)"`
      
      setDeploymentCommand(`${command}`)

      if (setFinished) setFinished(true)
      setIsGenerated(true)
    } catch (e) {
      let popupMessage = e.message
      if (e.error && e.error.trim() === 'login_required') {
        popupMessage = 'Third party cookies must be enabled'
      }
      handleSetPopup(popupTypes.ERROR, popupMessage)
    }
    setGenerateEnabled(true)
  }

  useEffect(() => {
    if (!open) handleOnClose()
  }, [open])

  useEffect(() => {
    if (alreadyCreated && !clone) {
      submit({})
    }
  }, [alreadyCreated, clone])

  useEffect(() => {
    argsSetIsGenerated(isGenerated)
  }, [isGenerated, argsSetIsGenerated])

  return (
    <div>
      <Formik
        initialValues={{
          sidecarName: suggestedSidecarName || (alreadyCreated ? argsSidecarName : ''),
        }}
        validate={(!alreadyCreated || (alreadyCreated && clone)) && validateForm}
        onSubmit={submit}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            {(!alreadyCreated || (alreadyCreated && clone)) && (
              <SidecarNameField disabled={isGenerated} />
            )}

        {isGenerated && ((deploymentCommand) ? (
          <div>
            <div>
              <DownloadLink style='max-width:none' caption="Copy the install command and run on a Linux machine" downloadLink={deploymentCommand} />
            </div>
          </div>
        ) : 
        <div css={{ position: 'relative', minHeight: '56px' }}>
          <Loading />
        </div>)}

            <SidecarGenerateActions
              disabled={!generateEnabled}
              isGenerated={isGenerated}
              handleOnClose={handleOnClose}
              isSubmitting={isSubmitting}
              buttonMessage="Create"
            />
          </form>
        )}
      </Formik>
    </div>
  )
}

const ExpressInstallerGen = ({ currentDialog, setCurrentDialog, ...props }) => {
  const [open, setOpen] = useState(currentDialog === 'express-installer')
  const [isGenerated, setIsGenerated] = useState(false)

  useEffect(() => {
    setOpen(currentDialog === 'express-installer')
  }, [currentDialog])

  const handleClose = () => {
    setCurrentDialog('')
  }

  return (
    <SidecarDialog open={open} onClose={handleClose} title={isGenerated ? "Installation command" : "Create sidecar"} maxWidth={isGenerated ? 'md' : 'sm'}>
      <ExpressInstallerGenContents open={open} onClose={handleClose} setIsGenerated={setIsGenerated} {...props}></ExpressInstallerGenContents>
    </SidecarDialog>
  )
}

export default ExpressInstallerGen
