/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import CircularProgress from '@material-ui/core/CircularProgress'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'

import { Offline, Unknown, Warning, Online } from './Icons'

const Status = ({ status, isInstance }) => {
  const getStatus = () => {
    switch (status) {
      case 'deploying':
        return <CircularProgress variant="indeterminate" size="1em" thickness={5} />
      case 'failed':
        return <WarningRoundedIcon color="error" fontSize="small" />
      case 'online':
        return <Online isInstance={isInstance} />
      case 'unknown':
        return <Unknown isInstance={isInstance} />
      case 'warning':
        return <Warning isInstance={isInstance} />
      case 'offline':
        return <Offline isInstance={isInstance} />
      default:
        return <Unknown isInstance={isInstance} />
    }
  }

  return (
    <div
      css={() => css`
        display: flex;
        align-items: center;
      `}
    >
      {getStatus()}
    </div>
  )
}

export default Status
