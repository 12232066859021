import React from 'react'
import { NavLink } from 'react-router-dom'
import { MenuList } from '@material-ui/core'
import PeopleIcon from '@material-ui/icons/People'
import CodeIcon from '@material-ui/icons/Code'
import FavoriteIcon from '@material-ui/icons/Favorite'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

import { useAppConfig } from '@jeeves/hooks'
import { useAuth } from '../Auth'
import { MenuItem } from './components/MenuItem'

import { getDocsURL } from '@jeeves/utils/docsURL'

const SixthSection = ({ iconOnly }) => {
  const { hasPermission } = useAuth()
  const { config } = useAppConfig()

  return (
    <MenuList>
      <Stack spacing="16px">
        <Box>
          <MenuItem
            component={NavLink}
            to="/account/users"
            disabled={!hasPermission('users:read')}
            iconOnly={iconOnly}
            primaryText="Cyral Users"
            listItemIcon={<PeopleIcon fontSize="small" />}
          />

          <MenuItem
            component={NavLink}
            to="/serviceAccounts"
            iconOnly={iconOnly}
            primaryText="Cyral API Keys"
            listItemIcon={<VpnKeyIcon fontSize="small" />}
          />

          <MenuItem
            component="a"
            href={getDocsURL({ docsPath: 'api-ref' })}
            target="_blank"
            rel="noopener"
            iconOnly={iconOnly}
            primaryText="Cyral API Docs"
            listItemIcon={<CodeIcon fontSize="small" />}
          />
        </Box>

        {config && config.helpType === 'support' && (
          <MenuItem
            component={NavLink}
            to="/help"
            disabled={!hasPermission('support:read')}
            iconOnly={iconOnly}
            primaryText="Help &amp; Support"
            listItemIcon={<FavoriteIcon fontSize="small" />}
          />
        )}
      </Stack>
    </MenuList>
  )
}

export default SixthSection
