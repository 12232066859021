import { useState, useEffect } from 'react'
import { useQuery, gql } from '@apollo/client'
import ExpressClient from '@jeeves/clients/express'
import { useAuth } from '@jeeves/components/Auth'
import lodash from 'lodash'

const SANDBOX = gql`
  query SandboxRepo($filters: ReposWithUtilitiesFilters, $first: Int, $sessionId: String!) {
    reposWithUtilities(filters: $filters, first: $first) {
      edges {
        cursor
        node {
          id
          boundSidecarsRelationship {
            edges {
              node {
                id
                endpoint
                userEndpoint
                labels
              }
              bindings {
                id
                ... on SingleListenerBinding {
                  listener {
                    id
                    port
                  }
                }
              }
            }
          }
          ... on PostgreSQLRepo {
            endpoint {
              host
              port
            }
            userAccounts {
              id
              name
              credentials {
                ... on CyralStorageUserAccountCredentials {
                  password
                }
              }
            }
          }
        }
      }
    }
    opaqueToken(sessionId: $sessionId)
  }
`

const DEFAULT_SANDBOX_PLACEHOLDERS = {
  sandboxRepoHost: '$REPO_HOST',
  sandboxRepoPort: '$REPO_PORT',
  sandboxSidecarHost: '$SIDECAR_HOST',
  sandboxSidecarPort: '$SIDECAR_PORT',
  nativeRepoUser: '$REPO_USER',
  nativeRepoPass: '$REPO_PASSWORD',
  idpUser: '$SSO_USER',
  ssoAuthToken: '$AUTH_TOKEN',
}

const SANDBOX_TAG = '_cyral-sandbox'

const useSandbox = () => {
  const { getTokenSilently, user } = useAuth()
  const [sandboxContent, setSandboxContent] = useState([])
  const { data, loading } = useQuery(SANDBOX, {
    variables: {
      sessionId: user.session_state,
      filters: {
        repoTags: [SANDBOX_TAG],
      },
      first: 1,
    },
  })

  const sandboxRepo = data?.reposWithUtilities?.edges?.[0]?.node
  const userAccount = sandboxRepo?.userAccounts?.find(
    userAccount => userAccount?.credentials?.__typename === 'CyralStorageUserAccountCredentials'
  )
  const sandboxSidecarEdge = sandboxRepo?.boundSidecarsRelationship?.edges?.find(sidecarEdge =>
    sidecarEdge?.node?.labels?.includes(SANDBOX_TAG)
  )

  useEffect(() => {
    const fetchSandboxContent = async () => {
      try {
        const ec = new ExpressClient(getTokenSilently)
        const resp = await ec
          .get('/templates/_sandboxContent')
          .then(res => res.data)
          .then(data => data.steps)
        // console.log('sandboxContent: ', resp)
        setSandboxContent(resp)
        return resp
      } catch (e) {
        console.error(e)
      }
    }
    fetchSandboxContent()
  }, [])

  return {
    placeHolders: lodash.merge(DEFAULT_SANDBOX_PLACEHOLDERS, {
      sandboxRepoHost: sandboxRepo?.endpoint.host,
      sandboxRepoPort: sandboxRepo?.endpoint.port,
      sandboxSidecarHost:
        sandboxSidecarEdge?.node?.userEndpoint || sandboxSidecarEdge?.node?.endpoint,
      sandboxSidecarPort: sandboxSidecarEdge?.bindings?.[0]?.listener?.port,
      nativeRepoUser: userAccount?.name ?? '',
      nativeRepoPass: userAccount?.credentials?.password ?? '',
      idpUser: user.email,
      ssoAuthToken: data?.opaqueToken,
    }),
    sandboxContent,
  }
}

export default useSandbox
