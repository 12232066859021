/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { Tooltip, Typography } from '@material-ui/core'
import { useAuth } from '@jeeves/components/Auth'
import { Paper, Tab, Tabs } from '@jeeves/components/Primitives'
import { CopyViewTextField } from '@jeeves/pages/Profile/components/JWT'
import Loading from '@jeeves/components/Loading'

const CopyAccessToken = ({ paperStyles, showLoadingState, opaqueToken }) => {
  if (!opaqueToken && showLoadingState) return <Loading />

  return (
    <Paper
      css={{
        padding: '24px',
        position: 'relative',
        minHeight: '100px',
        marginBottom: '24px',
        ...paperStyles,
      }}
    >
      <Typography variant="h6">Access Token</Typography>
      <div css={{ display: 'flex', alignItems: 'center', marginBottom: '24px' }}>
        <Typography>
          Use this access token as your password when you authenticate to Cyral-secured data
          repositories with SSO.
          <strong> This access token is short-lived and will rotate periodically.</strong>
        </Typography>
        {/* <div css={{display: 'inline-block'}}>
                <InfoSnackbar text="This access token is short-lived and will rotate periodically."></InfoSnackbar>
              </div> */}
      </div>
      <CopyViewTextField value={opaqueToken} fullWidth />
    </Paper>
  )
}

export default CopyAccessToken
