import React from 'react'
import { css } from '@emotion/react'
import { TableRow, withStyles } from '@material-ui/core'

import { TableCell } from '@jeeves/components/Table'
import Status from '@jeeves/pages/Wrappers/components/Status'
import { EllipsisTypography } from '@jeeves/components/Primitives'
import HealthStatus from './HealthStatus'
import humanizeDuration from 'humanize-duration'
const HD_OPTIONS = {
  round: true,
  largest: 1,
}

const styles = () => ({
  status: {
    width: 24,
    paddingRight: 0,
  },
})

const Row = ({ sidecarInstance, classes }) => {
  const startTimestampToAge = start_timestamp =>
    humanizeDuration(Date.now() - start_timestamp / 1000000, HD_OPTIONS)

  return (
    <>
      <TableRow hover>
        <TableCell preserveHeight align="left" className={classes.status}>
          <Status status={sidecarInstance.health} isInstance />
        </TableCell>
        <TableCell preserveHeight>{sidecarInstance.asg_instance}</TableCell>
        <TableCell preserveHeight>
          <EllipsisTypography width="120px">
            {sidecarInstance.sidecar_version || '-'}
          </EllipsisTypography>
        </TableCell>
        <TableCell preserveHeight>
          {sidecarInstance.start_timestamp
            ? startTimestampToAge(sidecarInstance.start_timestamp)
            : '-'}
        </TableCell>
        <TableCell preserveHeight textAlign="right">
          {sidecarInstance.queriesPerSecond ? sidecarInstance.queriesPerSecond.toFixed(3) : 0}
        </TableCell>
        <TableCell preserveHeight textAlign="right">
          {sidecarInstance.activeConnectionCount || 0}
        </TableCell>
      </TableRow>
    </>
  )
}

export default withStyles(styles)(Row)
