import React from 'react'

import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { IconCopyButton, Input } from '@jeeves/new-components'

const AccessToken = ({ opaqueToken, showAccessToken, handleToggle }) => {
  const handleFocus = event => event.target.select()

  return (
    <Stack spacing={4}>
      <Stack spacing={1}>
        <Typography variant="h4" sx={{ color: 'primary.main' }}>
          Access Token
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          Use this access token as your password when you authenticate to Cyral-secured data
          repositories. This access token is short-lived and will rotate periodically.
        </Typography>
      </Stack>
      <Input
        defaultValue={opaqueToken}
        type={showAccessToken ? 'text' : 'password'}
        onFocus={handleFocus}
        endAdornment={
          <InputAdornment position="end" sx={{ marginLeft: 0 }}>
            <IconButton
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent',

                  '& .MuiSvgIcon-root': {
                    transition: theme =>
                      theme.transitions.create(['color'], {
                        duration: theme.transitions.duration.shortest,
                      }),

                    color: 'cyralColors.grey.400',
                  },
                },
              }}
              disableRipple
              disableFocusRipple
              //   aria-label="toggle password visibility"
              onClick={handleToggle}
              //   onMouseDown={handleMouseDownPassword}
              //   edge="end"
            >
              {showAccessToken ? (
                <VisibilityOffOutlinedIcon
                  fontSize="small"
                  sx={{ color: 'cyralColors.grey.300' }}
                />
              ) : (
                <VisibilityOutlinedIcon fontSize="small" sx={{ color: 'cyralColors.grey.300' }} />
              )}
            </IconButton>
            <IconCopyButton text={opaqueToken} />
          </InputAdornment>
        }
        readOnly
      />
    </Stack>
  )
}

export default AccessToken
