import Crypto from 'crypto'
import { Typography, Stack } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'

const TAG_COLORS = ['green', 'yellow', 'red', 'grey', 'orange', 'purple', 'pink', 'primary']

const getHashValue = tag => {
  return Crypto.createHash('md5').update(tag.toLowerCase()).digest('hex').slice('-4')
}

const getTagColor = tag => {
  const HEX_BASE = 16
  const intValue = parseInt(getHashValue(tag), HEX_BASE)
  const index = intValue % TAG_COLORS.length

  return TAG_COLORS[index]
}

const Tag = ({ text, color, onRemove }) => {
  const tagColor = color ?? getTagColor(String(text))

  let removeIcon = null
  if (onRemove) {
    removeIcon = <ClearIcon fontSize="inherit" onClick={onRemove} />
  }

  return (
    <Stack
      spacing={1}
      direction="row"
      sx={{
        backgroundColor: theme => theme.palette.cyralColors?.[tagColor]?.[100],
        color: theme => theme.palette.cyralColors?.[tagColor]?.[500],
        padding: theme => theme.spacing(0.25, 1),
        borderRadius: theme => theme.radii.sm,
        alignItems: 'center',
      }}
    >
      <Typography variant="body2" component="span">
        {text}
      </Typography>
      {removeIcon}
    </Stack>
  )
}

export default Tag
