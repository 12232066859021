/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Fragment } from 'react'
import { useState, useEffect } from 'react'
import { withStyles, Typography, Toolbar } from '@material-ui/core'

import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'
import { useAuth } from '@jeeves/components/Auth'
import ExpressClient from '@jeeves/clients/express'
import Loading from '@jeeves/components/Loading'

import { red, green, grey } from '@material-ui/core/colors'

import { PlainLayout } from '@jeeves/components/Dashboard'
import { Paper } from '@jeeves/components/Primitives'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'

import crypto from 'crypto'

const styles = theme => ({
  command: {
    margin: 0,
    padding: '8px',
    fontFamily: '"Monospaced", monospace',
    backgroundColor: grey[200],
    border: `1px solid ${grey[400]}`,
    borderRadius: '4px',
  },
  summary: {
    padding: '0px 30px',
    color: grey[600],
  },
  commandContainer: {
    padding: '20px 30px',
  },
  paper: {
    paddingBottom: '25px',
  },
})

const CLIHome = props => {
  const { setPopup, popupTypes } = usePopup()
  const { user, getTokenSilently } = useAuth()
  const ec = new ExpressClient(getTokenSilently)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  const { classes } = props

  const publicKey = props.match.params.publicKey
  let buff = Buffer.from(publicKey, 'base64')
  const asciiPublicKey = buff.toString('ascii')

  const putOpaqueToken = async (publicKey, encryptedToken, user) => {
    try {
      await ec.request({
        method: 'PUT',
        url: `/opaqueToken`,
        data: { PublicKey: publicKey, EncryptedAccessToken: encryptedToken, User: user },
      })
    } catch (e) {
      throw e
    }
  }

  const encryptToken = token => {
    const encryptBuffer = Buffer.from(token)
    const encrypted = crypto.publicEncrypt(asciiPublicKey, encryptBuffer)
    let encrypted_b64 = encrypted.toString('base64')
    return encrypted_b64
  }

  const sendToken = async () => {
    try {
      const token = await getTokenSilently({ audience: [] })
      let encryptedToken
      try {
        encryptedToken = encryptToken(token)
      } catch (e) {
        throw 'Sorry, there was a problem fetching your token. Please check the format of your public key.'
      }
      try {
        await putOpaqueToken(publicKey, encryptedToken, user)
      } catch (e) {
        if (e && e.error && e.error === 'login_required') {
          throw 'Sorry, there was a problem fetching your token. Please make sure third-party cookies are enabled.'
        } else {
          throw 'Sorry, there was a problem fetching your token. Please try again.'
        }
      }
      setIsLoading(false)
    } catch (e) {
      setError(e)
      setIsLoading(false)
      console.error(e)
      // setPopup(popupTypes.ERROR, popupMessage)
    }
  }

  useEffect(() => {
    sendToken()
  }, [])

  return (
    <PlainLayout>
      <div css={{ position: 'relative', display: 'flex', alignItems: 'center', height: '100%' }}>
        <Paper
          css={{
            position: 'relative',
            margin: 'auto',
            height: '128px',
            minWidth: isLoading ? '480px' : error ? '660px' : '760px',
            padding: '36px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'all 0.5s ease-out',
          }}
        >
          {isLoading && (
            <Fragment>
              <div css={{ position: 'relative', height: '56px', width: '56px' }}>
                <Loading></Loading>
              </div>
              <Typography css={{ marginLeft: '12px', fontSize: '1.5rem' }}>
                Your token is being generated.
              </Typography>
            </Fragment>
          )}
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              left: '50%',
              opacity: isLoading ? 0 : 1,
              transition: 'opacity 1s ease-out',
              minWidth: error ? '600px' : '700px',
            }}
          >
            {error ? (
              <ErrorIcon fontSize="large" style={{ color: red[500] }}></ErrorIcon>
            ) : (
              <CheckCircleIcon fontSize="large" style={{ color: green[500] }}></CheckCircleIcon>
            )}
            <Typography css={{ marginLeft: '24px', fontSize: '1.5rem' }}>
              {error || 'Your token has been fetched. You can close this page.'}
            </Typography>
          </div>
        </Paper>
      </div>
    </PlainLayout>
  )
}

export default withStyles(styles)(CLIHome)
