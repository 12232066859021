import { useState } from 'react'
import { Box, Table, TableHead, TableBody } from '@mui/material'

import { graphql, FragmentType, useFragment } from '@jeeves/graphql'
import { TableContainer } from '@jeeves/new-components'
import { InstancesRow, UpgradeInstanceModal } from '../'

const InstancesTable_SidecarFragment = graphql(`
  fragment InstancesTable_SidecarFragment on Sidecar {
    instances {
      id
      ...InstancesRow_SidecarInstanceFragment
    }
    ...InstancesRow_SidecarFragment
    ...UpgradeInstanceModal_SidecarFragment
  }
`)

interface Props {
  sidecar: FragmentType<typeof InstancesTable_SidecarFragment>
}

const InstancesTable = ({ sidecar: sidecarProp }: Props) => {
  const [selectedInstanceToUpgrade, setSelectedInstanceToUpgrade] = useState('')

  const sidecar = useFragment(InstancesTable_SidecarFragment, sidecarProp)

  return (
    <TableContainer
      sx={{
        'tr:last-child': {
          '& td': {
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderColor: 'cyralColors.grey.200',
          },
        },
      }}
    >
      <Table>
        <TableHead>
          <Box component="tr">
            <Box component="th" scope="col"></Box>
            <Box component="th" scope="col" sx={{ whiteSpace: 'nowrap' }}>
              Instance ID
            </Box>
            <Box component="th" scope="col">
              Version
            </Box>
            <Box component="th" scope="col">
              Age
            </Box>
            <Box component="th" scope="col">
              Throughput (QPS)
            </Box>
            <Box component="th" scope="col">
              Active Connections
            </Box>
            <Box component="th" scope="col"></Box>
          </Box>
        </TableHead>
        <TableBody>
          {sidecar.instances.map(instance => (
            <InstancesRow
              key={instance.id}
              sidecarInstance={instance}
              sidecar={sidecar}
              setSelectedInstanceToUpgrade={setSelectedInstanceToUpgrade}
            />
          ))}
        </TableBody>
      </Table>
      <UpgradeInstanceModal
        sidecar={sidecar}
        selectedInstanceToUpgrade={selectedInstanceToUpgrade}
        setSelectedInstanceToUpgrade={setSelectedInstanceToUpgrade}
      />
    </TableContainer>
  )
}

export default InstancesTable
