/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useState, useEffect } from 'react'
import { withStyles, Grid, TextField, Switch, FormControlLabel } from '@material-ui/core'

import { Button, FlexGrow } from '@jeeves/components/Primitives'
import { ButtonLoading } from '@jeeves/components/Loading'
import { LogsIntegration, MetricsIntegration } from '../IntegrationInputs'
import useInstructions from '../../../hooks/useInstructions'
import { SidecarDialog } from '../index'
import useWrappers from '@jeeves/pages/Wrappers/hooks/useWrappers'
import { useAuth } from '@jeeves/hooks'
import ExpressClient from '@jeeves/clients/express'
import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'
import { useAccounts } from '@jeeves/pages/WrapperDetail/components/Accounts/useAccounts'

import { get as lodashGet } from 'lodash'

const styles = theme => ({
  title: {
    padding: theme.spacing.unit * 3,
  },
  deleteIcon: {
    fontSize: 28,
  },
  grow: {
    flexGrow: 1,
  },
  DialogTitle: {
    padding: theme.spacing.unit * 3,
  },
  DialogContent: {
    padding: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 5,
    paddingTop: 0,
  },
  DialogActions: {
    paddingBottom: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingTop: 0,
    backgroundColor: theme.palette.background.primary,
  },
  mt3: {
    marginTop: theme.spacing.unit * 3,
  },
  typography: {
    marginBottom: theme.spacing.unit,
  },
  vpcSwitch: {
    marginTop: theme.spacing.unit * 2,
  },
})

export const CloudFormGenContents = ({
  newTemplate,
  saveProperties,
  onSave,
  alreadyCreated,
  clone,
  setCloned,
  setFinished,
  open,
  onClose,
  onGenerated,
  logsOptions,
  metricsOptions,
  sidecarName: argsSidecarName,
  keyName: argsKeyName,
  vpc: argsVpc,
  logIntegrationID: argsLogIntegrationID,
  metricsIntegrationID: argsMetricsIntegrationID,
  publiclyAccessible: argsPubliclyAccessible,
  sidecarId: argsSidecarId,
  repos,
  suggestedSidecarName,
}) => {
  const [generateEnabled, setGenerateEnabled] = useState(true)
  const [keyName, setKeyName] = useState(argsKeyName || '')
  const [vpc, setVpc] = useState(argsVpc || '')
  const [sidecarName, setSidecarName] = useState(
    suggestedSidecarName || (alreadyCreated ? argsSidecarName : '')
  )
  const [publicSubnets, setPublicSubnets] = useState('')
  const [elkAddress, setElkAddress] = useState('')
  const [createVPC, setCreateVPC] = useState(true) // eslint-disable-line
  // TO DO: add ID to these variable names
  const [logIntegration, setLogIntegration] = useState(
    argsLogIntegrationID || (alreadyCreated && !newTemplate ? 'cloudwatch' : '')
  )
  const [metricsIntegration, setMetricsIntegration] = useState(
    argsMetricsIntegrationID || (alreadyCreated && !newTemplate ? 'default' : '')
  )
  const [publiclyAccessible, setPubliclyAccessible] = useState(argsPubliclyAccessible === 'true')
  const [generating, setGenerating] = useState(false)
  const { wrapperHandlers, repoHandlers } = useWrappers()
  const [sidecarNames, setSidecarNames] = useState([])
  const { user, getTokenSilently } = useAuth()
  const ec = new ExpressClient(getTokenSilently)
  const { showError } = usePopup()
  const { createSidecarAccount } = useAccounts()

  const { handleSetPopup, popupTypes, generateId, createSidecar, cloneSidecar } = useInstructions()

  const handleOnClose = () => {
    onClose()
    clearFields()
  }

  const clearFields = () => {
    setKeyName('')
    setVpc('')
    setSidecarName('')
    setPublicSubnets('')
    setElkAddress('')
    setLogIntegration('')
    setMetricsIntegration('')
    setPubliclyAccessible(false)
  }

  const downloadFile = content => {
    const element = document.createElement('a')
    // eslint-disable-next-line
    const file = new Blob([content], { type: 'application/x-yaml' })
    element.href = URL.createObjectURL(file)
    element.download = 'cft.yaml'
    document.body.appendChild(element)
    element.click()
  }

  const sidecarProperties = {
    deploymentMethod: 'cft-ec2',
    keyName,
    vpc,
    logIntegrationID: logIntegration,
    metricsIntegrationID: metricsIntegration,
    publiclyAccessible: publiclyAccessible ? 'true' : undefined,
  }

  const handleOnSubmit = async () => {
    const publicSubnetsArr = publicSubnets ? publicSubnets.replace(/\s/g, '').split(',') : null
    const replaceTerm = window.location.origin.includes('https') ? 'https://' : 'http://'
    const controlPlane = window.location.origin.replace(replaceTerm, '')
    const vpcValue = createVPC ? '' : vpc
    const publicSubnetsValue = createVPC ? '' : publicSubnetsArr
    let sidecar = {}

    setGenerateEnabled(false)
    setGenerating(true)

    try {
      if (alreadyCreated) {
        if (clone) {
          const {
            deploymentMethod,
            logIntegration,
            metricsIntegrationID,
            ...restSidecarProperties
          } = sidecarProperties
          const newSidecarResponse = await cloneSidecar({
            sidecarId: argsSidecarId,
            input: {
              name: sidecarName,
              deploymentMethod,
              logIntegration,
              metricsIntegrationID,
              additionalProperties: restSidecarProperties,
            },
          })
          sidecar.ID = newSidecarResponse.sidecar.id
          sidecar.clientID = newSidecarResponse.sidecarAccount.clientId
          sidecar.clientSecret = newSidecarResponse.sidecarAccount.clientSecret
        } else {
          sidecar.ID = argsSidecarId
          const sidecarAccount = await createSidecarAccount(sidecar.ID)
          sidecar.clientID = sidecarAccount.clientId
          sidecar.clientSecret = sidecarAccount.clientSecret
        }
        if (saveProperties) {
          await ec.put(`/sidecars/${argsSidecarId}`, {
            properties: sidecarProperties,
          })
        }
      } else {
        sidecar = await createSidecar(sidecarName, sidecarProperties)
      }
      // TO DO: correct name of state variable can make this variable name better
      const logIntegrationObject = logsOptions.find(logsOption => logsOption.id === logIntegration)
      const metricsIntegrationObject = metricsOptions.find(
        metricsOption => metricsOption.id === metricsIntegration
      )
      const cft = await ec
        .request({
          method: 'GET',
          timeout: 60000,
          url: `/deploy/cft/`,
          params: {
            SidecarId: sidecar.ID,
            KeyName: keyName,
            VPC: vpcValue,
            SidecarName: sidecarName,
            ControlPlane: controlPlane,
            PublicSubnets: publicSubnetsValue,
            ELKAddress: elkAddress,
            ELKUsername: logIntegrationObject.elkUsername,
            ELKPassword: logIntegrationObject.elkPassword,
            publiclyAccessible: publiclyAccessible,
            logIntegrationType: logIntegrationObject.type,
            logIntegrationValue: logIntegrationObject.value,
            metricsIntegrationType: metricsIntegrationObject.type,
            metricsIntegrationValue: metricsIntegrationObject.value,
            useTLS: logIntegrationObject.useTLS,
            useMutualAuthentication: logIntegrationObject.useMutualAuthentication,
            usePrivateCertificateChain: logIntegrationObject.usePrivateCertificateChain,
            SumologicHost: logIntegrationObject.host,
            SumologicUri: logIntegrationObject.uri,
            clientId: sidecar.clientID,
            clientSecret: sidecar.clientSecret,
            // clientId: 'testClientId',
            // clientSecret: 'testClientSecret',
          },
        })
        .then(res => res.data)
      await downloadFile(cft)
      handleOnClose()
      if (clone && setCloned) setCloned(true)
      if (setFinished) setFinished(true)
      // Save everytime because client id, secret is being generated
      if (onSave) onSave()
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', null)
      handleSetPopup(popupTypes.ERROR, popupMessage)
    } finally {
      setGenerating(false)
    }

    setGenerateEnabled(true)
  }

  useEffect(() => {
    if (!open) handleOnClose()
  }, [open])

  return (
    <div>
      <TextField
        fullWidth
        name="KeyName"
        value={keyName}
        label="AWS key pair name"
        onChange={e => setKeyName(e.target.value)}
      />

      {!createVPC && (
        <TextField
          fullWidth
          name="Vpc"
          value={vpc}
          label="VPC ID"
          onChange={e => setVpc(e.target.value)}
        />
      )}

      {(!alreadyCreated || (alreadyCreated && clone)) && (
        <TextField
          fullWidth
          name="SidecarName"
          value={sidecarName}
          label="Sidecar name"
          onChange={e => setSidecarName(e.target.value)}
        />
      )}

      {!createVPC && (
        <TextField
          fullWidth
          name="PublicSubnets"
          value={publicSubnets}
          label="Public subnet IDs"
          onChange={e => setPublicSubnets(e.target.value)}
        />
      )}

      <LogsIntegration
        logIntegration={logIntegration}
        setLogIntegration={setLogIntegration}
        logsOptions={logsOptions}
      />

      <MetricsIntegration
        metricsIntegration={metricsIntegration}
        setMetricsIntegration={setMetricsIntegration}
        metricsOptions={metricsOptions}
      />

      <Grid
        container
        css={{ justifyContent: 'space-between', alignItems: 'center', marginTop: '32px' }}
      >
        <FormControlLabel
          label="Publicly accessible"
          control={
            <Switch
              id="publicly_accessible"
              checked={publiclyAccessible}
              value={publiclyAccessible}
              onChange={e => setPubliclyAccessible(!publiclyAccessible)}
              color="primary"
            />
          }
        />
        <FlexGrow />
        <Button
          variant="outlined"
          color="primary"
          onClick={handleOnClose}
          css={{ marginRight: '16px' }}
          disabled={generating}
        >
          Cancel
        </Button>
        <div css={{ position: 'relative' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOnSubmit}
            disabled={!generateEnabled || generating}
          >
            Generate
          </Button>
          {generating && <ButtonLoading />}
        </div>
      </Grid>
    </div>
  )
}

const CloudFormGen = ({ currentDialog, setCurrentDialog, ...props }) => {
  const [open, setOpen] = useState(currentDialog === 'cft-ec2')

  useEffect(() => {
    setOpen(currentDialog === 'cft-ec2')
  }, [currentDialog])

  const handleClose = () => {
    setCurrentDialog('')
  }

  return (
    <SidecarDialog
      open={open}
      onClose={handleClose}
      title="Generate a file"
      subtitle="Add integrations to use them below"
    >
      <CloudFormGenContents open={open} onClose={handleClose} {...props}></CloudFormGenContents>
    </SidecarDialog>
  )
}

export default withStyles(styles)(CloudFormGen)
