import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { useAuth } from '@jeeves/components/Auth'
import ExpressClient from '@jeeves/clients/express'
import { useToast } from '@jeeves/new-components'

export const useOperationalMode = ({ sidecar, onSave }) => {
  const sidecarId = sidecar.id
  const { toast } = useToast()

  const { getTokenSilently } = useAuth()
  const ec = new ExpressClient(getTokenSilently)
  const hasCustomCertificateBundleSecrets = Boolean(sidecar?.certificateBundleSecrets?.sidecar)

  const methods = useForm({
    defaultValues: {
      passthrough: sidecar.services?.dispatcher?.bypass === 'always',
      failover: ['failover', 'always'].includes(sidecar.services?.dispatcher?.bypass),
    },
  })

  const {
    setError,
    formState: { isSubmitSuccessful },
    getValues,
    reset,
  } = methods

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getValues())
    }
  }, [getValues, isSubmitSuccessful, reset])

  const updateSidecarBypass = async ({ passthrough, failover }) => {
    const endpoint = `sidecars/${sidecarId}`
    const bypass = passthrough ? 'always' : failover ? 'failover' : 'never'
    await ec.put(endpoint, { bypass })
  }

  const onSubmit = async formData => {
    try {
      const { passthrough, failover } = formData
      await updateSidecarBypass({ passthrough, failover })

      await onSave()
    } catch (error) {
      const description =
        error.response?.data?.error?.message || 'An error occurred while saving, please try again.'
      toast({ description, variant: 'error' })
      setError('root.submissionError', {
        message: description,
      })
    }
  }

  return {
    hasCustomCertificateBundleSecrets,
    methods,
    onSubmit,
  }
}
