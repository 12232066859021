import { SIDECAR_REGEX } from '@jeeves/utils/constants'

export const validateSidecarName = sidecarName => {
  if (!sidecarName || sidecarName === '') {
    return 'Sidecar name is required'
  } else if (!SIDECAR_REGEX.test(sidecarName)) {
    return 'Sidecar name must contain only letters, numbers and dashes'
  }
}

export const validateLogIntegration = logIntegration => {
  if (!logIntegration || logIntegration === {}) {
    return 'Please select a valid log integration'
  }
}

export const validateMetricsIntegration = metricsIntegration => {
  if (!metricsIntegration || metricsIntegration === {}) {
    return 'Please select a valid log integration'
  }
}
