import * as React from 'react'
import { useMutation, gql } from '@apollo/client'

import UserAccountName from './steps/UserAccountName'
import SelectCredentialType from './steps/SelectCredentialType'

import AwsSecretsManager from './steps/credentials/AwsSecretsManager'
import AwsIam from './steps/credentials/AwsIam'
import EnvironmentVariable from './steps/credentials/EnvironmentVariable'
import HashicorpVault from './steps/credentials/HashicorpVault'
import KubernetesSecret from './steps/credentials/KubernetesSecret'
import GcpSecretManager from './steps/credentials/GcpSecretManager'

import { REPO_USER_ACCOUNTS } from '@jeeves/pages/RepositoryDetail/Tabs/UserAuthentication/graphql/fragments'
import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'
import { useUserAuthenticationContext } from '@jeeves/pages/RepositoryDetail/Tabs/UserAuthentication/contexts/UserAuthenticationContext'
import {
  allCredentialTypes,
  getCredentialsTypeMetadata,
} from '@jeeves/pages/RepositoryDetail/Tabs/UserAuthentication/helpers'

const CREATE_USER_ACCOUNT = gql`
  mutation CreateUserAccount($repoId: ID!, $userAccount: CreateUserAccountInput!) {
    createUserAccount(repoId: $repoId, userAccount: $userAccount) {
      userAccount {
        id
        name
      }
      repo {
        id
        type
        ...repoUserAccounts
      }
    }
  }
  ${REPO_USER_ACCOUNTS}
`

const UPDATE_USER_ACCOUNT = gql`
  mutation UpdateUserAccount(
    $repoId: ID!
    $userAccountId: ID!
    $userAccount: UpdateUserAccountInput!
  ) {
    updateUserAccount(repoId: $repoId, userAccountId: $userAccountId, userAccount: $userAccount) {
      userAccount {
        id
        name
      }
      repo {
        id
        type
        ...repoUserAccounts
      }
    }
  }
  ${REPO_USER_ACCOUNTS}
`

const userAccountTransformer = userAccount => {
  const { name, authenticationDatabase, credentials } = userAccount
  const { type, ...restOfCredentials } = credentials

  return {
    name,
    ...(authenticationDatabase && {
      authenticationDatabase,
    }),
    credentials: {
      [type]: {
        ...restOfCredentials,
      },
    },
  }
}

const useUpsertUserAccountWizard = () => {
  const [createUserAccount] = useMutation(CREATE_USER_ACCOUNT, {
    onCompleted: data => {
      const userAccountId = data.createUserAccount.userAccount.id

      onSelectUserAccount(userAccountId)
    },
  })
  const [updateUserAccount, { data, loading, error }] = useMutation(UPDATE_USER_ACCOUNT, {
    onCompleted: data => {
      const userAccountId = data.updateUserAccount.userAccount.id
      onSelectUserAccount(userAccountId)
    },
  })

  const { repoId, repoType } = useRepositoryDetailContext()
  const { onSelectUserAccount, selectedUserAccount: userAccount } = useUserAuthenticationContext()

  const [activeStep, setActiveStep] = React.useState(0)

  const prevStep = () => setActiveStep(currStep => currStep - 1)
  const nextStep = React.useCallback(() => {
    setActiveStep(currStep => currStep + 1)
  }, [])

  const handleMutateUserAccount = async userAccount => {
    const transformedUserAccount = userAccountTransformer(userAccount)

    try {
      if (!userAccount.id) {
        await createUserAccount({
          variables: {
            repoId,
            userAccount: transformedUserAccount,
          },
        })
      } else {
        await updateUserAccount({
          variables: {
            repoId,
            userAccountId: userAccount.id,
            userAccount: transformedUserAccount,
          },
        })
      }
    } catch (error) {}
  }

  const { __typename, ...rest } = userAccount?.credentials ?? {}

  const initialCredentials = userAccount?.credentials
    ? {
        ...rest,
        type: getCredentialsTypeMetadata(userAccount.credentials).value,
      }
    : {
        type: repoType === 's3' ? 'awsIAM' : allCredentialTypes[0].value,
      }

  const [databaseAccountInfo, setDatabaseAccountInfo] = React.useState({
    id: userAccount?.id || '',
    name: userAccount?.name || '',
    ...(userAccount?.authenticationDatabase && {
      authenticationDatabase: userAccount.authenticationDatabase,
    }),
    credentials: initialCredentials,
  })

  const credentialType = databaseAccountInfo.credentials.type

  const commonSteps = [
    <UserAccountName
      repoType={repoType}
      nextStep={nextStep}
      setDatabaseAccountInfo={setDatabaseAccountInfo}
      defaultValues={{
        username: databaseAccountInfo.name,
        ...(databaseAccountInfo.authenticationDatabase && {
          authenticationDatabase: databaseAccountInfo.authenticationDatabase,
        }),
      }}
    />,
    <SelectCredentialType
      nextStep={nextStep}
      initialSelectedCredentialType={credentialType}
      setDatabaseAccountInfo={setDatabaseAccountInfo}
    />,
  ]

  const stepsMap = {
    awsSecretsManager: [
      ...commonSteps,
      <AwsSecretsManager
        databaseAccountInfo={databaseAccountInfo}
        handleMutateUserAccount={handleMutateUserAccount}
      />,
    ],
    awsIAM: [
      ...(repoType !== 's3'
        ? commonSteps
        : [
            <UserAccountName
              repoType={repoType}
              nextStep={nextStep}
              setDatabaseAccountInfo={setDatabaseAccountInfo}
              defaultValues={{
                username: databaseAccountInfo.name,
                ...(databaseAccountInfo.authenticationDatabase && {
                  authenticationDatabase: databaseAccountInfo.authenticationDatabase,
                }),
              }}
            />,
          ]),
      <AwsIam
        databaseAccountInfo={databaseAccountInfo}
        handleMutateUserAccount={handleMutateUserAccount}
      />,
    ],
    hashicorpVault: [
      ...commonSteps,
      <HashicorpVault
        databaseAccountInfo={databaseAccountInfo}
        handleMutateUserAccount={handleMutateUserAccount}
      />,
    ],
    kubernetesSecret: [
      ...commonSteps,
      <KubernetesSecret
        databaseAccountInfo={databaseAccountInfo}
        handleMutateUserAccount={handleMutateUserAccount}
      />,
    ],
    gcpSecretManager: [
      ...commonSteps,
      <GcpSecretManager
        databaseAccountInfo={databaseAccountInfo}
        handleMutateUserAccount={handleMutateUserAccount}
      />,
    ],
    environmentVariable: [
      ...commonSteps,
      <EnvironmentVariable
        databaseAccountInfo={databaseAccountInfo}
        handleMutateUserAccount={handleMutateUserAccount}
      />,
    ],
  }

  const steps = stepsMap[credentialType]

  return {
    activeStep,
    steps,
    prevStep,
  }
}

export default useUpsertUserAccountWizard
