import * as React from 'react'
import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import { InlineCode, ServiceIcon, IconButton } from '@jeeves/new-components'

import DeleteServiceAccountModal from './DeleteServiceAccountModal'

import { useAppsAndBiToolsDispatch } from '../../contexts/AppsAndBiToolsContext'

const connectionDriverLogos = {
  looker: <ServiceIcon type="looker" sx={{ fontSize: 48 }} />,
  tableau: <ServiceIcon type="tableau" sx={{ fontSize: 48 }} />,
  custom: <ServiceIcon type="cyral" sx={{ fontSize: 48 }} />,
}

const ServiceAccountCard = ({ serviceAccount }) => {
  const { id: repoId } = useParams()
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const dispatch = useAppsAndBiToolsDispatch()

  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleEdit = () => {
    handleClose()
    dispatch({ type: 'editAccount', payload: serviceAccount })
  }

  const handleOpenDeleteModal = () => {
    handleClose()
    setDeleteModalOpen(true)
  }

  const handleCloseDeleteModal = () => setDeleteModalOpen(false)

  const { connectionDriverInstance } = serviceAccount

  return (
    <React.Fragment>
      <Stack
        direction="row"
        sx={{
          border: 1,
          borderColor: 'cyralColors.grey.200',
          padding: 4,
          borderRadius: theme => theme.radii.base,
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: 'grid',
            gridTemplateColumns: 'repeat(12, 1fr)',
            gap: 2,
          }}
        >
          <Box sx={{ gridColumn: 'span 6' }}>
            <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
              <Box>{connectionDriverLogos[connectionDriverInstance.type]}</Box>
              <Stack spacing={1} sx={{ overflow: 'hidden' }}>
                <Typography variant="h3" sx={{ color: 'text.primary' }} noWrap>
                  {serviceAccount.name}
                </Typography>
                <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                  <Typography variant="h4" sx={{ color: 'text.secondary', whiteSpace: 'nowrap' }}>
                    Application Name:
                  </Typography>
                  <InlineCode noWrap>{connectionDriverInstance.name}</InlineCode>
                </Stack>
              </Stack>
            </Stack>
          </Box>

          <Box sx={{ gridColumn: 'span 5', display: 'flex', alignItems: 'center' }}>
            {serviceAccount.numUsersAccessed !== null && (
              <Typography variant="h6" sx={{ color: 'text.primary' }}>
                Accessed by{' '}
                <Typography variant="h6" component="span" sx={{ color: 'primary.main' }}>
                  {serviceAccount.numUsersAccessed}
                </Typography>{' '}
                {`user${serviceAccount.numUsersAccessed === 1 ? '' : 's'}`} in the last{' '}
                <Typography variant="h6" component="span" sx={{ color: 'primary.main' }}>
                  7
                </Typography>{' '}
                days
              </Typography>
            )}
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gridColumn: 'span 1' }}>
            <IconButton onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
          </Box>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleEdit}>Edit</MenuItem>
            <MenuItem onClick={handleOpenDeleteModal}>Remove</MenuItem>
          </Menu>
        </Box>
      </Stack>

      <DeleteServiceAccountModal
        open={deleteModalOpen}
        closeModal={handleCloseDeleteModal}
        serviceAccountId={serviceAccount.id}
        repoId={repoId}
      />
    </React.Fragment>
  )
}

export default ServiceAccountCard
