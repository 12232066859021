import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  parseISO,
} from 'date-fns'

import pluralize from 'pluralize'

const getPluralizedOrSingularizedTime = (value: number, unit: string) =>
  pluralize(unit, value, true)

export const getRelativeTime = (timestamp: string) => {
  const eventDate = parseISO(timestamp)
  const now = new Date()

  const seconds = differenceInSeconds(now, eventDate)
  if (seconds < 60) {
    return getPluralizedOrSingularizedTime(seconds, 'second')
  }

  const minutes = differenceInMinutes(now, eventDate)
  if (minutes < 60) {
    return getPluralizedOrSingularizedTime(minutes, 'minute')
  }

  const hours = differenceInHours(now, eventDate)
  if (hours < 24) {
    return getPluralizedOrSingularizedTime(hours, 'hour')
  }

  const days = differenceInDays(now, eventDate)
  return getPluralizedOrSingularizedTime(days, 'day')
}
