import React, { useState, useEffect } from 'react'
import {
  Collapse,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  FormHelperText,
} from '@material-ui/core'
import { useAuth } from '@jeeves/hooks'
import ExpressClient from '@jeeves/clients/express'
import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'

export default ({
  vaultIntegrationID = '',
  setVaultIntegrationID = () => {},
  setVaultIntegration = () => {},
  disabled = false,
  error = '',
  touched = false,
  ...props
}) => {
  const { getTokenSilently } = useAuth()
  const ec = new ExpressClient(getTokenSilently)
  const { showError } = usePopup()

  const [vaultOptions, setVaultOptions] = useState([])
  const noneVaultOption = { id: 'None', integration: { name: 'None' } }

  useEffect(() => {
    const fetchVaultOptions = () => {
      try {
        ec.get('/integrations/secretProviders/hcvault').then(res => setVaultOptions(res.data))
      } catch (e) {
        showError('Error while fetching integrations')
      }
    }
    fetchVaultOptions()
  }, [])

  useEffect(() => {
    const id = vaultIntegrationID || (props && props.value)
    setVaultIntegration(vaultOptions.find(option => option.id === id))
  }, [vaultIntegrationID, props.value])

  return (
    <FormControl fullWidth error={!!error && touched}>
      <InputLabel htmlFor="vault-integration">Vault Integration (optional)</InputLabel>
      <Select
        value={vaultIntegrationID}
        onChange={e => setVaultIntegrationID(e.target.value)}
        name="vaultIntegrationID"
        disabled={disabled}
        inputProps={{ id: 'vault-integration' }}
        {...props}
      >
        {[noneVaultOption, ...vaultOptions].map(integration => (
          <MenuItem key={integration.id} value={integration.id}>
            {integration.id === 'None' ? (
              <i>{integration.integration.name}</i>
            ) : (
              integration.integration.name
            )}
          </MenuItem>
        ))}
      </Select>
      {error && touched && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}
