/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import { useState, Fragment, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  withStyles,
  Checkbox,
  FormControlLabel,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormLabel as MuiFormLabel,
  FormHelperText,
  TextField,
} from '@material-ui/core'
import { Button } from '@jeeves/components/Primitives'
import { ButtonLoading } from '@jeeves/components/Loading'
import ExpressClient from '@jeeves/clients/express'
import { useAuth } from '@jeeves/components/Auth'
import { CopyValue } from '@jeeves/pages/Wrappers/Instructions/components/CustomGen'
import { usePopup } from '@jeeves/hooks'
import _ from 'lodash'
import { Box } from '@mui/material'

const styles = theme => ({
  hideEditButton: {
    display: 'none',
  },
  inputField: {
    marginBottom: theme.spacing.unit * 2,
  },
  DialogActions: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing.unit * 3,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    paddingTop: 0,
  },
})

const FormLabel = styled(MuiFormLabel)`
  color: black;
  font-size: 1rem;
  font-weight: 600;
  line-height: 24px;
`

const getDefaultValues = (edit, serviceAccount, roles) => {
  return {
    name: edit ? serviceAccount?.displayName : '',
    roles: edit
      ? roles?.reduce(
          (prevObj, role) => ({
            ...prevObj,
            [role.id]: serviceAccount.roleIds.includes(role.id),
          }),
          {}
        )
      : roles?.reduce((prevObj, role) => ({ ...prevObj, [role.id]: false }), {}),
  }
}

const getRolesError = roles =>
  roles && Object.values(roles).find(role => role) ? true : 'At least one role must be selected.'

const ServiceAccountDialog = ({
  open,
  onClose = () => {},
  roles,
  classes,
  refresh = () => {},
  edit,
  serviceAccount,
}) => {
  const [clientId, setClientId] = useState()
  const [clientSecret, setClientSecret] = useState()
  const [updated, setUpdated] = useState(edit)
  const { getTokenSilently } = useAuth()
  const ec = new ExpressClient(getTokenSilently)
  const { showError } = usePopup()
  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    trigger,
    formState: { isSubmitting, isSubmitted, isSubmitSuccessful },
  } = useForm(getDefaultValues(edit, serviceAccount, roles))

  const onSubmit = async ({ name, roles }) => {
    try {
      if (edit) {
        await ec
          .patch(`/users/serviceAccounts/${serviceAccount.clientId}`, {
            displayName: name,
            roleIds: Object.keys(roles).filter(key => roles[key]),
          })
          .then(res => res.data)
      } else {
        const serviceAccount = await ec
          .post('/users/serviceAccounts', {
            displayName: name,
            roleIds: Object.keys(roles).filter(key => roles[key]),
          })
          .then(res => res.data)
        setClientId(serviceAccount.clientId)
        setClientSecret(serviceAccount.clientSecret)
      }
    } catch (e) {
      showError(`Error while ${edit ? 'updating' : 'creating new'} API client credentials`)
      console.error(e)
      throw e
    }
  }

  const handleRotate = async () => {
    try {
      if (edit) {
        const updatedServiceAccount = await ec
          .post(`/users/serviceAccounts/${serviceAccount.clientId}/rotateSecret`)
          .then(res => res.data)
        setClientId(updatedServiceAccount.clientId)
        setClientSecret(updatedServiceAccount.clientSecret)
      }
    } catch (e) {
      showError('Error while rotating client secret')
      console.error(e)
      throw e
    }
  }

  const handleClose = () => {
    onClose()
    setClientId(null)
    setClientSecret(null)
    reset(getDefaultValues(edit, serviceAccount, roles))
    if (isSubmitSuccessful) refresh()
  }

  const handleModifyAll = (name, select) => {
    const newArray = []
    roles.forEach(role => {
      newArray[role.id] = select
    })
    setValue(name, newArray)
  }

  useEffect(() => {
    if (edit && isSubmitSuccessful) {
      handleClose()
    }
  }, [isSubmitSuccessful, edit])

  useEffect(() => {
    if (roles && roles.length > 0) {
      reset(getDefaultValues(edit, serviceAccount, roles))
    }
  }, [roles])

  useEffect(
    () => {
      if (edit)
        setUpdated(
          watch('name') === getDefaultValues(edit, serviceAccount, roles)?.name &&
            _.isEqual(watch('roles'), getDefaultValues(edit, serviceAccount, roles)?.roles)
        )
    },
    [watch('name'), watch('roles')],
    edit,
    serviceAccount,
    roles
  )

  return (
    <Dialog open={open} onClose={handleClose} fullWidth aria-labelledby="form-dialog-title">
      {(isSubmitSuccessful && !edit) || (edit && clientSecret) ? (
        <Fragment>
          <DialogTitle id="form-dialog-title" className={classes.DialogTitle}>
            {edit ? 'New client secret' : 'API client credentials'}
            <Typography variant="caption" css={{ fontSize: '1rem' }}>
              Please copy the client secret, as it cannot be retrieved again.
            </Typography>
          </DialogTitle>
          <DialogContent className={classes.DialogContent}>
            {edit ? (
              <div css={{ display: 'flex', alignItems: 'center' }}>
                <CopyValue flex value={clientSecret} />
              </div>
            ) : (
              <div css={{ display: 'grid', gridTemplate: 'auto auto / 130px auto 60px' }}>
                <CopyValue label="Client ID" value={clientId} />
                <CopyValue label="Client Secret" value={clientSecret} />
              </div>
            )}
          </DialogContent>
          <DialogActions className={classes.DialogActions}>
            {/* <ErrorMessage message={error} /> */}
            <div className={classes.grow} />

            <Button color="primary" variant="contained" onClick={handleClose}>
              Done
            </Button>
          </DialogActions>
        </Fragment>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="form-dialog-title" className={classes.DialogTitle}>
            {`${edit ? 'Edit' : 'Create'} API Client`}
          </DialogTitle>
          <DialogContent className={classes.DialogContent}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: 'This field is required.',
                maxLength: {
                  value: 256,
                  message: 'Invalid input. Name must be <= 256 characters.',
                },
                pattern: new RegExp('^[\\w][\\w\\.\\-]*$'),
              }}
              render={({ field: { name, ref, ...inputProps }, fieldState: { error } }) => {
                return (
                  <TextField
                    margin="dense"
                    id="nameInput"
                    label="Name"
                    type="text"
                    variant="outlined"
                    className={classes.inputField}
                    fullWidth
                    name={name}
                    inputRef={ref}
                    {...inputProps}
                    error={!_.isEmpty(error)}
                    helperText={error?.message ?? ''}
                  />
                )
              }}
            />
            <Controller
              control={control}
              name="roles"
              rules={{
                validate: () => getRolesError(watch('roles')),
              }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error },
              }) => {
                return (
                  <Fragment>
                    <div
                      css={theme => css`
                        border: 1px solid ${error ? theme.palette.error.main : 'rgb(0, 0, 0, 0.15)'};
                        border-radius: 4px;
                        padding: 24px;
                      `}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <FormLabel>Select permissions for this API client.</FormLabel>
                        <Box>
                          <Button
                            variant="outlined"
                            color="secondary"
                            css={{ marginRight: '8px' }}
                            onClick={() => {
                              handleModifyAll(name, true)
                              if (isSubmitted) trigger(name)
                            }}
                          >
                            Select All
                          </Button>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                              handleModifyAll(name, false)
                              if (isSubmitted) trigger(name)
                            }}
                          >
                            Clear All
                          </Button>
                        </Box>
                      </Box>

                      {roles.map(role => (
                        <FormControlLabel
                          key={role.id}
                          control={
                            <Checkbox
                              name={role.id}
                              color="primary"
                              checked={value[role.id]}
                              onChange={e => {
                                setValue(name, { ...value, [role.id]: e.target.checked })
                                if (isSubmitted) trigger(name)
                              }}
                            />
                          }
                          label={role.name}
                        />
                      ))}
                    </div>
                    {error && (
                      <FormHelperText css={{ margin: '8px 12px 0' }} error>
                        {error?.message}
                      </FormHelperText>
                    )}
                  </Fragment>
                )
              }}
            />
          </DialogContent>
          <DialogActions className={classes.DialogActions}>
            {edit && (
              <Button color="primary" onClick={handleRotate} variant="outlined">
                Rotate Client Secret
              </Button>
            )}
            <div css={{ flexGrow: 1 }} />

            <Button variant="contained" onClick={handleClose}>
              Cancel
            </Button>
            <div
              css={() => css`
                position: relative;
              `}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || updated}
              >
                {edit ? 'Save' : 'Create'}
              </Button>
              {isSubmitting && <ButtonLoading />}
            </div>
          </DialogActions>
        </form>
      )}
    </Dialog>
  )
}

export default withStyles(styles)(ServiceAccountDialog)
