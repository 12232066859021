import React from 'react'
import Box from '@mui/material/Box'
import Highlight, { defaultProps } from 'prism-react-renderer'
import theme from 'prism-react-renderer/themes/github'

import { IconCopyButton } from '@jeeves/new-components'

import useFormattedCode from './useFormattedCode'
import { Pre } from './CodeBlock.style'

const CodeTheme = theme

const CodeBlock = ({
  children,
  language = 'bash',
  className: wrapperClassName = '',
  highlight = false,
  // type = 'single',
  copy,
  ...rest
}) => {
  const formattedCode = useFormattedCode(children.toString(), language)

  return (
    <Box className={wrapperClassName}>
      <Highlight
        {...defaultProps}
        code={formattedCode}
        language={language}
        theme={CodeTheme}
        // {...rest}
      >
        {({ className, style: themeStyle, tokens, getLineProps, getTokenProps }) => {
          const style = highlight
            ? themeStyle
            : {
                backgroundColor: 'cyralColors.grey.100',
                color: 'text.primary',
              }

          return (
            <Pre
              className={className}
              sx={{
                ...style,
              }}
            >
              <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
                <IconCopyButton text={children} />
              </Box>
              {tokens.map((line, i) => {
                const lineProps = getLineProps({ line, key: i })
                lineProps.style = highlight ? lineProps.style : {}

                return (
                  <Box {...lineProps}>
                    {line.map((token, key) => {
                      const tokenProps = getTokenProps({ token, key })
                      tokenProps.style = highlight ? tokenProps.style : {}

                      return <Box component="span" {...tokenProps} />
                    })}
                  </Box>
                )
              })}
            </Pre>
          )
        }}
      </Highlight>
    </Box>
  )
}

export default CodeBlock
