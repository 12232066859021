/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useEffect } from 'react'
import Chart from 'chart.js'

const ChartWrapper = ({ chartData }) => {
  // Chart.defaults.scale.gridLines.display = false

  let chart

  useEffect(() => {
    const createChart = () => {
      const ctx = document.getElementById('chart-element').getContext('2d')
      const chartSettings = !chartData
        ? undefined
        : {
            type: chartData.type || undefined,
            data: chartData.data || [],
            options:
              {
                ...chartData.options,
                legend: { ...chartData.options.legend, position: 'right' },
              } || [],
            plugins: [
              {
                beforeInit: function(chart, options) {
                  chart.legend.afterFit = function() {
                    this.height = this.height + 50
                  }
                },
              },
            ],
          }
      ctx.canvas.width = 700
      ctx.canvas.height = 475

      if (chartSettings) {
        chart = new Chart(ctx, chartSettings)
      }
    }

    createChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartData])

  return (
    <div css={{ maxHeight: '600px' }}>
      <canvas id="chart-element" />
    </div>
  )
}

export default ChartWrapper
