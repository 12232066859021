/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Fragment } from 'react'
import { TableRow, withStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import { TableCell } from '@jeeves/components/Table'
import { ServiceIcon } from '@jeeves/components/ServiceIcon'
import { TableLink } from '@jeeves/components/Primitives'
import { Tag } from '@jeeves/components/Tag'

import { PassthroughToggle } from './PassthroughToggle'

import Status from './Status'

const styles = () => ({
  status: {
    width: 24,
    paddingRight: 0,
  },
  root: {
    backgroundColor: '#FAFAFA',
  },
  hidden: {
    visibility: 'hidden',
  },
})

const Row = props => {
  const { wrapper, classes, key } = props
  const { id, health, metrics, repoCount, sidecar, numOfActiveRepos } = wrapper
  const { passThroughEnabled } = sidecar
  const history = useHistory()

  const getMetrics = () => {
    return metrics === null ? '-' : Math.round(metrics)
  }

  const propertiesList = sidecar.properties
    ? Object.entries(sidecar.properties)
        .filter(([k, v]) => v)
        .map(([k, v], i) => `${k}:${v}`)
    : []
  const labelsList = sidecar.labels || []
  let tags = [...propertiesList.sort(), ...labelsList.sort()]
  tags = tags.filter(
    tag =>
      tag.charAt(0) !== '_' &&
      // !tag.includes('deploymentMethod:') &&
      !tag.includes('logIntegrationID:') &&
      !tag.includes('metricsIntegrationID:') &&
      !tag.includes('awsRegion:') &&
      !tag.includes('vpc:') &&
      !tag.includes('publiclyAccessible:') &&
      !tag.includes('publicSubnets:') &&
      !tag.includes('keyName:')
  ) //TODO: fix how we filter _cyral-sandbox and cyral-set properties

  return (
    <Fragment key={key}>
      <TableRow
        tabIndex={-1}
        key={key}
        css={() => css`
          transition: opacity 0.2s ease-in-out;
          cursor: pointer;
        `}
        onClick={() => history.push(`/sidecars/${id}`)}
        hover
      >
        <TableCell className={classes.status}>
          <Status status={health} />
        </TableCell>
        <TableCell>
          <TableLink>{sidecar.name}</TableLink>
        </TableCell>

        <TableCell>
          {tags.map(tag => (
            <Tag key={tag}>{tag}</Tag>
          ))}
        </TableCell>

        <TableCell textAlign="center">
          <ServiceIcon
            type={sidecar.cloud}
            css={() => css`
              max-width: 40px;
              max-height: 40px;
            `}
          />
        </TableCell>
        <TableCell numeric textAlign="center">
          {wrapper.activeInstances}
        </TableCell>
        <TableCell numeric textAlign="center">
          {repoCount}
        </TableCell>
        {/* <TableCell numeric textAlign="center">
          {getMetrics()}
        </TableCell> */}
      </TableRow>
    </Fragment>
  )
}

export default withStyles(styles)(Row)
