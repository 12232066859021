/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { grey } from '@material-ui/core/colors'
import { IconButton } from '@material-ui/core'
import Crypto from 'crypto'
import { getLuminance } from 'polished'

const StyledTag = styled.div`
  display: inline-block;
  padding: ${({ isButton }) => (isButton ? '2px 2px 2px 4px' : '3px 7px 6px 7px')};
  background-color: ${grey[300]};
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.87);
  margin: 0 5px;
  border-radius: 4px;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.fontSize[2]};
`
const COLORS = [
  '#5D938D',
  '#6479A4',
  '#9079AE',
  '#92A2A9',
  '#A7C9DD',
  '#A7D7D2',
  '#BDE095',
  '#BE7B8F',
  '#CBD4D8',
  '#CBD4D8',
  '#D5C9E3',
  '#EEBDAE',
]

const getHashValue = tag => {
  return Crypto.createHash('md5').update(tag.toLowerCase()).digest('hex').slice('-4')
}

const mapColor = (tag, colors = []) => {
  const HEX_BASE = 16
  const intValue = parseInt(getHashValue(tag), HEX_BASE)
  const index = intValue % colors.length

  return colors[index]
}

export const Tag = ({ children, label, ...props }) => {
  const [color, setColor] = useState(grey[300])
  const [textColor, setTextColor] = useState('#000000')
  const tag = label ? label : children // eslint-disable-line

  useEffect(() => {
    const tagColor =
      typeof tag === 'string'
        ? tag === 'masking-enabled'
          ? '#31B237'
          : mapColor(tag, COLORS)
        : grey[300]
    const text = getLuminance(tagColor) > 0.5 ? 'black' : 'white'

    setTextColor(text)
    setColor(tagColor)
  }, [])

  return (
    <StyledTag css={{ backgroundColor: color, color: textColor }} {...props}>
      {children || label}
    </StyledTag>
  )
}

export const ButtonTag = ({ icon, onClick, children, ...props }) => (
  <Tag label={children} {...props} isButton>
    <span>{children}</span>
    {onClick && icon && (
      <IconButton css={{ padding: '4px' }} onClick={onClick}>
        {icon}
      </IconButton>
    )}
  </Tag>
)
