import * as React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Link from '@mui/material/Link'

import { Button, Dialog, DialogActions } from '@jeeves/new-components'

const CustomConnectionDriverModal = ({ open, setModalOpen, closeWizard }) => {
  const handleClose = () => {
    setModalOpen(false)
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      TransitionProps={{
        onExited: closeWizard,
      }}
    >
      <DialogTitle variant="h3" id="alert-dialog-titlea">
        Custom Connection Driver Requested
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="body1" sx={{ color: 'text.secondary' }}>
            A notification has been sent to Cyral customer support regarding your request for a
            custom connection driver.
          </Typography>
          <Typography variant="body1" sx={{ color: 'text.secondary' }}>
            For additional assistance, reach out to{' '}
            <Link href="mailto:support@cyral.com" underline="hover">
              support@cyral.com
            </Link>
            .
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CustomConnectionDriverModal
