import React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Card, Tabs, Tab, Notification } from './DataLabels.style'

import { Recommendations } from './Predefined/Recommendations/Recommendations'

import { PredefinedLabels } from './Predefined/PredefinedLabels'
import { CustomLabels } from './Custom/CustomLabels'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

import { useQuery, useMutation, gql } from '@apollo/client'
import {
  RecommendationsContainer,
  RecommendationsHeader,
  RecommendationCard,
} from '@jeeves/pages/RepositoryDetail/Tabs/Mappings/Recommendations/Recommendations.styles.js'

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}
const FETCH_RECOMMENDATIONS = gql`
  query RecommendationsQuery($query: DatamapRecommendationsQuery) {
    datamapRecommendations(query: $query) {
      repo {
        type
        name
        id
      }
    }
  }
`

export const DATALABELS_QUERY = gql`
  query Datalabels {
    datalabels {
      name
      description
      type
      tags
      classificationRule {
        ruleType
        ruleCode
        enabled
      }
      mappedReposRelationship {
        edges {
          node {
            id
            name
            type
          }
          datamapMappings {
            datalabel {
              name
              description
            }
            field {
              ... on Attribute {
                attribute
              }
              ... on RESTEndpoint {
                endpoint {
                  uri
                  method
                  createdCount
                  readCount
                  updatedCount
                  deletedCount
                }
              }
            }
          }
        }
      }
    }
  }
`

const UPDATE_ENABLED = gql`
  mutation UpdateDatalabelStatus($datalabelName: String!, $enabled: Boolean!) {
    updateDatalabelStatus(datalabelName: $datalabelName, enabled: $enabled) {
      message
    }
  }
`

const ErrorBanner = ({ text }) => (
  <RecommendationsContainer
    sx={{
      mb: 2,
      backgroundColor: 'cyralColors.red.100',

      pb: 1,
    }}
  >
    <RecommendationsHeader sx={{ mb: 1.5 }}>
      <Stack direction="row">
        <WarningAmberIcon
          sx={{
            color: 'cyralColors.red.400',
          }}
        />

        <Typography
          variant="h6"
          sx={{
            alignSelf: 'center',
            ml: 1,
            color: 'cyralColors.red.400',
          }}
        >
          {text}
        </Typography>
      </Stack>
    </RecommendationsHeader>
  </RecommendationsContainer>
)

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

const DataLabels = () => {
  const [value, setValue] = React.useState(0)

  const handleChange = (_, newValue) => {
    setValue(newValue)
  }
  const {
    loading: labelsLoading,
    error: labelsError,
    data,
  } = useQuery(DATALABELS_QUERY, { errorPolicy: 'all' })
  const {
    loading,
    error: recommendationsError,
    data: recommendationsData,
  } = useQuery(FETCH_RECOMMENDATIONS, {
    errorPolicy: 'all',
    variables: {
      query: {
        status: 'RECOMMENDED',
      },
    },
  })
  const [updateEnabled, { data: enabledData, loading: enabledLoading, error: enabledError }] =
    useMutation(UPDATE_ENABLED, {
      refetchQueries: [DATALABELS_QUERY],
    })

  const handleUpdateEnabled = async (datalabelName, enabled) => {
    try {
      await updateEnabled({
        variables: {
          datalabelName,
          enabled,
        },
      })
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Card sx={{ minHeight: '750px' }}>
      <Card.Header>
        <Stack spacing={1} direction="row" alignItems="center">
          <Typography variant="h2" sx={{ color: 'text.primary' }}>
            Data Labels
          </Typography>
          <InfoOutlinedIcon
            fontSize="small"
            sx={{
              color: 'cyralColors.grey.200',
              // Used to achieve optical alignment to fit Figma design
              transform: 'translateY(3.5px)',
            }}
          />
        </Stack>

        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Predefined Labels" {...a11yProps(0)} />
          <Tab label="Custom Labels" {...a11yProps(1)} />
        </Tabs>
      </Card.Header>
      <Card.Body>
        {recommendationsError && (
          <ErrorBanner text="There was a problem loading data label suggestions" />
        )}
        {labelsError && <ErrorBanner text="There was a problem loading data labels" />}
        <TabPanel value={value} index={0}>
          {recommendationsData && (
            <Recommendations
              recommendations={recommendationsData.datamapRecommendations
                .filter(
                  (value, index, self) => index === self.findIndex(t => t.repo.id === value.repo.id)
                )
                .filter(recommendation => recommendation?.repo?.name !== '')}
			/>
          )}

          {data && (
            <PredefinedLabels
              handleUpdateEnabled={handleUpdateEnabled}
              dataLabels={data.datalabels.filter(datalabel => datalabel.type === 'PREDEFINED')}
            />
          )}
        </TabPanel>

        <TabPanel value={value} index={1}>
          {data && (
            <CustomLabels
              dataLabels={data.datalabels.filter(datalabel => datalabel.type === 'CUSTOM')}
            />
          )}
        </TabPanel>
      </Card.Body>
    </Card>
  )
}

export default DataLabels
