import { useContext, useState, useEffect } from 'react'
import ExpressClient from '@jeeves/clients/express'
import { useAuth } from '@jeeves/components/Auth'
import { usePopup } from '@jeeves/hooks'
import moment from 'moment'

export const useAccounts = () => {
  const { getTokenSilently, user } = useAuth()
  const { setPopup, popupTypes } = usePopup()
  const ec = new ExpressClient(getTokenSilently)

  const createSidecarAccount = async sidecarId => {
    try {
      const resp = await ec.post('/sidecars/sidecarAccounts', { sidecarId }).then(res => res.data)
      return resp
    } catch (e) {
      console.log(e)
      throw e
    }
  }

  const getDate = clientId => {
    return moment(getTimestamp(clientId)).format('MMMM DD, YYYY')
  }

  const getTimestamp = clientId => {
    let split = clientId.split('/')
    const timestamp = split[split.length - 1]

    return timestamp / Math.pow(10, 6) // convert nanoseconds to milliseconds before formatting
  }

  const createdOnComparator = order => {
    const timestamp = account => getTimestamp(account.clientId)
    const desc = (a, b) => (timestamp(a) < timestamp(b) ? 1 : -1)
    return order === 'desc' ? desc : (a, b) => -desc(a, b)
  }

  return {
    getDate,
    getTimestamp,
    createSidecarAccount,
    createdOnComparator,
  }
}
