import * as sv from 'semver'
import * as path from 'path'
import { XMLParser } from 'fast-xml-parser'

// getDocsURL returns the documentation URL corresponding to the version of the control plane
// The function takes an optional argument object with the following properties:
//   `docsPath`: used to link to a specific page/section in the documentation
//     (e.g. '/policy/datamap#add-or-edit-a-data-map')
//   `useVersionedDocsURL`: if true, the version of the control plane will be used to determine
//     the version of the documentation to link to -- otherwise, the unversioned docs URL will be returned
//   `listOfAvailableURLDocs`: defines the URLs available in the sitemap, this is usually read with fetch
//     if not defined
export const getDocsURL = ({
  docsPath = '',
  useVersionedDocsURL = true,
  listOfAvailableURLDocs = null,
} = {}) => {
  const versionedURL = _getDocsURL(docsPath, useVersionedDocsURL)
  if (_isURLValid(versionedURL, listOfAvailableURLDocs)) {
    return versionedURL
  }
  const unversionedURL = _getDocsURL(docsPath, (useVersionedDocsURL = false))
  return unversionedURL
}

const _getDocsURL = (docsPath, useVersionedDocsURL) => {
  const baseURL = _getDocsBaseURL(useVersionedDocsURL)
  const docsURL = new URL(path.join(baseURL, docsPath)).toString()
  return docsURL
}

// _getDocsVersion returns the version of the public docs corresponding to the given control plane version
//   This entails trimming the given CP version down to its minor version.
//   If the given CP version not a valid semver, the function returns ''.
export const _getDocsVersion = controlPlaneVersion => {
  const parsedCPVersion = sv.parse(controlPlaneVersion)
  return parsedCPVersion ? `v${sv.major(parsedCPVersion)}.${sv.minor(parsedCPVersion)}` : ''
}

// starting with null
let docsLinksArray = null

const _getDocsBaseURL = useVersionedDocsURL => {
  const unversionedDocsBaseURL = window?._env_?.documentation_base_url || 'https://cyral.com/docs'
  const controlPlaneVersion = window?._env_?.control_plane_version || ''
  const docsVersion = useVersionedDocsURL ? _getDocsVersion(controlPlaneVersion) : ''
  return new URL(path.join(unversionedDocsBaseURL, docsVersion)).toString()
}

// _isURLValid returns whether the created
// URL is in the docsLinksArray or not.
const _isURLValid = (URL, listOfAvailableURLDocs) => {
  const cleanDocsURL = URL.split('#')[0].replace(/\/$/, '')
  const checkArray = () => {
    if (docsLinksArray !== null) {
      return docsLinksArray.includes(cleanDocsURL)
    }
    setTimeout(checkArray, 100) // waits 100 ms before trying again
  }
  if (listOfAvailableURLDocs !== null) {
    return listOfAvailableURLDocs.includes(cleanDocsURL)
  }
  return checkArray()
}

// fetches XML from "baseURL/sitemap.xml"
function _readXMLFromLink(link, successCallback, errorCallback) {
  const xmlURL = link + '/sitemap.xml'

  fetch(xmlURL)
    .then(response => {
      if (!response.ok) {
        throw new Error('Error reading XML from link: ' + response.status)
      }
      return response.text()
    })
    .then(xmlText => {
      successCallback(xmlText)
    })
    .catch(error => {
      errorCallback(error)
    })
}

const unversionedDocsBaseURL = window?._env_?.documentation_base_url || 'https://cyral.com/docs'
// calls the function
_readXMLFromLink(
  unversionedDocsBaseURL,
  function (xmlResponse) {
    const rawJSON = _parseXML(xmlResponse)
    docsLinksArray = rawJSON.urlset.url.map(obj => obj.loc)
  },
  function (error) {
    docsLinksArray = []
    console.error(error)
  }
)

// Parses the XML, transforming it in a JSON
const _parseXML = xml => {
  const options = {
    attributeNamePrefix: '@_',
    attrNodeName: 'attr', // default is 'false'
    textNodeName: '#text',
    ignoreAttributes: false,
    ignoreNameSpace: false,
    parseNodeValue: true,
    trimValues: true,
    cdataTagName: '__cdata', // default is 'false'
    cdataPositionChar: '\\c',
    parseTrueNumberOnly: false,
    arrayMode: false, // "strict"
  }

  const parser = new XMLParser(options)

  try {
    return parser.parse(xml, true)
  } catch (err) {
    throw err
  }
}
