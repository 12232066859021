import { useAppConfig } from '@jeeves/hooks'

const useEntitlements = () => {
  const { license } = useAppConfig()
  const hasFeature = featureName => {
    return license.features && license.features.some(f => f.internalName === featureName)
  }
  const policyMonitoring = () => {
    return hasFeature('AllowPolicyMonitoring')
  }
  const policyBlocking = () => {
    return hasFeature('AllowPolicyBlocking')
  }

  return {
    hasFeature,
    policyMonitoring,
    policyBlocking,
  }
}

export default useEntitlements
