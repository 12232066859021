import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Accordion from '@mui/material/Accordion'
import Typography from '@mui/material/Typography'
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone'
import { formatDistanceToNowStrict, parseISO, milliseconds, format } from 'date-fns'

import { Tooltip } from '@jeeves/new-components'
import { RepoHeaderContent } from '../components'

import StyledDataRepoCard from './GeneralDataRepoCard.style'
import {
  AccordionSummary,
  AccordionDetails,
  DenodoAccordionContent,
  DremioAccordionContent,
  DynamoDBAccordionContent,
  DynamoDBStreamsAccordionContent,
  MariaDBAccordionContent,
  MongoAccordionContent,
  MySqlAccordionContent,
  OracleAccordionContent,
  PostgresqlAccordionContent,
  RedshiftAccordionContent,
  SqlServerAccordionContent,
} from './components'

const getExpirationContent = isoDate => {
  const dateObject = parseISO(isoDate)
  const duration = dateObject - new Date()
  const overOneWeek = duration > milliseconds({ weeks: 1 })
  const dateString = format(dateObject, 'MMM do, y')
  const content = (
    <Stack direction="row" sx={{ alignItems: 'center' }} spacing={1}>
      <Typography variant="h6" sx={{ color: 'text.secondary' }}>
        {`Access expires ${
          overOneWeek ? `on ${dateString}` : `in ${formatDistanceToNowStrict(dateObject)}`
        }`}
      </Typography>
      <AccessTimeTwoToneIcon
        sx={{
          fontSize: '1rem',
          color: overOneWeek ? 'secondary.main' : 'cyralColors.yellow.300',
        }}
      />
    </Stack>
  )
  return overOneWeek ? (
    content
  ) : (
    <Tooltip
      title={<Typography variant="caption">{`Your access expires on ${dateString} at ${format(dateObject, 'h:mm aaa OOOO')}`}</Typography>}
    >
      {content}
    </Tooltip>
  )
}

const getAccordionDetailsContent = type => {
  switch (type) {
    case 'denodo':
      return DenodoAccordionContent
    case 'dremio':
      return DremioAccordionContent
    case 'dynamodb':
      return DynamoDBAccordionContent
    case 'dynamodbstreams':
      return DynamoDBStreamsAccordionContent
    case 'postgresql':
      return PostgresqlAccordionContent
    case 'redshift':
      return RedshiftAccordionContent
    case 'mariadb':
      return MariaDBAccordionContent
    case 'mongodb':
      return MongoAccordionContent
    case 'mysql':
      return MySqlAccordionContent
    case 'sqlserver':
      return SqlServerAccordionContent
    case 'oracle':
      return OracleAccordionContent
    default:
      return null
  }
}

const GeneralDataRepoCard = ({ repoEdge, opaqueToken }) => {
  const [expanded, setExpanded] = useState()

  const handleChange = panel => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : null)
  }

  const { node: repo, accessibleUserAccounts, accessPortalBindingRelationship } = repoEdge

  const AccordionDetailsContent = getAccordionDetailsContent(repo.type)

  return (
    <StyledDataRepoCard>
      <Header repoType={repo.type} repoName={repo.name} tags={repo.tags} />

      <Box>
        {accessibleUserAccounts?.edges?.map(userAccountEdge => {
          const { node: userAccount, validUntil } = userAccountEdge

          return (
            <LocalAccountAccordion
              key={userAccount.id}
              expanded={expanded === userAccount.id}
              onChange={handleChange(userAccount.id)}
            >
              <AccordionSummary
                aria-controls={`${repo.name}-${userAccount.name}-content`}
                id={`${repo.name}-${userAccount.name}-header`}
              >
                <Stack direction="row" spacing={1} sx={{ width: '100%', alignItems: 'baseline', justifyContent: 'space-between' }}>
                  <Typography variant="h4">{userAccount.name}</Typography>
                  {validUntil && getExpirationContent(validUntil)}
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <AccordionDetailsContent
                  opaqueToken={opaqueToken}
                  repo={repo}
                  accessPortalSidecar={accessPortalBindingRelationship?.edge?.sidecar}
                  accessPortalSidecarBinding={accessPortalBindingRelationship?.edge?.node}
                  userAccountEdge={userAccountEdge}
                />
              </AccordionDetails>
            </LocalAccountAccordion>
          )
        })}
      </Box>
    </StyledDataRepoCard>
  )
}

const LocalAccountAccordion = ({ children, ...rest }) => {
  return (
    <Accordion
      {...rest}
      elevation={0}
      disableGutters
      square
      sx={{
        '&.Mui-expanded': {
          ':before': {
            opacity: 1,
          },
        },
      }}
    >
      {children}
    </Accordion>
  )
}

const Header = ({ repoType, repoName, tags }) => {
  return (
    <Box
      sx={{
        paddingY: 2,
        paddingX: 3,
        bgcolor: 'cyralColors.grey.100',
      }}
    >
      <RepoHeaderContent repoType={repoType} repoName={repoName} tags={tags} />
    </Box>
  )
}

GeneralDataRepoCard.LocalAccountAccordion = LocalAccountAccordion

export default GeneralDataRepoCard
