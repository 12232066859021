/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import styled from '@emotion/styled'
import { Tooltip } from '@material-ui/core'
import { grey, red, green, yellow } from '@material-ui/core/colors'

const statusMap = new Map([
  ['0', 'unhealthy'],
  ['1', 'healthy'],
  ['other', 'unknown'],
])

const STATUS = {
  healthy: green[500],
  unhealthy: red[500],
  unknown: yellow[700],
}

const Dot = styled.span`
  min-width: 9px;
  height: 9px;
  display: inline-block;
  border-radius: 10px;

  background: ${({ status }) => STATUS[status] || STATUS['unknown']};
`

const HealthStatus = ({ healthLevel }) => {
  const status = statusMap.get(healthLevel === '0' || healthLevel === '1' ? healthLevel : 'other')

  return (
    <Tooltip title={`Status: ${status}`}>
      <Dot status={status} />
    </Tooltip>
  )
}

export default HealthStatus
