/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useState, useEffect } from 'react'
import { Input } from '@material-ui/core'
import { useFormik } from 'formik'
import * as yup from 'yup'

import useInstructions from '@jeeves/pages/Wrappers//hooks/useInstructions'
import { LogsIntegration, MetricsIntegration, VaultIntegration } from '../IntegrationInputs'
import { DownloadLink, FormField, SidecarDialog, SidecarGenerateActions } from '../index'
import useWrappers from '@jeeves/pages/Wrappers/hooks/useWrappers'
import { useAuth } from '@jeeves/hooks'
import ExpressClient from '@jeeves/clients/express'
import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'
import { useAccounts } from '@jeeves/pages/WrapperDetail/components/Accounts/useAccounts'
import { useAppConfig } from '@jeeves/hooks'

export const DockerGenContents = ({
  newTemplate,
  alreadyCreated,
  saveProperties,
  onSave,
  clone,
  setCloned,
  setFinished,
  open,
  onClose,
  onGenerated,
  logsOptions,
  metricsOptions,
  sidecarName: argsSidecarName,
  logIntegrationID: argsLogIntegrationID,
  metricsIntegrationID: argsMetricsIntegrationID,
  vaultIntegrationID: argsVaultIntegrationID,
  sidecarId: argsSidecarId,
  repos,
  suggestedSidecarName,
}) => {
  const [dockerComposeUpCmd, setDockerComposeUpCmd] = useState('')
  const [downloadLink, setDownloadLink] = useState('')
  const [dockerCommand, setDockerCommand] = useState('')
  const [isGenerated, setIsGenerated] = useState(false)
  const { wrapperHandlers, repoHandlers } = useWrappers()
  const { user, getTokenSilently } = useAuth()
  const ec = new ExpressClient(getTokenSilently)
  const { showError } = usePopup()
  const [sidecarNames, setSidecarNames] = useState([])
  const { handleSetPopup, popupTypes, generateId, getGCRKey, createSidecar, cloneSidecar } = useInstructions()
  const { createSidecarAccount } = useAccounts()
  const { config } = useAppConfig()

  const getLink = async ({ sidecarName, logIntegration, metricsIntegration, vaultIntegration }) => {
    let sidecar = {}

    const sidecarProperties = {
      deploymentMethod: 'docker-compose',
      logIntegrationID: logIntegration.id,
      metricsIntegrationID: metricsIntegration.id,
      vaultIntegrationID: vaultIntegration ? vaultIntegration.id : undefined,
    }

    if (alreadyCreated) {
      if (clone) {
        const newSidecarResponse = await cloneSidecar({
          sidecarId: argsSidecarId,
          input: {
            name: sidecarName,
            ...sidecarProperties
          }
        })
        sidecar.ID = newSidecarResponse.sidecar.id
        sidecar.clientID = newSidecarResponse.sidecarAccount.clientId
        sidecar.clientSecret = newSidecarResponse.sidecarAccount.clientSecret
      } else {
        sidecar.ID = argsSidecarId
        const sidecarAccount = await createSidecarAccount(sidecar.ID)
        sidecar.clientID = sidecarAccount.clientId
        sidecar.clientSecret = sidecarAccount.clientSecret
      }
      if (saveProperties) {
        await ec.put(`/sidecars/${argsSidecarId}`, {
          properties: sidecarProperties,
        })
      }
    } else {
      sidecar = await createSidecar(sidecarName, sidecarProperties)
    }

    // const sidecarId = 'test' // uncomment to test without creating new sidecar
    // const token = await getTokenSilently({ audience: [] }) // opaque token
    const token = await getTokenSilently() // jwt
    const header = `-H "Authorization: Bearer ${token}"`
    const baseUrl = `${window.location.origin}/deploy/docker-compose`

    const params = {
      LogIntegration: logIntegration.type,
      MetricsIntegration: metricsIntegration.type,
      ...(vaultIntegration ? { HCVaultEnabled: true } : {}),
    }

    const getParams = () => {
      return new URLSearchParams(params).toString()
    }

    const splunkParams = {
      logIntegrationValue: logIntegration.type,
      SplunkIndex: logIntegration.value.index,
      SplunkHost: logIntegration.value.host,
      SplunkPort: logIntegration.value.hecPort,
      SplunkTLS: logIntegration.value.useTLS,
      SplunkToken: logIntegration.value.accessToken,
    }

    const sumoLogicParams = {
      SumologicHost: logIntegration.host,
      SumologicUri: logIntegration.uri,
    }

    const elkParams = {
      ELKAddress: logIntegration.value,
      ELKUsername: logIntegration.elkUsername,
      ELKPassword: logIntegration.elkPassword,
    }

    // returns the command to run docker compose
    const getDockerComposeUpCmd = () => {
      // default variables to export
      let paramsCompose = ''.concat(
        `CYRAL_SIDECAR_ID="${sidecar.ID}" `,
        `CYRAL_SIDECAR_CLIENT_ID="${sidecar.clientID}" `,
        `CYRAL_SIDECAR_CLIENT_SECRET="${sidecar.clientSecret}" `
      )
      if (logIntegration.type === 'splunk') {
        paramsCompose = paramsCompose.concat(
          `SPLUNK_HOST="${splunkParams.SplunkHost}" `,
          `SPLUNK_TOKEN="${splunkParams.SplunkToken}" `,
          `SPLUNK_PORT=${splunkParams.SplunkPort} `,
          `SPLUNK_TLS=${splunkParams.SplunkTLS} `,
          `SPLUNK_INDEX="${splunkParams.SplunkIndex}" `
        )
      } else if (logIntegration.type === 'sumologic') {
        paramsCompose = paramsCompose.concat(
          `SUMOLOGIC_HOST="${sumoLogicParams.SumologicHost}" `,
          `SUMOLOGIC_URI="${sumoLogicParams.SumologicUri}" `
        )
      } else if (logIntegration.type === 'elk') {
        paramsCompose = paramsCompose.concat(
          `ELK_ADDRESS="${elkParams.ELKAddress}" `,
          `ELK_USERNAME="${elkParams.ELKUsername}" `,
          `ELK_PASSWORD="${elkParams.ELKPassword}" `
        )
      }
      if (metricsIntegration.type === 'datadog') {
        paramsCompose = paramsCompose.concat(
          `DD_API_KEY="${metricsIntegration.value}" `
        )
      } else if (logIntegration.type === 'datadog') {
        paramsCompose = paramsCompose.concat(
          `DD_API_KEY="${logIntegration.value}" `
        )
      }
      if (vaultIntegration) {
        paramsCompose = paramsCompose.concat(
          `HCVAULT_INTEGRATION_ID="${vaultIntegration.id}" `
        )
      }
      paramsCompose = paramsCompose.concat('docker-compose -f sidecar.compose.yaml up')
      return paramsCompose
    }
    setDockerComposeUpCmd(getDockerComposeUpCmd())

    const tlsFlag = config && config.tlsType && config.tlsType === 'tls-skip-verify' ? '-k' : ''
    return `curl ${tlsFlag} "${baseUrl}?${getParams()}" \\
  -H "cyral-username: ${user.email}" \\
  -H "Authorization: Bearer ${token}" \\
  -o sidecar.compose.yaml \n`
  }

  //comment
  const getDockerCommand = async () => {
    const gcrKey = await getGCRKey()
    const dockerLogin = `echo ${gcrKey} \\
  | base64 --decode \\
  | docker login -u _json_key --password-stdin https://gcr.io`
    setDockerCommand(dockerLogin)
  }

  const { errors, values, getFieldProps, touched, isSubmitting, setValues, ...formik } = useFormik({
    validateOnChange: true,
    initialValues: {
      sidecarName: suggestedSidecarName || (alreadyCreated ? argsSidecarName : ''),
      logIntegration: argsLogIntegrationID || (alreadyCreated && !newTemplate ? 'default' : ''),
      metricsIntegration:
        argsMetricsIntegrationID || (alreadyCreated && !newTemplate ? 'default' : ''),
      vaultIntegrationID: argsVaultIntegrationID || 'None',
      vaultIntegration: '',
    },
    validationSchema: yup.object({
      sidecarName:
        (!alreadyCreated || (alreadyCreated && clone)) &&
        yup
          .string()
          .matches(/^[a-z0-9-_]+$/, 'Only letters, numbers and dashes allowed')
          .required('Sidecar is required'),
      logIntegration: yup.string().required('Please choose a log integration'),
      metricsIntegration: yup.string().required('Please choose the metrics integration'),
    }),
    onSubmit: async values => {
      try {
        const link = await getLink({
          ...values,
          logIntegration: logsOptions.find(logsOption => logsOption.id === values.logIntegration),
          metricsIntegration: metricsOptions.find(
            metricsOption => metricsOption.id === values.metricsIntegration
          ),
          vaultIntegration: values.vaultIntegration,
        })
        getDockerCommand()
        setDownloadLink(link)
        if (setFinished) setFinished(true)
        setIsGenerated(true)
        if (onGenerated) onGenerated()
      } catch (e) {
        console.log(e)
        let popupMessage = e.message
        if (e.error && e.error.trim() === 'login_required') {
          popupMessage = 'Third party cookies must be enabled'
        }
        handleSetPopup(popupTypes.ERROR, popupMessage)
      }
    },
  })

  const handleOnClose = () => {
    onClose()
    if (isGenerated && clone && setCloned) setCloned(true)
    // Save everytime because client id, secret is being generated
    if (isGenerated && onSave) onSave()
    setDownloadLink('')
    setDockerComposeUpCmd('')
    setIsGenerated(false)
    formik.resetForm()
    formik.setFieldValue('isGenerated', false)
  }

  useEffect(() => {
    if (!open) handleOnClose()
  }, [open])

  return (
    <form onSubmit={formik.handleSubmit}>
      {(!alreadyCreated || (alreadyCreated && clone)) && (
        <FormField error={errors.sidecarName} touched={touched.sidecarName} label="Sidecar Name">
          {/**
           * todo: add helper text to explain the user we need the sidecars to be lowercase, numbers and dashes only
           */}
          <Input
            fullWidth
            name="sidecarName"
            label="Sidecar name"
            disabled={isGenerated}
            {...getFieldProps('sidecarName')}
            onChange={e => {
              const { value } = e.target
              formik.setFieldValue('sidecarName', (value || '').toLowerCase().trim())
            }}
          />
        </FormField>
      )}

      <div css={theme => ({ marginBottom: theme.spacing[4], minHeight: '68px' })}>
        <LogsIntegration
          logsOptions={logsOptions}
          disabled={isGenerated}
          {...getFieldProps('logIntegration')}
        />
      </div>

      <div css={theme => ({ marginBottom: theme.spacing[4], minHeight: '68px' })}>
        <MetricsIntegration
          metricsOptions={metricsOptions}
          disabled={isGenerated}
          {...getFieldProps('metricsIntegration')}
        />
      </div>

      <div css={theme => ({ marginBottom: theme.spacing[4], minHeight: '68px' })}>
        <VaultIntegration
          disabled={isGenerated}
          {...getFieldProps('vaultIntegrationID')}
          vaultIntegration={values.vaultIntegration}
          setVaultIntegration={vaultIntegration =>
            setValues(values => ({ ...values, vaultIntegration }))
          }
        />
      </div>

      {downloadLink && dockerCommand && dockerComposeUpCmd && (
        <div>
          <DownloadLink caption={`Access Cyral's Docker registry`} downloadLink={dockerCommand} />
          <DownloadLink
            caption={`Run the docker-compose sidecar`}
            downloadLink={
              `${downloadLink}${dockerComposeUpCmd}`
            }
          />
        </div>
      )}

      <SidecarGenerateActions
        isGenerated={isGenerated}
        handleOnClose={handleOnClose}
        isSubmitting={isSubmitting}
      />
    </form>
  )
}

const DockerGen = ({ currentDialog, setCurrentDialog, ...props }) => {
  const [open, setOpen] = useState(currentDialog === 'docker-compose')

  useEffect(() => {
    setOpen(currentDialog === 'docker-compose')
  }, [currentDialog])

  const handleClose = () => {
    setCurrentDialog('')
  }

  return (
    // TO DO: setting open always to true not good
    <SidecarDialog
      style={{ innerWidth: '500px' }}
      open={open}
      onClose={handleClose}
      title="Generate Download Link"
    >
      <DockerGenContents open={open} onClose={handleClose} {...props}></DockerGenContents>
    </SidecarDialog>
  )
}

export default DockerGen
