import { valid as semverValid } from 'semver'

const cleanedTemplateGitReference = semverValid(window._env_.template_git_reference)

export const chartRepo = cleanedTemplateGitReference
  ? 'https://charts.cyral.com'
  : 'gs://charts.dev.cyral.com'

const cleanNonvalidSemver = gitReference => `0.0.1-${gitReference.replace(/\/|_/g, '-')}`

export const chartVersion =
  cleanedTemplateGitReference || cleanNonvalidSemver(window._env_.template_git_reference)
