import * as React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQuery, gql } from '@apollo/client'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import FormControl from '@mui/material/FormControl'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'

import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'

import { useAuth } from '@jeeves/components/Auth'
import ExpressClient from '@jeeves/clients/express'
import { useAppConfig } from '@jeeves/hooks'

import CustomConnectionDriverModal from './CustomConnectionDriverModal'
import { useAppsAndBiToolsState } from '../../../../contexts/AppsAndBiToolsContext'

import {
  Button,
  Input,
  InputLabel,
  CyralIcon,
  LookerIcon,
  TableauIcon,
} from '@jeeves/new-components'

const GET_REPO_NAME = gql`
  query RepoName_StepTwo($repoId: String!) {
    repo(id: $repoId) {
      id
      name
      type
    }
  }
`

const StepTwo = ({
  repoId,
  prevStep,
  nextStep,
  setDatabaseAccountInfo,
  initialSelectedConnectionDriver,
  selectedConnectionDriver,
  closeWizard,
  handleMutateServiceAccount,
  connectionDrivers,
}) => {
  const { isUpdating } = useAppsAndBiToolsState()
  const { user, getTokenSilently } = useAuth()
  const ec = new ExpressClient(getTokenSilently)
  const { license } = useAppConfig()
  const [modalOpen, setModalOpen] = React.useState(false)

  // Since these fields are all fetched in useRepositoryDetail, it simply queries from cache instead of server
  const { loading, error, data } = useQuery(GET_REPO_NAME, {
    variables: {
      repoId,
    },
  })

  const repoType = data?.repo?.type

  const {
    control,
    handleSubmit,
    errors,
    formState: { isSubmitting, isSubmitSuccessful },
    watch,
  } = useForm({
    defaultValues: {
      connectionDriverType: selectedConnectionDriver,
    },
  })

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // console.log('value: ', value)
      // console.log('name: ', name)
      // console.log('type: ', type)

      if (name === 'connectionDriverType') {
        setDatabaseAccountInfo(info => {
          return {
            ...info,
            connectionDriverType: value.connectionDriverType,
          }
        })
      }
    })

    return () => subscription.unsubscribe()
  }, [watch, setDatabaseAccountInfo])

  React.useEffect(() => {
    if (!isSubmitSuccessful) return
    if (selectedConnectionDriver !== 'custom') {
      nextStep()
      return
    }

    if (initialSelectedConnectionDriver === 'custom') {
      closeWizard()
    }
  }, [
    isSubmitSuccessful,
    nextStep,
    selectedConnectionDriver,
    initialSelectedConnectionDriver,
    closeWizard,
  ])

  const sendCustomConnectionDriverRequestedEmail = async () => {
    const body = {
      type: 'Customer',
      subtype: 'custom_connection_driver_requested',
      subject: 'A customer has requested a custom connection driver',
      contactName: user?.given_name || '',
      customerEmail: user?.email || '',
      companyName: license?.customer?.name || '',
      currentPlan: license?.userFriendlyName || '',
      details: {
        repoName: data?.repo?.name,
        repoType,
      },
    }

    try {
      await ec.post('/integrations/_sendNotification', body)
    } catch (e) {
      console.log(e)
      throw e
    }
  }

  const onSubmit = async ({ connectionDriverType }) => {
    if (connectionDriverType !== 'custom') return

    try {
      await handleMutateServiceAccount()

      if (initialSelectedConnectionDriver !== 'custom') {
        await sendCustomConnectionDriverRequestedEmail()
        setModalOpen(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <React.Fragment>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <Box>
            {/* <FormControl variant="standard" error={Boolean(errors?.username)}>
            <InputLabel htmlFor="database-account-username-input">
              Select a connection driver
            </InputLabel>
            <Input
              id="database-account-username-input"
              defaultValue=""
              inputProps={{
                ...register('connectionDriver', {
                  required: true,
                }),
              }}
            />
          </FormControl> */}

            <FormControl component="fieldset" fullWidth>
              {/* <FormControl variant="standard"> */}
              {/* <FormLabel id="demo-radio-buttons-group-label">Select a connection driver</FormLabel> */}
              {/* <InputLabel id="demo-radio-buttons-group-label">Select a connection driver</InputLabel> */}

              <Controller
                name="connectionDriverType"
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    aria-labelledby="demo-radio-buttons-group-label"
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(3, 1fr)',
                      gap: 2,
                    }}
                  >
                    {connectionDrivers.map(driver => {
                      const { label, value, avatar } = driver

                      return (
                        <ConnectionDriverCard
                          key={value}
                          value={value}
                          label={label}
                          avatar={avatar}
                          selectedConnectionDriver={selectedConnectionDriver}
                        />
                      )
                    })}
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Box>

          {selectedConnectionDriver === 'custom' && initialSelectedConnectionDriver !== 'custom' && (
            <Alert
              severity="info"
              sx={{
                color: 'primary.main',
                backgroundColor: 'cyralColors.primary.100',

                '& .MuiAlert-icon': {
                  color: 'inherit',
                },

                '& .MuiAlert-message': {
                  typography: 'h6',
                },
              }}
            >
              Cyral customer support will set up this custom connection driver.
            </Alert>
          )}

          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            <Button variant="outlined" onClick={prevStep}>
              Back
            </Button>
            <Button type="submit" loading={isSubmitting}>
              {selectedConnectionDriver !== 'custom'
                ? 'Next'
                : initialSelectedConnectionDriver !== 'custom'
                ? 'Contact Support'
                : 'Update'}
            </Button>
          </Stack>
        </Stack>
      </Box>
      <CustomConnectionDriverModal
        open={modalOpen}
        setModalOpen={setModalOpen}
        closeWizard={closeWizard}
      />
    </React.Fragment>
  )
}

// TODO: Look into the possibility of refactoring this to use <FormControlLabel />
const ConnectionDriverCard = ({ label, value, avatar, selectedConnectionDriver }) => {
  const isSelected = value === selectedConnectionDriver

  return (
    <Card
      component="label"
      variant="outlined"
      role="button"
      sx={{
        cursor: 'pointer',
        transition: theme =>
          theme.transitions.create(['border-color', 'background-color', 'box-shadow'], {
            duration: theme.transitions.duration.shorter,
          }),

        ...(isSelected && {
          borderColor: 'primary.main',
          boxShadow: theme => `${theme.palette.primary.main} 0 0 0 1px`,
        }),
      }}
    >
      <Radio
        value={value}
        inputProps={{
          'aria-label': 'A',
        }}
        sx={{
          opacity: 0,
          position: 'absolute',
        }}
      />
      <CardHeader
        // action={'action'}
        // subheader={'subheader'}
        avatar={avatar}
        title={
          <Typography variant="h4" sx={{ color: 'text.primary' }}>
            {label}
          </Typography>
        }
        disableTypography
        sx={{
          flexDirection: 'column',

          '& .MuiCardHeader-avatar': {
            marginRight: 0,
            marginBottom: 2,
          },
        }}
      />
    </Card>
  )
}

export default StepTwo
