/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Fragment, useEffect, useState } from 'react'
import moment from 'moment'
import { cloneDeep } from 'lodash'

import Loading from '@jeeves/components/Loading'
import { Paper } from '@jeeves/components/Primitives'
import Toolbar from '@jeeves/components/Toolbar'
import TimeSpanSelector from '../TimeSpanSelector'

import useAlerts from '../../hooks/useAlerts'
import { useAppConfig } from '@jeeves/hooks'
import Chart from './Chart'
import { getDocsURL } from '@jeeves/utils/docsURL'

const Graph = () => {
  const [chartData, setChartData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const { getMetrics, chartColors, timeSpan, setTimeSpan, alerts } = useAlerts()
  const { license } = useAppConfig()

  const fetchChartData = async () => {
    setLoading(true)
    try {
      const data = await getMetrics()
      const dataIsEmpty = !data || data.length === 0

      if (dataIsEmpty && !chartData) {
        setChartData(null)
      } else if (!dataIsEmpty) {
        setBubbleChartData(data)
      }
    } catch (e) {
      console.error(e)
      if (!chartData) {
        setError(true)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchChartData()
  }, [timeSpan, license]) // eslint-disable-line

  const getLabel = timestamp => {
    return moment(timestamp).local().format('h:mm A')
  }

  // TODO: create hook
  const allowPolicy =
    license.features && license.features.some(f => f.internalName === 'AllowPolicyMonitoring')

  const DISPLAY_LABELS = {
    authenticationFailure: 'Authentication Failure',
    ddl: 'DDL',
    dcl: 'DCL',
    dql: 'DQL',
    privileged_commands: 'Privileged Command',
  }
  if (allowPolicy) {
    DISPLAY_LABELS.policyViolation = 'Policy Violation'
  }

  const setBubbleChartData = dataFromAPI => {
    const bubbleChartData = cloneDeep(dataFromAPI)

    const labels = bubbleChartData.chartLabels.map(timestamp => getLabel(timestamp))

    setChartData({
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          ...bubbleChartData.data.map((result, i) => ({
            label: DISPLAY_LABELS[result.label] || result.label,
            data: result.values,
            hoverBackgroundColor: result.values.map(v => chartColors[i].hover),
            backgroundColor: result.values.map(v => chartColors[i].background),
            borderColor: result.values.map(v => chartColors[i].border),
            borderWidth: 2,
            barThickness: 75,
          })),
          ...Object.keys(DISPLAY_LABELS)
            .filter(label => !bubbleChartData.data.find(result => result.label === label))
            .map((label, index) => ({
              label: DISPLAY_LABELS[label],
              data: [0],
              hoverBackgroundColor: [chartColors[index + bubbleChartData.data.length].hover],
              backgroundColor: [chartColors[index + bubbleChartData.data.length].background],
              borderColor: [chartColors[index + bubbleChartData.data.length].border],
              borderWidth: 2,
            })),
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
        },
        layout: {
          padding: {
            right: 30,
            left: 10,
            top: 10,
            bottom: 10,
          },
        },
        animation: false,
        scales: {
          xAxes: [
            {
              // barPercentage: 1.0,
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: 'Time',
                fontStyle: 'bold',
                fontSize: 18,
              },
              ticks: {
                autoSkip: false,

                padding: 20,
              },
              gridLines: {
                drawOnChartArea: false,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: 'Count',
                fontSize: 18,
                fontStyle: 'bold',
              },
              ticks: {
                beginAtZero: true,
                stepSize: 1,
                suggestedMax: 5,
              },
              gridLines: {
                drawOnChartArea: false,
              },
            },
          ],
        },
      },
    })
  }

  return (
    <Fragment>
      <Paper css={{ marginBottom: '24px', minWidth: '850px' }}>
        <Toolbar
          title="Alerts"
          chip={alerts ? alerts.length : null}
          link={getDocsURL({ docsPath: 'reference/alerts' })}
        >
          <TimeSpanSelector timeSpan={timeSpan} setTimeSpan={setTimeSpan} />
        </Toolbar>
        <div
          css={() => css`
            margin-top: 60px;
            padding: 0 24px 24px 24px;
            position: relative;
            min-height: 100px;
          `}
        >
          <div
            css={() => css`
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
            `}
          ></div>

          {loading && <Loading />}
          {!loading && error && (
            <div
              css={theme => css`
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                font-family: ${theme.typography.fontFamily};
                /* font-size: ${theme.fontSize[3]}; */
              `}
            >
              <p>
                There was an error while loading the graph. Please try again or contact support.
              </p>
            </div>
          )}
          {!loading && !error && <Chart chartData={chartData} />}
        </div>
      </Paper>
    </Fragment>
  )
}

export default Graph
