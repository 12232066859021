/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useState } from 'react'
import axios from 'axios'
import moment from 'moment'

import {
  withStyles,
  MenuItem,
  Grid,
  TextField,
  Switch,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from '@material-ui/core'
import { ButtonLoading } from '@jeeves/components/Loading'
import { Button } from '@jeeves/components/Primitives'

import useInstructions from '@jeeves/pages/Wrappers/hooks/useInstructions'

const styles = theme => ({
  title: {
    padding: theme.spacing.unit * 3,
  },
  content: {
    marginBottom: theme.spacing.unit * 4,
  },
  deleteIcon: {
    fontSize: 28,
  },
  grow: {
    flexGrow: 1,
  },
  DialogTitle: {
    padding: theme.spacing.unit * 3,
  },
  DialogContent: {
    padding: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 5,
    paddingTop: 0,
  },
  DialogActions: {
    paddingBottom: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingTop: 0,
    backgroundColor: theme.palette.background.primary,
  },
  mt3: {
    marginTop: theme.spacing.unit * 3,
  },
  typography: {
    marginBottom: theme.spacing.unit,
  },
  vpcSwitch: {
    marginTop: theme.spacing.unit * 2,
  },
})

const AutomatedGen = ({ classes, currentDialog, setCurrentDialog }) => {
  const { AWSRegions } = useInstructions()
  const [awsRegion, setAwsRegion] = useState('')
  const [keyName, setKeyName] = useState('')
  const [vpc, setVpc] = useState('')
  const [sidecarName, setSidecarName] = useState('')
  const [publicSubnets, setPublicSubnets] = useState('')
  const [elkAddress, setElkAddress] = useState('')
  const [createVPC, setCreateVPC] = useState(true)
  const [submitting, setSubmitting] = useState(false)

  const handleOnClose = () => {
    setCurrentDialog('')
    clearFields()
  }

  const clearFields = () => {
    setAwsRegion('')
    setKeyName('')
    setVpc('')
    setSidecarName('')
    setPublicSubnets([])
    setElkAddress('')
  }

  const handleOnSubmit = () => {
    setSubmitting(true)
    const replaceTerm = window.location.origin.includes('https') ? 'https://' : 'http://'
    const controlPlane = window.location.origin.replace(replaceTerm, '')
    const vpcValue = createVPC ? 'cyral-default' : vpc
    const publicSubnetsValue = createVPC ? 'cyral-default' : publicSubnets // publicSubnetsArr

    const currentDate = moment().format('YYYYMMDD')
    const stackNameValue = `c${currentDate}-${sidecarName}`

    axios
      .request({
        method: 'POST',
        url: `/deploy/cft/`,
        data: {
          parameters: {
            AWSRegion: awsRegion,
            KeyName: keyName,
            VPC: vpcValue,
            SidecarName: sidecarName,
            ControlPlane: controlPlane,
            PublicSubnets: publicSubnetsValue,
            ELKAddress: elkAddress,
          },
          stackName: stackNameValue,
          awsSettings: { region: awsRegion },
        },
      })
      .then(() => {
        handleOnClose()
        setSubmitting(false)
      })
  }

  if (currentDialog !== 'automated') return null
  return (
    <Dialog fullWidth open={true} onClose={handleOnClose}>
      <Grid container>
        <Grid item xs>
          <DialogTitle>Deploy</DialogTitle>
        </Grid>
        <Grid item>
          <FormControlLabel
            className={classes.vpcSwitch}
            label="Use default VPC"
            control={
              <Switch
                // className={classes.vpcSwitch}
                onChange={() => setCreateVPC(!createVPC)}
                checked={createVPC}
                color="primary"
              />
            }
          />
        </Grid>
      </Grid>
      <DialogContent className={classes.content}>
        <Grid container spacing={24} className={classes.content}>
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              name="AwsRegion"
              value={awsRegion}
              label="AWS region"
              onChange={e => setAwsRegion(e.target.value)}
            >
              {AWSRegions.map(region => (
                <MenuItem key={region.value} value={region.value}>
                  {region.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="KeyName"
              value={keyName}
              label="AWS key pair name"
              onChange={e => setKeyName(e.target.value)}
            />
          </Grid>
          {!createVPC && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="Vpc"
                value={vpc}
                label="VPC ID"
                onChange={e => setVpc(e.target.value)}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              fullWidth
              name="SidecarName"
              value={sidecarName}
              label="Sidecar name"
              onChange={e => setSidecarName(e.target.value)}
            ></TextField>
          </Grid>
          {!createVPC && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="PublicSubnets"
                value={publicSubnets}
                label="Public subnet IDs"
                onChange={e => setPublicSubnets(e.target.value)}
              ></TextField>
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              fullWidth
              name="ElkAddress"
              value={elkAddress}
              onChange={e => setElkAddress(e.target.value)}
              label="Log location"
            ></TextField>
          </Grid>
        </Grid>

        <Grid container spacing={24}>
          <div className={classes.grow} />
          <Grid item>
            <Button color="primary" onClick={handleOnClose}>
              Cancel
            </Button>
          </Grid>

          <Grid item>
            <div css={{ position: 'relative' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOnSubmit}
                disabled={submitting}
              >
                Deploy
              </Button>
              {submitting && <ButtonLoading />}
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default withStyles(styles)(AutomatedGen)
