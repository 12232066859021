import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Box, Stack, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

import { graphql } from '@jeeves/graphql'
import { isSidecarOutdated } from '@jeeves/utils/comparation'

import {
  InstancesTable,
  InstancesHeader,
  EmptyState,
  SidecarVersionUpgradeCard,
} from './components'

const SIDECAR_INSTANCES = graphql(`
  query SidecarInstances($id: String!) {
    systemInfo {
      latestSidecarVersion
    }
    sidecar(id: $id) {
      id
      version
      instances {
        id
      }
      ...InstancesTable_SidecarFragment
      ...InstancesHeader_SidecarFragment
    }
    ...SidecarVersionUpgradeCard_QueryFragment
  }
`)

const Instances = () => {
  const { id } = useParams<{ id: string }>()

  const { data, loading, error } = useQuery(SIDECAR_INSTANCES, {
    variables: {
      id,
    },
    pollInterval: 15000,
  })

  const isSidecarVersionUpgradeAvailable = isSidecarOutdated({
    sidecarVersion: data?.sidecar.version || null,
    latestSidecarVersion: data?.systemInfo?.latestSidecarVersion || null,
  })

  const instances = data?.sidecar.instances ?? []

  if (loading) {
    return (
      <Box sx={{ p: 4 }}>
        <Stack spacing={2} sx={{ alignItems: 'center' }}>
          <CircularProgress size={64} />
          <Typography variant="h6" sx={{ color: 'text.secondary' }}>
            Loading Instances...
          </Typography>
        </Stack>
      </Box>
    )
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          Sorry, an error occurred while fetching instances
        </Typography>
      </Box>
    )
  }

  return (
    <Box sx={{ p: 4 }}>
      <Stack spacing={4}>
        {isSidecarVersionUpgradeAvailable && <SidecarVersionUpgradeCard query={data!} />}
        <Stack spacing={2}>
          <InstancesHeader sidecar={data!.sidecar} />
          <InstancesTable sidecar={data!.sidecar} />
          {instances.length === 0 && <EmptyState />}
        </Stack>
      </Stack>
    </Box>
  )
}

export default Instances
