import { useState, useEffect, useReducer } from 'react'
import axios from 'axios'

import { useAuth } from '../../components/Auth'

export const FETCH_INIT = 'FETCH_INIT'
export const FETCH_SUCCESS = 'FETCH_SUCCESS'
export const FETCH_FAILURE = 'FETCH_FAILURE'

const reducer = (state, action) => {
  switch (action.type) {
    case FETCH_INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
      }
    case FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      }
    default:
      throw new Error()
  }
}

const useFetch = (initialUrl, config, initialFetch = true) => {
  const [url, setUrl] = useState(initialUrl)
  const { getTokenSilently } = useAuth()

  const [state, dispatch] = useReducer(reducer, {
    isLoading: false,
    isError: false,
  })

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      dispatch({ type: FETCH_INIT })

      try {
        const token = await getTokenSilently()

        const result = await axios.request({
          ...config,
          url: initialUrl || config.url,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        if (!didCancel) {
          dispatch({ type: FETCH_SUCCESS, payload: result.data })
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: FETCH_FAILURE })
        }
      }
    }

    if (initialFetch) {
      fetchData()
    }

    return () => {
      didCancel = true
    }
  }, [url, config]) // eslint-disable-line

  return [state, setUrl]
}

export default useFetch

/** Usage Example */
/*
const ReactComponent = () => {
  const [{ data, isLoading, isError }, doFetch] = useFetch('URL')
}

export default ReactComponent
*/
