import { z } from 'zod'
import { y } from 'zod.discriminatedunion'
import { hostPortSchema } from '@jeeves/pages/RepositoryDetail/Edit/schemas'
import { REPO_METADATA, ALL_REPO_TYPES_WITH_DISPLAY_NAME } from '@jeeves/constants'

const baseSchema = z.object({
  repositoryName: z.string().min(1, { message: 'Please enter a repository name.' }),
})

const standardRepoSchema = baseSchema.merge(
  z
    .object({
      repositoryType: z.enum(
        ALL_REPO_TYPES_WITH_DISPLAY_NAME.map(({ typeName }) => typeName).filter(
          repoType => repoType !== REPO_METADATA.MONGODB.typeName
        )
      ),
    })
    .merge(hostPortSchema)
)

const mongoDBStandaloneRepoSchema = baseSchema.merge(
  z
    .object({
      repositoryType: z.enum([REPO_METADATA.MONGODB.typeName]),
      clusterType: z.enum(['standalone']),
    })
    .merge(hostPortSchema)
)

const mongoDBReplicaSetRepoSchema = () => {
  const mongoDBReplicaSetBaseSchema = baseSchema.merge(
    z.object({
      repositoryType: z.enum([REPO_METADATA.MONGODB.typeName]),
      clusterType: z.enum(['replicaSet']),
      replicaSetName: z.string().min(1, { message: 'Please enter a replica set name.' }),
      numNodes: z.number({
        required_error: 'Please enter the number of nodes.',
        invalid_type_error: 'Invalid number of nodes',
      }),
    })
  )

  const staticNodesSchema = mongoDBReplicaSetBaseSchema.merge(
    z.object({
      connectionFormat: z.enum(['StaticNodeSpecification']),
      nodes: z.array(hostPortSchema).min(1, {
        message: 'Please enter at least 1 host:port pair.',
      }),
    })
  )

  const SRVRecordSchema = mongoDBReplicaSetBaseSchema.merge(
    z.object({
      connectionFormat: z.enum(['SRVRecordSpecification']),
      SRVRecord: z.string().min(1, { message: 'Please enter the SRV record.' }),
    })
  )

  return y.discriminatedUnion('connectionFormat', [staticNodesSchema, SRVRecordSchema])
}

const mongoDBShardedClusterRepoSchema = () => {
  const mongoDBShardedClusterBaseSchema = baseSchema.merge(
    z.object({
      repositoryType: z.enum([REPO_METADATA.MONGODB.typeName]),
      clusterType: z.enum(['shardedCluster']),
      numNodes: z.number({
        required_error: 'Please enter the number of nodes.',
        invalid_type_error: 'Invalid number of nodes',
      }),
    })
  )

  const staticNodesSchema = mongoDBShardedClusterBaseSchema.merge(
    z.object({
      connectionFormat: z.enum(['StaticNodeSpecification']),
      nodes: z.array(hostPortSchema).min(1, {
        message: 'Please enter at least 1 host:port pair.',
      }),
    })
  )

  const SRVRecordSchema = mongoDBShardedClusterBaseSchema.merge(
    z.object({
      connectionFormat: z.enum(['SRVRecordSpecification']),
      SRVRecord: z.string().min(1, { message: 'Please enter the SRV record.' }),
    })
  )

  return y.discriminatedUnion('connectionFormat', [staticNodesSchema, SRVRecordSchema])
}

const mongoDBRepoSchema = y.discriminatedUnion('clusterType', [
  mongoDBStandaloneRepoSchema,
  mongoDBReplicaSetRepoSchema(),
  mongoDBShardedClusterRepoSchema(),
])

export const validationSchema = y.discriminatedUnion(
  'repositoryType',
  [standardRepoSchema, mongoDBRepoSchema],
  {
    errorMap: (error, ctx) => {
      switch (error.code) {
        case z.ZodIssueCode.invalid_union_discriminator:
          if (!ctx.data.repositoryType) {
            return { message: 'Please select a repository type.' }
          }
          break
        default:
          return { message: ctx.defaultError }
      }

      return { message: ctx.defaultError }
    },
  }
)
