/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useField } from 'formik'
import { TextField } from '@material-ui/core'

export const SidecarNameField = ({ ...props }) => {
  const parseName = e => (e.target.value || '').toLowerCase().trim()

  const [field, meta, helpers] = useField('sidecarName')
  const { error, touched } = meta

  return (
    <div css={theme => ({ marginBottom: theme.spacing[4] })}>
      <TextField
        fullWidth
        label="Sidecar Name"
        {...field}
        name="sidecarName"
        onChange={e => helpers.setValue(parseName(e))}
        error={!!error && touched}
        helperText={!!error && touched ? error : ''}
        {...props}
      />
    </div>
  )
}
