/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import styled from '@emotion/styled'
import { FormControl as muiFormControl, FormHelperText, InputLabel } from '@material-ui/core'

export const FormControl = styled(muiFormControl)`
  margin-bottom: ${({ theme }) => theme.spacing[4]};
`

export const FormField = ({ error, touched, label, helperText, children }) => (
  <FormControl error={Boolean(error) && touched} css={{ width: '100%', display: 'flex' }}>
    <InputLabel>{label}</InputLabel>
    {children}
    {!touched && <FormHelperText>{helperText}</FormHelperText>}
    {error && touched && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
)
