/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Paper } from '@jeeves/components/Primitives'
import Toolbar from '@jeeves/components/Toolbar'

import SidecarHeaderButton from '../SidecarHeaderButton/SidecarHeaderButton'
import { Box, ThemeProvider } from '@mui/material'
import { v5Theme } from '@jeeves/theme'

const SidecarError = () => (
  <Paper>
    <ThemeProvider theme={v5Theme}>
      <Box sx={{ px: 4, pt: 4 }}>
        <SidecarHeaderButton />
      </Box>
    </ThemeProvider>
    <Toolbar
      title={'There was an error while fetching the Sidecar'}
      css={() => css`
        padding-top: 0;
        margin-top: -8px;
      `}
    />
  </Paper>
)

export default SidecarError
