import React from 'react'
import {
  Collapse,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  FormHelperText,
} from '@material-ui/core'

// TO DO: this component should fetch the options

export default ({
  logsOptions,
  logIntegration = '',
  setLogIntegration = () => {},
  disabled = false,
  error = '',
  touched = false,
  ...props
}) => {
  return (
    <Tooltip title="Select a log location from one of your existing integrations" placement="top">
      <FormControl fullWidth error={!!error && touched}>
        <InputLabel htmlFor="log-integration">Log Integration</InputLabel>
        <Select
          value={logIntegration}
          onChange={e => setLogIntegration(e.target.value)}
          name="logIntegration"
          disabled={disabled}
          inputProps={{ id: 'log-integration' }}
          {...props}
        >
          {logsOptions.map(integration => (
            <MenuItem key={integration.value} value={integration.id}>
              {integration.label}
            </MenuItem>
          ))}
        </Select>
        {error && touched && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </Tooltip>
  )
}
