import { FormProvider } from 'react-hook-form'
import { Box, Stack, Typography } from '@mui/material'

import { Button } from '@jeeves/new-components'

import { useOperationalMode } from './useOperationalMode'

import { CertificateManagement, PassthroughMode } from './components'

const OperationalMode = ({ sidecar, onSave }) => {
  const { hasCustomCertificateBundleSecrets, methods, onSubmit } = useOperationalMode({
    sidecar,
    onSave,
  })

  const {
    formState: { isDirty, isSubmitting },
    handleSubmit,
    reset,
  } = methods

  const isDisabled = isSubmitting || !isDirty

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          border: '1px solid',
          borderColor: 'cyralColors.grey.200',
          p: 4,
          borderRadius: theme => theme.radii.base,
        }}
      >
        <Stack spacing={6}>
          <Typography variant="h3" sx={{ color: 'text.primary' }}>
            Operational Mode
          </Typography>

          <Stack spacing={4}>
            <PassthroughMode />

            {hasCustomCertificateBundleSecrets && (
              <CertificateManagement
                customCertificateBundleSecret={sidecar.certificateBundleSecrets.sidecar}
              />
            )}
          </Stack>

          <Stack direction="row" sx={{ justifyContent: 'flex-end' }} spacing={2}>
            <Button variant="text" disabled={isDisabled} onClick={() => reset()}>
              Cancel
            </Button>
            <Button type="submit" disabled={isDisabled} loading={isSubmitting}>
              Save
            </Button>
          </Stack>
        </Stack>
      </Box>
    </FormProvider>
  )
}

export default OperationalMode
