/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import { Fragment } from 'react'
import { grey } from '@material-ui/core/colors'
import { DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'

import { ButtonLoading } from '@jeeves/components/Loading'
import { Button, Dialog } from '@jeeves/components/Primitives'

export const SidecarDialogActions = styled(DialogActions)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.spacing[4]};
`

export const SidecarDialog = ({ title, subtitle = null, onClose, open, children, ...props }) => (
  <Dialog fullWidth open={open} onClose={onClose} maxWidth={'sm'} {...props}>
    {subtitle ? (
      <DialogTitle disableTypography>
        <Typography
          variant="title"
          css={t => css`
            font-size: ${t.fontSize[6]};
          `}
        >
          {title}
        </Typography>
        <Typography variant="subtitle1" css={{ color: grey[600] }}>
          {subtitle}
        </Typography>
      </DialogTitle>
    ) : (
      <DialogTitle>{title}</DialogTitle>
    )}

    <DialogContent>{children}</DialogContent>
  </Dialog>
)

export const SidecarGenerateActions = ({
  disabled,
  handleOnClose,
  isGenerated,
  isSubmitting = false,
  onGenerate = null,
  buttonMessage = "Generate"
}) => (
  <SidecarDialogActions spacing={24}>
    {isGenerated ? (
      <Button color="primary" onClick={handleOnClose}>
        Done
      </Button>
    ) : (
      <Fragment>
        <Button
          color="primary"
          onClick={handleOnClose}
          disabled={isSubmitting}
          css={{ marginRight: '16px' }}
        >
          Cancel
        </Button>
        <div css={{ position: 'relative' }}>
          {/* TO DO: Use of submit type from formik and onClick callback very confusing */}
          <Button
            variant="contained"
            color="primary"
            type={onGenerate ? 'button' : 'submit'}
            onClick={() => (onGenerate ? onGenerate() : null)}
            disabled={isSubmitting || disabled}
          >
            {buttonMessage}
          </Button>
          {isSubmitting && <ButtonLoading />}
        </div>
      </Fragment>
    )}
  </SidecarDialogActions>
)
