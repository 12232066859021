import React, { useState, useRef } from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Popper from '@mui/material/Popper'
import Fade from '@mui/material/Fade'
import ClickAwayListener from '@mui/material/ClickAwayListener'

import { Button } from '@jeeves/new-components'

import AccessToken from './components/AccessToken'

const PopoverContentWrapper = styled(
  Box,
  {}
)(({ theme }) => ({
  //   overflow: 'hidden',
  marginTop: theme.spacing(1),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.cyralColors.grey[200]}`,
  borderRadius: theme.radii.base,
  maxWidth: '475px',
  boxShadow: theme.cyralShadows['2dp'],
  //   height: '100%',
}))

const Popover = ({ isOpen, handleClose, anchorRef, opaqueToken }) => {
  const [showAccessToken, setShowAccessToken] = useState(false)

  const handleToggle = () => {
    setShowAccessToken(currShowAccessToken => !currShowAccessToken)
  }

  return (
    <Popper open={isOpen} anchorEl={anchorRef.current} placement="bottom-end" transition>
      {({ TransitionProps }) => (
        <Fade
          {...TransitionProps}
          timeout={350}
          onExited={() => setShowAccessToken(false)}
          unmountOnExit
        >
          <PopoverContentWrapper>
            <ClickAwayListener onClickAway={handleClose}>
              <Stack divider={<Divider orientation="horizontal" flexItem />} spacing={2}>
                <AccessToken
                  opaqueToken={opaqueToken}
                  showAccessToken={showAccessToken}
                  handleToggle={handleToggle}
                />
              </Stack>
            </ClickAwayListener>
          </PopoverContentWrapper>
        </Fade>
      )}
    </Popper>
  )
}

const AdvancedPopover = ({
  opaqueToken,
}) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    // Prevents selects in 'Request Access' section from closing popover
    if (event?.target?.localName === 'body') {
      event.preventDefault()
      return
    }

    if (anchorRef.current?.contains(event?.target)) return

    setOpen(false)
  }

  return (
    <>
      <Button
        variant={open ? 'contained' : 'outlined'}
        onClick={handleToggle}
        type="button"
        ref={anchorRef}
        color="secondary"
      >
        Advanced
      </Button>
      <Popover
        isOpen={open}
        anchorRef={anchorRef}
        handleClose={handleClose}
        opaqueToken={opaqueToken}
      />
    </>
  )
}

export default AdvancedPopover
