/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import React, { useState } from 'react'
import { useParams, useRouteMatch, Switch, Route, Redirect, Link } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { useQuery, gql, NetworkStatus } from '@apollo/client'

import useInterval from '@jeeves/hooks/useInterval'
import Loading from '@jeeves/components/Loading'
import { Paper } from '@jeeves/components/Primitives'
import useWrappers from '@jeeves/pages/Wrappers/hooks/useWrappers'
import { WrappersProvider } from '@jeeves/pages/Wrappers/contexts/WrappersContext'

import { IntermediateLoading } from '@jeeves/components/Loading'
import { SidecarDetailProvider } from './contexts/SidecarDetailContext'
import { v5Theme } from '@jeeves/theme'

import { Repositories, RepositoriesProvider } from './tabs'
import {
  Advanced,
  Deployment,
  Instances,
  SidecarError,
  SidecarHeader,
  Tab,
  Tabs,
} from './components'

const SIDECAR_DETAILS = gql`
  query SidecarDetails($sidecarId: String!) {
    ...AdvancedTab_query
    ...Repositories_query
    ...SidecarHeader_query
  }
  ${Repositories.fragments.Repositories_queryFragment}
`

const tabs = [
  {
    label: 'Instances',
    value: 'instances',
  },
  {
    label: 'Bindings',
    value: 'bindings',
  },
  {
    label: 'Deployment',
    value: 'deployment',
  },
  {
    label: 'Settings',
    value: 'settings',
  },
]

const WrapperDetail = () => {
  const { id } = useParams()
  const {
    data: sidecarDetailsData,
    loading: sidecarDetailsLoading,
    error: sidecarDetailsError,
    fetchMore,
    refetch,
    networkStatus,
  } = useQuery(SIDECAR_DETAILS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      sidecarId: id,
    },
  })

  const [wrapper, setWrapper] = useState(null)
  const [loading, setLoading] = useState(true)
  const [intermediateLoading, setIntermediateLoading] = useState(false)
  const [inFlight, setInFlight] = useState(false)
  const [error, setError] = useState(false)
  const { path, url } = useRouteMatch()
  const routeMatch = useRouteMatch(tabs.map(tab => `${path}/${tab.value}`))

  const { ec } = useWrappers()

  const fetchData = async (silently = false) => {
    if (!silently) {
      if (!wrapper) {
        setLoading(true)
      } else {
        setIntermediateLoading(true)
      }
    }
    setInFlight(true)
    try {
      const result = await ec.get(`/sidecars/${id}/details`)
      if (result && result.data && result.data !== {}) {
        setWrapper(result.data)
        setError(false)
      }
    } catch (e) {
      setError(true)
    } finally {
      setLoading(false)
      setIntermediateLoading(false)
      setInFlight(false)
    }
  }

  useInterval(async () => {
    if (!inFlight) {
      fetchData(true)
    }
  }, 30000)

  if (loading || networkStatus === NetworkStatus.loading) {
    return (
      <Paper css={{ minHeight: '80px' }}>
        <Loading />
      </Paper>
    )
  }

  if (error || sidecarDetailsError) {
    return <SidecarError />
  }

  return (
    <SidecarDetailProvider sidecarId={id}>
      <Paper>
        <IntermediateLoading loading={intermediateLoading} />

        <ThemeProvider theme={v5Theme}>
          <SidecarHeader query={sidecarDetailsData} />

          <Tabs value={routeMatch?.url}>
            {tabs.map(tab => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={`${url}/${tab.value}`}
                component={Link}
                to={`${url}/${tab.value}`}
              />
            ))}
          </Tabs>

          <Switch>
            <Route path={`${path}/instances`}>
              <Instances />
            </Route>

            <Route path={`${path}/bindings`}>
              <RepositoriesProvider
                fetchMore={fetchMore}
                refetch={refetch}
                networkStatus={networkStatus}
              >
                <Repositories query={sidecarDetailsData} />
              </RepositoriesProvider>
            </Route>

            <Route path={`${path}/settings`}>
              {wrapper && (
                <Advanced sidecar={wrapper} query={sidecarDetailsData} onSave={() => fetchData()} />
              )}
            </Route>

            <Route path={`${path}/deployment`}>
              <Deployment />
            </Route>

            <Redirect to={`${path}/${tabs?.[0].value}`} />
          </Switch>
        </ThemeProvider>
      </Paper>
    </SidecarDetailProvider>
  )
}

export default () => (
  <WrappersProvider>
    <WrapperDetail />
  </WrappersProvider>
)
