import React from 'react'
import { Tooltip } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import ErrorIcon from '@material-ui/icons/Error'
import WarningIcon from '@material-ui/icons/Warning'

import { red, green, yellow, grey } from '@material-ui/core/colors'

const Offline = ({ isInstance }) => (
  <Tooltip title={isInstance ? 'Instance is unhealthy' : 'Sidecar is unhealthy'}>
    <ErrorIcon style={{ color: red[500] }}></ErrorIcon>
  </Tooltip>
)

const Warning = ({ isInstance }) => (
  <Tooltip title={isInstance ? 'Some services are down' : 'Some instances are degraded'}>
    <WarningIcon style={{ color: yellow[700] }}></WarningIcon>
  </Tooltip>
)

const Unknown = ({ isInstance }) => (
  <Tooltip
    title={isInstance ? 'No health metrics found for this instance' : 'Sidecar has not registered'}
  >
    <RemoveCircleIcon style={{ color: grey[500] }}></RemoveCircleIcon>
  </Tooltip>
)

const Online = ({ isInstance }) => (
  <Tooltip title={isInstance ? 'All services are healthy' : 'All instances are healthy'}>
    <CheckCircleIcon style={{ color: green[500] }}></CheckCircleIcon>
  </Tooltip>
)

export { Offline, Unknown, Warning, Online }
