/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import styled from '@emotion/styled'
import { useState, useEffect } from 'react'
import { TextField } from '@material-ui/core'

import { LogsIntegration, MetricsIntegration, VaultIntegration } from '../IntegrationInputs'
import useInstructions from '../../../hooks/useInstructions'
import { DownloadLink, SidecarDialog, SidecarGenerateActions } from '../index'
import useWrappers from '@jeeves/pages/Wrappers/hooks/useWrappers'
import { useAuth } from '@jeeves/hooks'
import ExpressClient from '@jeeves/clients/express'
import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'
import { useAccounts } from '@jeeves/pages/WrapperDetail/components/Accounts/useAccounts'
import { chartRepo, chartVersion } from '../'

// This will be abstracted out when we better abstract out sidecar deployment
const InputWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[4]};
  min-height: 68px;
`

export const Helm3GenContents = ({
  newTemplate,
  alreadyCreated,
  saveProperties,
  onSave,
  clone,
  setCloned,
  setFinished,
  open,
  onClose,
  onGenerated,
  logsOptions,
  metricsOptions,
  sidecarName: argsSidecarName,
  logIntegrationID: argsLogIntegrationID,
  metricsIntegrationID: argsMetricsIntegrationID,
  vaultIntegrationID: argsVaultIntegrationID,
  sidecarId: argsSidecarId,
  repos,
  suggestedSidecarName,
}) => {
  const [generateEnabled, setGenerateEnabled] = useState(true)
  const [sidecarName, setSidecarName] = useState(
    suggestedSidecarName || (alreadyCreated ? argsSidecarName : '')
  )
  const [elkAddress, setElkAddress] = useState('')
  const [downloadLink, setDownloadLink] = useState('')
  const [isGenerated, setIsGenerated] = useState('')
  const [logIntegration, setLogIntegration] = useState(
    argsLogIntegrationID || (alreadyCreated && !newTemplate ? 'default' : '')
  )
  const [metricsIntegration, setMetricsIntegration] = useState(
    argsMetricsIntegrationID || (alreadyCreated && !newTemplate ? 'default' : '')
  )
  const [vaultIntegration, setVaultIntegration] = useState(argsVaultIntegrationID || 'None')
  const [generating, setGenerating] = useState(false)
  const { wrapperHandlers, repoHandlers } = useWrappers()
  const [sidecarNames, setSidecarNames] = useState([])
  const { user, getTokenSilently } = useAuth()
  const ec = new ExpressClient(getTokenSilently)
  const { showError } = usePopup()
  const { createSidecarAccount } = useAccounts()

  const { handleSetPopup, popupTypes, generateId, createSidecar, cloneSidecar } = useInstructions()

  const handleOnClose = () => {
    onClose()
    if (isGenerated && clone && setCloned) setCloned(true)
    // Save everytime because client id, secret is being generated
    if (isGenerated && onSave) onSave()
    clearFields()
    setIsGenerated(false)
  }

  const clearFields = () => {
    setSidecarName('')
    setElkAddress('')
    setDownloadLink('')
    setLogIntegration('')
    setMetricsIntegration('')
    setVaultIntegration('None')
  }

  const downloadFile = content => {
    const element = document.createElement('a')
    // eslint-disable-next-line
    const file = new Blob([content], { type: 'application/x-yaml' })
    element.href = URL.createObjectURL(file)
    element.download = `${sidecarName}.yaml`
    document.body.appendChild(element)
    element.click()
  }

  const sidecarProperties = {
    deploymentMethod: 'helm',
    logIntegrationID: logIntegration,
    metricsIntegrationID: metricsIntegration,
    vaultIntegrationID: vaultIntegration,
  }

  const handleOnSubmit = async () => {
    let sidecar = {}
    setGenerateEnabled(false)
    setGenerating(true)
    try {
      if (alreadyCreated) {
        if (clone) {
          const newSidecarResponse = await cloneSidecar({
            sidecarId: argsSidecarId,
            input: {
              name: sidecarName,
              ...sidecarProperties
            }
          })
          sidecar.ID = newSidecarResponse.sidecar.id
          sidecar.clientID = newSidecarResponse.sidecarAccount.clientId
          sidecar.clientSecret = newSidecarResponse.sidecarAccount.clientSecret
        } else {
          sidecar.ID = argsSidecarId
          const sidecarAccount = await createSidecarAccount(sidecar.ID)
          sidecar.clientID = sidecarAccount.clientId
          sidecar.clientSecret = sidecarAccount.clientSecret
        }
        if (saveProperties) {
          await ec.put(`/sidecars/${argsSidecarId}`, {
            properties: sidecarProperties,
          })
        }
      } else {
        sidecar = await createSidecar(sidecarName, sidecarProperties)
      }
      const namePrefix = `cyral-${sidecar.ID.substr(sidecar.ID.length - 6).toLowerCase()}`
      // TO DO: correct name of state variable can make this variable name better
      const logIntegrationObject = logsOptions.find(logsOption => logsOption.id === logIntegration)
      const metricsIntegrationObject = metricsOptions.find(
        metricsOption => metricsOption.id === metricsIntegration
      )
      const values = await ec
        .request({
          method: 'GET',
          timeout: 60000,
          url: `/deploy/helm/values.yaml`,
          params: {
            sidecarId: sidecar.ID,
            ELKAddress: elkAddress,
            ELKUsername: logIntegrationObject.elkUsername,
            ELKPassword: logIntegrationObject.elkPassword,
            logIntegrationType: logIntegrationObject.type,
            logIntegrationValue: logIntegrationObject.value,
            metricsIntegrationType: metricsIntegrationObject.type,
            metricsIntegrationValue: metricsIntegrationObject.value,
            HCVaultIntegrationID: vaultIntegration === 'None' ? '' : vaultIntegration,
            useTLS: logIntegrationObject.useTLS,
            useMutualAuthentication: logIntegrationObject.useMutualAuthentication,
            usePrivateCertificateChain: logIntegrationObject.usePrivateCertificateChain,
            SumologicHost: logIntegrationObject.host,
            SumologicUri: logIntegrationObject.uri,
            clientId: sidecar.clientID,
            clientSecret: sidecar.clientSecret,
          },
        })
        .then(res => res.data)
      await downloadFile(values)

      const link = `kubectl create namespace ${namePrefix}

helm upgrade -i ${namePrefix} cyral-sidecar --namespace ${namePrefix} -f ${sidecarName}.yaml --repo ${chartRepo} --version ${chartVersion}`
      setDownloadLink(link)
      if (setFinished) setFinished(true)
      setIsGenerated(true)
    } catch (e) {
      let popupMessage = e.message
      if (e.error && e.error.trim() === 'login_required') {
        popupMessage = 'Third party cookies must be enabled'
      }
      handleSetPopup(popupTypes.ERROR, popupMessage)
    } finally {
      setGenerating(false)
    }
    setGenerateEnabled(true)
  }

  useEffect(() => {
    if (!open) handleOnClose()
  }, [open])

  return (
    <div>
      {(!alreadyCreated || (alreadyCreated && clone)) && (
        <InputWrapper>
          <TextField
            fullWidth
            name="SidecarName"
            value={sidecarName}
            label="Sidecar name"
            onChange={e => setSidecarName(e.target.value)}
            disabled={isGenerated}
          />
        </InputWrapper>
      )}
      <InputWrapper>
        <LogsIntegration
          logIntegration={logIntegration}
          setLogIntegration={setLogIntegration}
          logsOptions={logsOptions}
          disabled={isGenerated}
        />
      </InputWrapper>
      <InputWrapper>
        <MetricsIntegration
          metricsIntegration={metricsIntegration}
          setMetricsIntegration={setMetricsIntegration}
          metricsOptions={metricsOptions}
          disabled={isGenerated}
        />
      </InputWrapper>
      <InputWrapper>
        <VaultIntegration
          vaultIntegrationID={vaultIntegration}
          setVaultIntegrationID={setVaultIntegration}
        />
      </InputWrapper>

      {downloadLink && <DownloadLink downloadLink={downloadLink} />}

      <SidecarGenerateActions
        disabled={!generateEnabled}
        isGenerated={isGenerated}
        handleOnClose={handleOnClose}
        isSubmitting={generating}
        onGenerate={handleOnSubmit}
      />
    </div>
  )
}

const Helm3Gen = ({ currentDialog, setCurrentDialog, ...props }) => {
  const [open, setOpen] = useState(currentDialog === 'helm')

  useEffect(() => {
    setOpen(currentDialog === 'helm')
  }, [currentDialog])

  const handleClose = () => {
    setCurrentDialog('')
  }

  return (
    <SidecarDialog open={open} onClose={handleClose} title="Generate installation command">
      <Helm3GenContents open={open} onClose={handleClose} {...props}></Helm3GenContents>
    </SidecarDialog>
  )
}

export default Helm3Gen
