import { ReactElement } from 'react'

import { Typography } from '@mui/material'
import {
  ArrowCircleUpRounded,
  CheckCircleOutlineRounded,
  InfoOutlined,
  RemoveCircleOutline,
  WarningAmberRounded,
  Autorenew,
} from '@mui/icons-material'

import { SidecarHealth } from '@jeeves/graphql/graphql'
import { Button, Tooltip } from '@jeeves/new-components'
import { getRelativeTime } from '@jeeves/utils/duration'
import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { instanceCanUpgrade } from '../../helpers'

const InstancesRow_SidecarFragment = graphql(`
  fragment InstancesRow_SidecarFragment on Sidecar {
    id
    version
  }
`)

const InstancesRow_SidecarInstanceFragment = graphql(`
  fragment InstancesRow_SidecarInstanceFragment on SidecarInstance {
    id
    metrics {
      activeConnections
      queriesPerSecond
    }
    startTime
    version
    recyclable
    versionIsDynamic
    health
    isRecycling
  }
`)

const variantIcons = {
  [SidecarHealth.Healthy]: <CheckCircleOutlineRounded sx={{ color: 'cyralColors.green.300' }} />,
  [SidecarHealth.Unhealthy]: <InfoOutlined sx={{ color: 'error.main' }} />,
  [SidecarHealth.Degraded]: <WarningAmberRounded sx={{ color: 'cyralColors.yellow.300' }} />,
  [SidecarHealth.Unknown]: <RemoveCircleOutline sx={{ color: 'cyralColors.grey.300' }} />,
} as const

interface Wrapper {
  condition: boolean
  wrapper: (children: ReactElement) => ReactElement
  children: ReactElement
}

interface Props {
  sidecarInstance: FragmentType<typeof InstancesRow_SidecarInstanceFragment>
  sidecar: FragmentType<typeof InstancesRow_SidecarFragment>
  setSelectedInstanceToUpgrade: React.Dispatch<React.SetStateAction<string>>
}

const ConditionalWrapper = ({ condition, wrapper, children }: Wrapper) =>
  condition ? wrapper(children) : children

const InstancesRow = ({
  sidecarInstance: sidecarInstanceProp,
  sidecar: sidecarProp,
  setSelectedInstanceToUpgrade,
}: Props) => {
  const instance = useFragment(InstancesRow_SidecarInstanceFragment, sidecarInstanceProp)
  const sidecar = useFragment(InstancesRow_SidecarFragment, sidecarProp)

  const { startTime, id, version, metrics, recyclable, versionIsDynamic, health, isRecycling } =
    instance
  const sidecarVersion = sidecar.version
  const isUpgradeDisabled = !instanceCanUpgrade({ instance, sidecarVersion })
  const icon = variantIcons[health]

  const getTooltipMessage = () => {
    if (isRecycling) {
      return 'Instance is being upgraded'
    }

    if (version === sidecarVersion) {
      return 'Instance is up to date'
    }

    if (!versionIsDynamic) {
      return 'Version set via deployment'
    }

    if (!recyclable) {
      return 'Feature not supported'
    }
  }

  return (
    <tr>
      <td>{icon}</td>
      <td>
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          {id}
        </Typography>
      </td>
      <td>
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          {version}
        </Typography>
      </td>
      <td>
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          {getRelativeTime(startTime)}
        </Typography>
      </td>
      <td>
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          {metrics.queriesPerSecond}
        </Typography>
      </td>
      <td>
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          {metrics.activeConnections}
        </Typography>
      </td>
      <td>
        <ConditionalWrapper
          condition={isUpgradeDisabled}
          wrapper={children => (
            <Tooltip title={getTooltipMessage()} placement="bottom-end">
              <div>{children}</div>
            </Tooltip>
          )}
        >
          <Button
            disabled={isUpgradeDisabled}
            variant="outlined"
            color="secondary"
            sx={{ minWidth: 0, padding: '7px !important' }}
            onClick={() => setSelectedInstanceToUpgrade(id)}
          >
            {isRecycling ? (
              <Autorenew
                fontSize="small"
                sx={{
                  animation: 'spin-ccw 2s linear infinite',
                  '@keyframes spin-ccw': {
                    '0%': {
                      transform: 'rotate(0deg)',
                    },
                    '100%': {
                      transform: 'rotate(360deg)',
                    },
                  },
                }}
              />
            ) : (
              <ArrowCircleUpRounded fontSize="small" />
            )}
          </Button>
        </ConditionalWrapper>
      </td>
    </tr>
  )
}

export default InstancesRow
