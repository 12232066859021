import React from 'react'

import { InlineCode } from '@jeeves/new-components'
import { getRedactedPassword, createEscapedConnectionString } from '../utils'

import { Compass } from './components/Compass'
import { Studio3T } from './components/Studio3T'

const getConnectionTypes = (connectionInfo, options) => {
  const { showPassword } = options

  return [
    {
      label: 'Mongo',
      value: 'mongo',
      content: (
        <InlineCode sx={{ backgroundColor: 'common.white' }} noWrap>
          {getMongoShellCommand({
            connectionInfo,
            options: {
              showPassword,
            },
          })}
        </InlineCode>
      ),
      copyValue: getMongoShellCommand({
        connectionInfo,
      }),
    },
    {
      label: 'Mongosh',
      value: 'mongosh',
      content: (
        <InlineCode sx={{ backgroundColor: 'common.white' }} noWrap>
          {getMongoshCommand({
            connectionInfo,
            options: {
              showPassword,
            },
          })}
        </InlineCode>
      ),
      copyValue: getMongoshCommand({
        connectionInfo,
      }),
    },
    {
      label: 'Compass',
      value: 'compass',
      content: (
        <Compass
          connectionUri={getGeneralMongoUri({
            connectionInfo,
            options: {
              escaped: true,
            },
          })}
        />
      ),
      copyValue: null,
    },
    {
      label: 'Studio3T',
      value: 'studio3T',
      content: (
        <Studio3T
          connectionUri={getGeneralMongoUri({
            connectionInfo,
            options: {
              escaped: true,
            },
          })}
        />
      ),
      copyValue: null,
    },
  ]
}

const getMongoShellCommand = ({ connectionInfo, options }) => {
  const generalUri = getGeneralMongoUri({ connectionInfo, options })

  return `mongo "${generalUri}"`
}

const getMongoshCommand = ({ connectionInfo, options }) => {
  const generalUri = getGeneralMongoUri({ connectionInfo, options })

  return `mongosh "${generalUri}"`
}

const getGeneralMongoUri = ({ connectionInfo, options = {} }) => {
  const { password, replicaSetName, ...rest } = connectionInfo
  const { showPassword = true } = options

  return createEscapedConnectionString({
    scheme: 'mongodb',
    connectionInfo: {
      ...rest,
      password: showPassword ? password : getRedactedPassword(password),
    },
    options: {
      authMechanism: 'PLAIN',
      ...(replicaSetName && { replicaSet: replicaSetName }),
    },
  })
}

export default getConnectionTypes
