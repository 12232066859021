import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { useLocation } from 'react-router-dom'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'

import { ThemeProvider } from '@mui/material/styles'
import { v5Theme } from '@jeeves/theme'
import { SearchInput } from '@jeeves/new-components'

import CopyAccessToken from './components/CopyAccessToken'
import AdvancedPopover from './components/AdvancedPopover'
import { ExpandableDataRepoCard, GeneralDataRepoCard } from './components/RepoCards'
import useAccessPortal from './hooks/useAccessPortal'

import { Button } from '@jeeves/new-components'
import { RequestAccess, Search, FilterPopover, EmptyStateGraphic } from './new-components'

import lodash from 'lodash'

const AccessPortal = () => {
  const { search } = useLocation()
  const { firstLoading, error, data, getNextPage, refetch, fetchingMore } = useAccessPortal({
    variables: { first: 10, accessibility: 'HAS_ACCESS' },
    notifyOnNetworkStatusChange: true,
  })

  const [searchValue, setSearchValue] = useState('')
  const [filters, setFilters] = useState({
    repoTypes: [],
    repoTags: [],
  })

  const submitSearch = useCallback(
    lodash.debounce(e => {
      refetch({
        filters: {
          repoName: e.target.value,
          ...filters,
        },
      })
    }, 300),
    [filters]
  )

  const handleChange = e => {
    setSearchValue(e.target.value)
    submitSearch(e)
  }

  const handleFilter = ({ repoType, repoTags }) => {
    setFilters({ repoTypes: repoType ? [repoType] : [], repoTags })
    refetch({
      filters: {
        ...(searchValue ? { repoName: searchValue } : {}),
        repoTypes: repoType ? [repoType] : [],
        repoTags,
      },
    })
  }

  if (firstLoading) return 'Loading...'
  if (error) return `Error! ${error.message}`

  const isMinimized = new URLSearchParams(search).get('minimized') === 'true'

  return isMinimized ? (
    // TODO: Create a MUI v5 version of <CopyAccessToken />
    <CopyAccessToken
      opaqueToken={data.opaqueToken}
      paperStyles={{ backgroundColor: 'transparent', margin: 0 }}
    />
  ) : (
    <ThemeProvider theme={v5Theme}>
      <Container maxWidth="xl">
        <Stack spacing={4}>
          <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Stack spacing={1} direction="row" alignItems="center">
              <Typography variant="h2" sx={{ color: 'text.primary' }}>
                Your Data Repositories
              </Typography>
              {/* <InfoOutlinedIcon
                  fontSize="small"
                  sx={{
                    color: 'cyralColors.grey.200',
                    // Used to achieve optical alignment to fit Figma design
                    transform: 'translateY(3.5px)',
                  }}
                /> */}
            </Stack>
          </Stack>
          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            <Stack direction="row" spacing={2}>
              <SearchInput
                value={searchValue}
                onChange={handleChange}
                placeholder="Search by repository name"
                sx={{ minWidth: '300px' }}
              />
              <FilterPopover handleFilter={handleFilter} filters={filters}></FilterPopover>
            </Stack>

            <Stack direction="row" spacing={2}>
              <AdvancedPopover opaqueToken={data.opaqueToken} />
              {data.repoEdgesUserCanAccess?.length > 0 && (
                <RequestAccess tooltipPlacement="bottom-end" />
              )}
            </Stack>
          </Stack>
          {data.repoEdgesUserCanAccess?.length > 0 ? (
            <Stack spacing={3}>
              {data.repoEdgesUserCanAccess.map(edge => {
                switch (edge.node.type) {
                  case 'postgresql':
                  case 'redshift':
                  case 'mongodb':
                  case 'mariadb':
                  case 'oracle':
                  case 'sqlserver':
                  case 'denodo':
                  case 'dremio':
                  case 'dynamodb':
                  case 'dynamodbstreams':
                  case 'mysql':
                    return (
                      <GeneralDataRepoCard
                        key={edge.node.id}
                        repoEdge={edge}
                        opaqueToken={data.opaqueToken}
                      />
                    )
                  case 's3':
                    return <ExpandableDataRepoCard key={edge.node.id} repoEdge={edge} />
                  default:
                    return null
                }
              })}
            </Stack>
          ) : (
            <Box paddingTop="150px">
              <EmptyStateGraphic />
            </Box>
          )}
          {data?.hasNextPage && (
            <Button
              variant="outlined"
              color="secondary"
              sx={{ alignSelf: 'center' }}
              onClick={getNextPage}
              loading={fetchingMore}
            >
              Load More
            </Button>
          )}
        </Stack>
      </Container>
    </ThemeProvider>
  )
}

export default AccessPortal
