import React from 'react'
import { MenuItem, TextField, Tooltip } from '@material-ui/core'

// TO DO: this component should fetch the options

export default ({
  metricsOptions = [{ type: '', value: '', label: 'None' }],
  metricsIntegration = '',
  setMetricsIntegration = () => {},
  disabled = false,
  error = '',
  touched = false,
  ...props
}) => (
  <Tooltip title="Select a metrics location from one of your existing integrations" placement="top">
    <TextField
      select
      fullWidth
      name="metricsIntegration"
      value={metricsIntegration}
      onChange={e => setMetricsIntegration(e.target.value)}
      label="Metrics Integration"
      disabled={disabled}
      {...props}
      error={!!error && touched}
      helperText={error && touched ? error : null}
    >
      {metricsOptions.map(integration => (
        <MenuItem key={integration.value} value={integration.id}>
          {integration.label}
        </MenuItem>
      ))}
    </TextField>
  </Tooltip>
)
