/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import { Link, useParams, useRouteMatch, Switch, Redirect, Route } from 'react-router-dom'
import React from 'react'
import { NetworkStatus } from '@apollo/client'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { ThemeProvider } from '@mui/material/styles'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import Loading from '@jeeves/components/Loading'
import { Paper, Tab, Tabs } from '@jeeves/components/Primitives'

import {
  Approvals,
  AppsAndBiTools,
  Datamap,
  UserAuthentication,
  NetworkShield,
  Config,
  Overview,
  Policies,
  AuditReport,
} from './Tabs'
import useRepositoryDetail from './hooks/useRepositoryDetail'
import { RepositoryDetailProvider } from './contexts/RepositoryDetailContext'
import getTabs from './helpers/getTabs'
import { Breadcrumbs, RepositoryError, RepositoryHeader } from '.'
import { ReposProvider } from '../Repositories/contexts/ReposContext'
import { v5Theme } from '@jeeves/theme'
import { useEntitlements } from '@jeeves/hooks'

const TabContainer = styled.div`
  overflow-x: auto;
  padding: 24px 0;
`

const RepositoryLoading = () => (
  <Paper
    css={() => css`
      min-height: 80px;
    `}
  >
    <Loading />
  </Paper>
)

export const RepositoryDetail = () => {
  const { id } = useParams()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const dataMapMenuRef = React.useRef()
  const open = Boolean(anchorEl)

  const handleOpenSettings = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseSettings = () => {
    setAnchorEl(null)
  }

  const {
    loading: repositoryDetailLoading,
    error: repositoryDetailError,
    data: repositoryDetailData,
    refetch,
    networkStatus,
  } = useRepositoryDetail({
    variables: {
      repoId: id,
      workerRunsQuery: {
        modes: ['ACCOUNT_DISCOVERY'],
      },
    },
  })

  const repoType = repositoryDetailData?.repo?.type

  const { policyMonitoring } = useEntitlements()

  const tabs = React.useMemo(() => {
    const tabsForRepo = getTabs(repoType) ?? []
    const enabledTabs = tabsForRepo.filter(tab => {
      if (tab.value === 'policies') {
        return policyMonitoring()
      }

      return true
    })
    return enabledTabs
  }, [repoType])

  const { path, url } = useRouteMatch()
  const routeMatch = useRouteMatch(tabs.map(tab => `${path}/${tab.value}`))

  if (networkStatus === NetworkStatus.loading) {
    return <RepositoryLoading />
  }

  if (repositoryDetailError) {
    return <RepositoryError />
  }

  return (
    <ReposProvider>
      <Paper>
        <Breadcrumbs />
        <RepositoryHeader repo={repositoryDetailData.repo} />
        <Tabs value={routeMatch?.url} variant="scrollable">
          {tabs.map(repoTab => {
            if (repoTab.value === 'datamap') {
              return (
                <Tab
                  key={repoTab.value}
                  ref={dataMapMenuRef}
                  label={
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div>{repoTab.label}</div>
                      <ArrowDropDownIcon
                        css={{
                          transform: `translate(6px, 3px)`,
                          fontSize: '20px',
                          color: '#9AA5B1',
                        }}
                      />
                    </div>
                  }
                  value={`${url}/${repoTab.value}`}
                  onClick={handleOpenSettings}
                />
              )
            }

            return (
              <Tab
                key={repoTab.value}
                label={repoTab.label}
                value={`${url}/${repoTab.value}`}
                component={Link}
                to={`${url}/${repoTab.value}`}
              />
            )
          })}

          <div>
            <Menu
              id="basic-menu"
              anchorOrigin={{ vertical: 'bottom' }}
              anchorEl={anchorEl}
              onClose={handleCloseSettings}
              open={open}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              style={{ paddingRight: '30px' }}
            >
              <MenuItem style={{ display: 'none' }} />
              {repoType !== 'rest' && (
                <MenuItem
                  style={{ fontSize: '16px', padding: '6px 16px', paddingRight: '30px' }}
                  component={Link}
                  to={`${url}/datamap/mappings`}
                  onClick={handleCloseSettings}
                >
                  Mappings
                </MenuItem>
              )}
              {repoType !== 'rest' && (
                <MenuItem
                  style={{ fontSize: '16px', padding: '6px 16px' }}
                  component={Link}
                  to={`${url}/datamap/auto-updates`}
                  onClick={handleCloseSettings}
                >
                  Auto Updates
                </MenuItem>
              )}
              <MenuItem
                style={{ fontSize: '16px', padding: '6px 16px' }}
                component={Link}
                to={`${url}/datamap/yaml`}
                onClick={handleCloseSettings}
              >
                View as YAML
              </MenuItem>
            </Menu>
          </div>
        </Tabs>

        <Switch>
          {tabs.some(tab => tab.value === 'approvals') && (
            <Route path={`${path}/approvals`}>
              <RepositoryDetailProvider repoId={id}>
                <ThemeProvider theme={v5Theme}>
                  <Approvals userAccounts={repositoryDetailData?.repo?.userAccounts} />
                </ThemeProvider>
              </RepositoryDetailProvider>
            </Route>
          )}

          {tabs.some(tab => tab.value === 'network-shield') && (
            <Route path={`${path}/network-shield`}>
              <RepositoryDetailProvider repoId={id}>
                <ThemeProvider theme={v5Theme}>
                  <NetworkShield networkShield={repositoryDetailData?.networkShield} />
                </ThemeProvider>
              </RepositoryDetailProvider>
            </Route>
          )}

          {tabs.some(tab => tab.value === 'user-authentication') && (
            <Route path={`${path}/user-authentication`}>
              <RepositoryDetailProvider repoId={id} repoType={repoType}>
                <ThemeProvider theme={v5Theme}>
                  <UserAuthentication userAccounts={repositoryDetailData?.repo?.userAccounts} />
                </ThemeProvider>
              </RepositoryDetailProvider>
            </Route>
          )}

          {tabs.some(tab => tab.value === 'apps-and-bi-tools') && (
            <Route path={`${path}/apps-and-bi-tools`}>
              <ThemeProvider theme={v5Theme}>
                <AppsAndBiTools
                  repoType={repoType}
                  serviceAccounts={repositoryDetailData?.serviceAccounts}
                  samlIntegrations={repositoryDetailData?.samlIntegrations}
                  refetch={refetch}
                  selectedSidecarEdge={repositoryDetailData?.accessGatewaySidecarEdges?.[0]}
                />
              </ThemeProvider>
            </Route>
          )}

          {tabs.some(tab => tab.value === 'datamap') && (
            <Route path={`${path}/datamap`}>
              <TabContainer>
                <ThemeProvider theme={v5Theme}>
                  <Datamap repoId={id} repoType={repoType} />
                </ThemeProvider>
              </TabContainer>
            </Route>
          )}

          {tabs.some(tab => tab.value === 'policies') && (
            <Route path={`${path}/policies`}>
              <TabContainer>
                <RepositoryDetailProvider
                  repoId={id}
                  repoType={repoType}
                  repoName={repositoryDetailData.repo.name}
                  policyTemplates={repositoryDetailData.repo.policyTemplates}
                >
                  <ThemeProvider theme={v5Theme}>
                    <Policies />
                  </ThemeProvider>
                </RepositoryDetailProvider>
              </TabContainer>
            </Route>
          )}

          {tabs.some(tab => tab.value === 'audit-report') && (
            <Route path={`${path}/audit-report`}>
              <TabContainer>
                <RepositoryDetailProvider repoId={id} repoType={repoType}>
                  <ThemeProvider theme={v5Theme}>
                    <AuditReport />
                  </ThemeProvider>
                </RepositoryDetailProvider>
              </TabContainer>
            </Route>
          )}

          {tabs.some(tab => tab.value === 'config') && (
            <Route path={`${path}/config`}>
              <TabContainer>
                <RepositoryDetailProvider repoId={id} repoType={repoType}>
                  <ThemeProvider theme={v5Theme}>
                    <Config repo={repositoryDetailData.repo} query={repositoryDetailData} />
                  </ThemeProvider>
                </RepositoryDetailProvider>
              </TabContainer>
            </Route>
          )}

          {tabs.some(tab => tab.value === 'overview') && (
            <Route path={`${path}/overview`}>
              <TabContainer>
                <ThemeProvider theme={v5Theme}>
                  <Overview
                    repo={repositoryDetailData?.repo}
                    discoveredAccounts={repositoryDetailData?.discoveredAccounts}
                    workerRuns={repositoryDetailData?.workerRuns}
                    mappedDatalabelsRelationship={
                      repositoryDetailData?.mappedDatalabelsRelationship
                    }
                  />
                </ThemeProvider>
              </TabContainer>
            </Route>
          )}

          <Redirect to={`${path}/${tabs?.[0].value}`} />
        </Switch>
      </Paper>
    </ReposProvider>
  )
}
