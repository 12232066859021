import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { useAuth } from '@jeeves/components/Auth'
import { ConnectionTypesTabs } from '../../../../components'
import ConnectionFields, {
  Host,
  Port,
  Username,
  Password,
  Database,
} from '../../ConnectionInfoFields'
import { AccessRestrictionInfo } from '../../AccessRestrictionInfo'

import { getUsername, getHost, getPorts } from '../../../../utils'

import getConnectionTypes from './connectionTypes'

const PostgresqlAccordionContent = ({
  opaqueToken,
  repo,
  accessPortalSidecar,
  accessPortalSidecarBinding,
  userAccountEdge,
}) => {
  const [database, setDatabase] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const { user } = useAuth()

  const connectionInfo = {
    username: getUsername(user, repo, userAccountEdge),
    host: getHost(accessPortalSidecar),
    ports: getPorts(accessPortalSidecarBinding),
    password: opaqueToken,
    database,
  }

  const connectionTypes = getConnectionTypes(connectionInfo, {
    showPassword,
  })

  return (
    <Stack spacing={3}>
      <Grid container spacing={8}>
        <Grid item xs={6}>
          <Stack spacing={0.5}>
            <Typography
              variant="h6"
              sx={{
                color: 'text.secondary',
              }}
            >
              Connection Info
            </Typography>

            <ConnectionFields connectionInfo={connectionInfo}>
              <Username />
              <Password showPassword={showPassword} setShowPassword={setShowPassword} />
              <Host />
              <Port />
              <Database setDatabase={setDatabase} />
            </ConnectionFields>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          {userAccountEdge?.accessRestrictionSummaries?.length > 0 && (
            <Stack spacing={0.5}>
              <Typography
                variant="h6"
                sx={{
                  color: 'text.secondary',
                }}
              >
                Access Restrictions
              </Typography>
              <AccessRestrictionInfo
                accessRestrictionSummaries={userAccountEdge?.accessRestrictionSummaries}
              />
            </Stack>
          )}
        </Grid>
      </Grid>

      <ConnectionTypesTabs connectionTypes={connectionTypes} />
    </Stack>
  )
}

export default PostgresqlAccordionContent
