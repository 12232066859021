import { useContext, useState, useEffect } from 'react'
import ExpressClient from '@jeeves/clients/express'
import { useAuth } from '@jeeves/components/Auth'

import { AlertsContext } from '../contexts/AlertsContext'

import moment from 'moment-timezone'
import { get as lodashGet } from 'lodash'
import { supportedAlertTypes } from '../constants'

const useAlerts = () => {
  const [state, setState] = useContext(AlertsContext)
  const { alerts } = state
  // TO DO: refactor this, fetch in the hook, not outside

  const { getTokenSilently } = useAuth()
  const ec = new ExpressClient(getTokenSilently)

  const handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'asc'
    if (state.orderBy === property && state.order === 'asc') {
      order = 'desc'
    }
    setState(state => ({ ...state, order, orderBy }))
  }

  const handleChangePage = (event, page) => {
    setState(state => ({ ...state, page }))
  }

  const handleChangeRowsPerPage = event => {
    setState(state => ({ ...state, rowsPerPage: event.target.value }))
  }

  const severityMap = new Map([
    [0, 'Low'],
    [1, 'Medium'],
    [2, 'High'],
  ])

  // TO DO: should this be here?
  const chartColors = [
    {
      hover: '#f00c0c90',
      background: '#f00c0c90',
      border: '#f00c0c90',
    },
    {
      hover: '#d26e0090',
      background: '#d26e0090',
      border: '#d26e0090',
    },
    {
      hover: '#36A2EB90',
      background: '#36A2EB90',
      border: '#36A2EB90',
    },
    {
      hover: '#dee02d90',
      background: '#dee02d90',
      border: '#dee02d90',
    },
    {
      hover: '#82b93b90',
      background: '#82b93b90',
      border: '#82b93b90',
    },
    {
      hover: '#55d18890',
      background: '#55d18890',
      border: '#55d18890',
    },
    {
      hover: '#39e3cc90',
      background: '#39e3cc90',
      border: '#39e3cc90',
    },
    {
      hover: '#69f0ff90',
      background: '#69f0ff90',
      border: '#69f0ff90',
    },
  ]

  const formatDate = isoDate => {
    return moment.unix(isoDate).format('MMM DD, YYYY hh:mm:ss A ')
  }

  const setTimeSpan = timeSpan => {
    setState(state => ({ ...state, timeSpan: timeSpan }))
  }

  const getTimeSpan = timeSpan => {
    const SECOND = 1000
    const MINUTE = SECOND * 60
    const HOUR = MINUTE * 60
    const DAY = HOUR * 24
    const WEEK = DAY * 7
    const MONTH = WEEK * 4

    const timeSpanMap = new Map([
      ['FIFTEEN_MINS', { value: MINUTE * 15, label: 'Last 15 minutes' }],
      ['HOUR', { value: HOUR, label: 'Last hour' }],
      ['DAY', { value: DAY, label: 'Last day' }],
      ['WEEK', { value: WEEK, label: 'Last week' }],
      ['MONTH', { value: MONTH, label: 'Last month' }],
    ])

    return timeSpanMap.get(timeSpan)
  }

  const getMetrics = async () => {
    const _getChartData = (metricsData, chartLabels, windowStart, windowEnd, bucketLength) => {
      const data = []

      for (var type in metricsData) {
        const values = new Array(chartLabels.length).fill(0)

        for (var timestamp in metricsData[type]) {
          if (
            windowStart <= parseInt(timestamp) &&
            parseInt(timestamp) <= windowEnd + bucketLength
          ) {
            const bucketIndex = parseInt(Math.abs(timestamp - windowStart) / bucketLength)
            values[bucketIndex] += 1
          }
        }
        data.push({ label: type, values: values })
      }
      return data
    }

    const _getMetricsData = async () => {
      const alerts = await ec
        .get(`/alerts/all`)
        .then(res => res.data)
        .then(res => res.filter(data => supportedAlertTypes.includes(data.alerttype)))

      const metricsData = {}

      alerts.map(alert => {
        const type = alert.alerttype

        const alertEpochTime = alert.time * 1000

        if (!metricsData[type]) {
          metricsData[type] = {}
        }
        metricsData[type][alertEpochTime] = {
          occurrences: alert.count,
          severity: alert.severity,
        }
      })

      return metricsData
    }

    const _getChartLabels = (windowStart, windowEnd, bucketLength) => {
      const chartLabels = []
      for (let i = windowStart; i <= windowEnd; i += bucketLength) {
        chartLabels.push(i)
      }

      return chartLabels
    }

    const _getMetricsConfig = () => {
      const ONE_SECOND = 1000
      const ONE_MINUTE = ONE_SECOND * 60
      const ONE_HOUR = ONE_MINUTE * 60

      let bucketLength, windowLength

      switch (state.timeSpan) {
        case 'DAY':
          bucketLength = ONE_MINUTE * 180
          windowLength = ONE_HOUR * 23
          break
        case 'HOUR':
          bucketLength = ONE_MINUTE * 10
          windowLength = ONE_HOUR
          break
        case 'FIFTEEN_MINS':
          bucketLength = ONE_MINUTE * 3
          windowLength = ONE_MINUTE * 15
          break
      }

      const metricsConfig = { bucketLength, windowLength }
      return metricsConfig
    }

    const _getWindowEnd = bucketLength => {
      let windowEnd = moment.utc().valueOf()
      windowEnd = windowEnd - (windowEnd % bucketLength)

      return windowEnd
    }

    try {
      const metricsData = await _getMetricsData()
      const metricsConfig = _getMetricsConfig()

      const bucketLength = metricsConfig.bucketLength
      const windowEnd = _getWindowEnd(bucketLength)
      const windowStart = windowEnd - metricsConfig.windowLength

      const chartLabels = _getChartLabels(windowStart, windowEnd, bucketLength)

      const chartData = {
        chartLabels,
        data: _getChartData(metricsData, chartLabels, windowStart, windowEnd, bucketLength),
      }
      return chartData
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  return {
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    severityMap,
    chartColors,
    formatDate,
    getMetrics,
    lodashGet,
    timeSpan: state.timeSpan,
    setTimeSpan,
    getTimeSpan,
    ec,
    setState,
    alerts,
  }
}

export default useAlerts
