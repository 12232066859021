import { Divider, Stack, Typography } from '@mui/material'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { IconCopyButton } from '@jeeves/new-components'

const SidecarHeaderInfo_queryFragment = graphql(`
  fragment SidecarHeaderInfo_query on Query {
    sidecar(id: $sidecarId) {
      id
      endpoint
      userEndpoint
      version
    }
  }
`)

interface SidecarHeaderInfoProps {
  query: FragmentType<typeof SidecarHeaderInfo_queryFragment>
}

const SidecarHeaderInfo = ({ query: queryProp }: SidecarHeaderInfoProps) => {
  const query = useFragment(SidecarHeaderInfo_queryFragment, queryProp)

  const { id, endpoint, userEndpoint, version } = query.sidecar

  const sidecarHost = userEndpoint || endpoint

  return (
    <Stack spacing={1} direction="row" divider={<Divider orientation="vertical" flexItem />}>
      {sidecarHost && (
        <Stack direction="row" spacing={1}>
          <Stack direction="row" spacing={0.5} sx={{ alignItems: 'baseline' }}>
            <Typography variant="h6" sx={{ color: 'text.secondary' }}>
              Host:
            </Typography>
            <Typography variant="code" sx={{ color: 'text.primary' }}>
              {sidecarHost}
            </Typography>
          </Stack>
          <IconCopyButton
            text={sidecarHost}
            sx={{
              padding: 0,
              '& .MuiSvgIcon-root': {
                fontSize: '16px',
              },
            }}
          />
        </Stack>
      )}

      <Stack direction="row" spacing={1}>
        <Stack direction="row" spacing={0.5} sx={{ alignItems: 'baseline' }}>
          <Typography variant="h6" sx={{ color: 'text.secondary' }}>
            ID:
          </Typography>
          <Typography variant="code" sx={{ color: 'text.primary' }}>
            {id}
          </Typography>
        </Stack>
        <IconCopyButton
          text={id}
          sx={{
            padding: 0,
            '& .MuiSvgIcon-root': {
              fontSize: '16px',
            },
          }}
        />
      </Stack>

      {version && (
        <Stack direction="row" spacing={0.5} sx={{ alignItems: 'baseline' }}>
          <Typography variant="h6" sx={{ color: 'text.secondary' }}>
            Version:
          </Typography>
          <Typography variant="code" sx={{ color: 'text.primary' }}>
            {version}
          </Typography>
        </Stack>
      )}
    </Stack>
  )
}

export default SidecarHeaderInfo
