/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import styled from '@emotion/styled'
import { useState } from 'react'
import { Grid, IconButton, Typography, Tooltip } from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const CodeSnippet = styled(Typography)`
  font-family: ${({ theme }) => theme.typography.monospaced};
  background: ${({ theme }) => theme.palette.background.default};
  padding: ${({ theme }) => theme.spacing.unit}px;
  font-size: 0.95rem;
  white-space: pre;
  overflow-x: scroll;
`

const DownloadWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing[5]};
  // overflow-wrap: anywhere;
  // word-break: break-all;
  overflow-x: scroll;
`

export const DownloadLink = ({ downloadLink, caption = 'Your command is ready!' }) => {
  const [isCopied, setIsCopied] = useState(false)

  const handleOnCopy = () => {
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 3000)
  }

  return (
    <DownloadWrapper>
      <Grid container alignItems="center">
        <Grid item>
          <Typography variant="subtitle1">{caption}</Typography>
        </Grid>

        <Grid item>
          <CopyToClipboard text={downloadLink} onCopy={handleOnCopy}>
            <Tooltip title={isCopied ? 'Copied!' : 'Copy to clipboard'}>
              <IconButton color="default">
                <FileCopyIcon />
              </IconButton>
            </Tooltip>
          </CopyToClipboard>
        </Grid>
      </Grid>

      <CodeSnippet variant="subtitle1" style={{'maxHeight':'95px', 'overflowY':'scroll'}}>{downloadLink}</CodeSnippet>
    </DownloadWrapper>
  )
}
