import React, { useState } from 'react'
import debounce from 'lodash/debounce'
import { useQuery, gql, NetworkStatus } from '@apollo/client'
import { useForm, Controller } from 'react-hook-form'
import sortBy from 'lodash/sortBy'
import {
  Button,
  Dialog,
  Select,
  SelectOption,
  InputLabel,
  Input,
  DatePicker,
  ServiceIcon,
} from '@jeeves/new-components'

import {
  Stack,
  Typography,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  FormControl,
  Box,
  Radio,
  RadioGroup,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import useRequestAccess from '../hooks/useRequestAccess'
import InfiniteLoad from './InfiniteLoad'
import { ALL_REPO_TYPES_WITH_DISPLAY_NAME } from '@jeeves/constants'
import { Tooltip } from '@jeeves/new-components'

import { RequestAccessFeedbackDialog } from './RequestAccessFeedbackDialog'

export const ACCESS_PORTAL_REQUESTABLE_REPOS = gql`
  query AccessPortalRequestableRepos(
    $first: Int
    $after: String
    $filters: IdentityReposFilters
    $accessibility: ReposAccessibilityState!
  ) {
    slackNotificationIntegrations {
      id
      name
    }
    user {
      ... on ControlPlaneUser {
        id
        repos(first: $first, after: $after, filters: $filters, accessibility: $accessibility) {
          edges {
            node {
              id
              name
              type
              tags
              config {
                authentication {
                  allowNativeAuthentication
                  otpDbuser
                  ...MongoAccordionContent_repoAuthenticationConfig
                }
                TLS {
                  enableClientSidecarTLS
                }
              }
              ... on MongoDBReplicaSetRepo {
                replicaSetName
              }
            }
            accessibleUserAccounts {
              edges {
                node {
                  id
                  name
                }
              }
            }
            accessPortalBindingRelationship {
              edge {
                node {
                  id
                  ... on ClusterBinding {
                    boundListenersRelationship {
                      edges {
                        node {
                          id
                          port
                        }
                      }
                    }
                  }
                  ... on S3Binding {
                    listenerSet {
                      proxyListener {
                        id
                        port
                      }
                      browserListener {
                        id
                        port
                      }
                    }
                  }
                  ... on SingleListenerBinding {
                    listener {
                      id
                      port
                    }
                  }
                }
                sidecar {
                  name
                  endpoint
                  userEndpoint
                }
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
`

const supportedRepoTypes = ALL_REPO_TYPES_WITH_DISPLAY_NAME.filter(
  type => type.typeName !== 'snowflake' && type.typeName !== 'rest'
)

export const RequestAccess = ({ setIsRequestable = () => {}, tooltipPlacement = 'bottom' }) => {
  const [open, setOpen] = useState(false)
  const [feedbackOpen, setFeedbackOpen] = useState(false)
  const [requestButtonEnabled, setRequestButtonEnabled] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
    watch,
    setValue,
    register,
  } = useForm({
    defaultValues: {
      repoType: '',
      repo: '',
      userAccountId: '',
      duration: {
        type: 'always',
      },
      validUntil: '',
      reason: '',
    },
  })

  const { repo, repoType, userAccountId, duration } = watch()
  const durationType = watch('duration.type')

  const { data, loading, fetchMore, refetch, networkStatus } = useQuery(
    ACCESS_PORTAL_REQUESTABLE_REPOS,
    {
      variables: {
        accessibility: 'ALL',
        first: 10,
        filters: {
          ...(repoType && { repoTypes: [repoType] }),
          repoName: searchValue,
        },
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: data => {
        const hasAccessRequestIntegration = data.slackNotificationIntegrations?.some(
          slackIntegration => slackIntegration.name?.startsWith('Access Requests:')
        )
        const repoEdgesUserCanRequest = data?.user?.repos?.edges ?? []
        const requestButtonEnabled =
          hasAccessRequestIntegration && repoEdgesUserCanRequest?.length > 0

        setRequestButtonEnabled(requestButtonEnabled)
        setIsRequestable(requestButtonEnabled)
      },
    }
  )

  const autocompleteOptions =
    networkStatus === NetworkStatus.setVariables ? [] : data?.user?.repos?.edges ?? []

  const firstLoading = networkStatus === NetworkStatus.loading

  const hasNextPage = data?.user?.repos?.pageInfo?.hasNextPage

  const getNextPage =
    hasNextPage &&
    (() => fetchMore({ variables: { after: data?.user?.repos?.pageInfo?.endCursor } }))

  const { requestAccess, loading: requestLoading } = useRequestAccess()

  const debouncedSearch = React.useMemo(() => {
    return debounce(value => setSearchValue(value), 300)
  }, [setSearchValue])

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }
  const handleOnClose = () => {
    reset()
    refetch({ filters: null })
    setOpen(false)
  }
  const handleOnFeedbackClose = () => {
    handleOnClose()
    setFeedbackOpen(false)
  }

  const onSubmit = async ({ repo, userAccountId, duration, validUntil, reason }) => {
    try {
      await requestAccess({
        variables: {
          repoId: repo.id,
          request: {
            validFrom: new Date().toISOString(),
            userAccountId,
            comments: reason,
            validUntil: validUntil || null,
          },
        },
      })
      setFeedbackOpen(true)
    } catch (e) {
      console.error(e)
    }
  }

  const RequestAccessButtonWithMessaging = () => {
    const RequestAccessButton = (...props) => (
      <Button
        onClick={handleToggle}
        type="button"
        loading={firstLoading}
        disabled={!requestButtonEnabled}
        {...props}
      >
        Request Access
      </Button>
    )
    return !firstLoading && !requestButtonEnabled ? (
      <Tooltip
        placement={tooltipPlacement}
        title={
          <Typography variant="caption">
            Your admin has not configured Access Portal access requests.
          </Typography>
        }
      >
        <Box>
          <RequestAccessButton />
        </Box>
      </Tooltip>
    ) : (
      <RequestAccessButton />
    )
  }

  return (
    <React.Fragment>
      <RequestAccessButtonWithMessaging />
      <Dialog
        open={open}
        onClose={handleOnClose}
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            position: 'absolute',
            right: 0,
            margin: 0,
            top: 0,
            height: '100vh',
            minHeight: '100vh',
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h3" sx={{ color: 'text.primary' }}>
            Request Access
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Stack
            sx={{ justifyContent: 'space-between', height: '100%' }}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Stack spacing={4}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Request access to a data repository.
              </Typography>
              <Stack spacing={3}>
                <Box>
                  <FormControl variant="standard" sx={{ display: 'flex' }}>
                    <InputLabel id="repoType-select-label" htmlFor="repoType-select">
                      Repository Type
                    </InputLabel>

                    <Controller
                      name="repoType"
                      control={control}
                      render={({ field: { onChange, ...rest } }) => (
                        <Select
                          labelId="repoType-select-label"
                          id="repoType-select"
                          onChange={e => {
                            setValue('repo', '')
                            setValue('userAccountId', '')
                            setSearchValue('')
                            onChange(e)
                          }}
                          options={sortBy(supportedRepoTypes, ['displayName']).map(repoType => {
                            return {
                              icon: <ServiceIcon type={repoType.typeName} />,
                              label: repoType.displayName,
                              value: repoType.typeName,
                            }
                          })}
                          {...rest}
                        />
                      )}
                    />
                  </FormControl>
                </Box>
                <Box>
                  <FormControl variant="standard" sx={{ display: 'flex' }}>
                    <InputLabel id="repoName-select-label" htmlFor="repoName-select" required>
                      Repository Name
                    </InputLabel>

                    <Controller
                      onChange={([, data]) => data}
                      name="repo"
                      control={control}
                      render={({ field: { onChange, value, ...rest } }) => (
                        <Autocomplete
                          sx={{ mt: 1 }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          labelId="repoName-select-label"
                          id="repoName-select"
                          loading={loading}
                          ListboxComponent={InfiniteLoad}
                          ListboxProps={{
                            hasNextPage,
                            isNextPageLoading: networkStatus === NetworkStatus.fetchMore,
                            getNextPage,
                            sx: {
                              '& li': {
                                typography: 'body2',
                              },
                            },
                          }}
                          options={autocompleteOptions.map(repoEdge => ({
                            icon: <ServiceIcon type={repoEdge.node.type} />,
                            label: repoEdge.node.name,
                            id: repoEdge.node.id,
                            repoEdge,
                          }))}
                          filterOptions={options => options}
                          value={value}
                          onChange={(e, data) => {
                            setValue('userAccountId', '')
                            onChange(data)
                          }}
                          onInputChange={(e, value, reason) => {
                            if (reason === 'input') {
                              debouncedSearch(value)
                            }
                          }}
                          renderOption={(props, option) => (
                            <SelectOption
                              value={option.id}
                              icon={option.icon}
                              label={option.label}
                              selectSize="medium"
                              {...props}
                            />
                          )}
                          renderInput={params => {
                            return (
                              <Input
                                onBlur={() => {
                                  setSearchValue(repo?.label ?? '')
                                }}
                                inputRef={params.InputProps.ref}
                                inputProps={{ ...params.inputProps, ...params.InputProps }}
                                endAdornment={params.InputProps.endAdornment}
                                {...params}
                                margin="normal"
                              />
                            )
                          }}
                          freeSolo
                          clearOnBlur
                          {...rest}
                        />
                      )}
                      rules={{
                        required: true,
                      }}
                    />
                  </FormControl>
                </Box>
                <Box>
                  <FormControl
                    variant="standard"
                    sx={{ display: 'flex' }}
                    disabled={!repo?.repoEdge}
                  >
                    <InputLabel id="dbAccount-select-label" htmlFor="dbAccount-select" required>
                      Database Account
                    </InputLabel>

                    <Controller
                      name="userAccountId"
                      control={control}
                      render={({ field }) => (
                        <Select
                          labelId="dbAccount-select-label"
                          id="dbAccount-select"
                          options={
                            repo?.repoEdge
                              ? repo.repoEdge.accessibleUserAccounts.edges.map(userAccountEdge => ({
                                  label: userAccountEdge.node.name,
                                  value: userAccountEdge.node.id,
                                }))
                              : []
                          }
                          {...field}
                        />
                      )}
                      rules={{
                        required: true,
                      }}
                    />
                  </FormControl>
                </Box>
                <Stack spacing={1}>
                  <Box>
                    <FormControl component="fieldset" variant="standard">
                      <InputLabel component="legend" required>
                        How long do you need access?
                      </InputLabel>
                      <Controller
                        name="duration.type"
                        control={control}
                        render={({ field }) => (
                          <RadioGroup
                            row
                            aria-label="duration"
                            {...field}
                            sx={{
                              'legend + &': {
                                marginTop: 3,
                              },
                              gap: 8,
                            }}
                          >
                            <FormControlLabel
                              value="always"
                              control={<Radio size="small" disableRipple />}
                              label="Always"
                            />
                            <FormControlLabel
                              value="until"
                              control={<Radio size="small" disableRipple />}
                              label="Until:"
                            />
                          </RadioGroup>
                        )}
                        rules={{
                          required: true,
                        }}
                      />
                    </FormControl>
                  </Box>

                  {durationType === 'until' && (
                    <Stack>
                      <Controller
                        name="validUntil"
                        control={control}
                        render={({ field: { onChange, onBlur, value, name, ref } }) => {
                          return (
                            <DatePicker
                              onChange={onChange}
                              value={value}
                              sx={{
                                flex: 1,
                              }}
                              selectTime
                            />
                          )
                        }}
                      />
                    </Stack>
                  )}
                </Stack>

                <Box>
                  <FormControl component="fieldset" variant="standard" sx={{ display: 'flex' }}>
                    <InputLabel
                      sx={{
                        typography: 'h6',
                      }}
                      shrink
                      disableAnimation
                    >
                      Reason
                    </InputLabel>
                    <Input
                      id="description-input"
                      inputProps={{
                        ...register('reason'),
                      }}
                      multiline
                      rows={5}
                      placeholder="e.g. Approval for contract period."
                    />
                  </FormControl>
                </Box>
              </Stack>
            </Stack>

            <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end' }}>
              <Button variant="text" onClick={handleOnClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                loading={isSubmitting || requestLoading}
                disabled={!repo || !userAccountId || !duration}
              >
                Request Access
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
      <RequestAccessFeedbackDialog open={feedbackOpen} handleOnClose={handleOnFeedbackClose} />
    </React.Fragment>
  )
}
