import React from 'react'
import Box from '@mui/material/Box'

import { DataLabelHeader } from './components/DataLabelHeader'
import { RepoRow } from '../../components/RepoRow/RepoRow'
import { ShowMore } from '../../components/ShowMore'

const SHOWN_REPOS = 5

export const DataLabelCard = ({ dataLabel, handleUpdateEnabled }) => {
  const [allRepos, setAllRepos] = React.useState(false)
  const [reposToShow, setReposToShow] = React.useState(SHOWN_REPOS)

  const handleShow = () => {
    if (reposToShow === SHOWN_REPOS) {
      setReposToShow(dataLabel.mappedReposRelationship.edges.length)
      setAllRepos(true)
    } else {
      setReposToShow(SHOWN_REPOS)
      setAllRepos(false)
    }
  }
  return (
    <Box>
      <DataLabelHeader dataLabel={dataLabel} handleUpdateEnabled={handleUpdateEnabled} />

      {dataLabel.mappedReposRelationship.edges.slice(0, reposToShow).map(mapping => (
        <RepoRow mapping={mapping} />
      ))}

      {dataLabel.mappedReposRelationship.edges.length > SHOWN_REPOS && (
        <ShowMore allRepos={allRepos} setAllRepos={setAllRepos} handleShow={handleShow} />
      )}
    </Box>
  )
}
